import { Box, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import UploadAttachments from '../../../../shared/components/uploadAttachments'

export const AddNotesForm = ({ register, errors, setValue, control }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1">
                Note <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              size="small"
              {...register('note', {
                required: 'Name is required',
              })}
              placeholder="Enter Note"
              error={!!errors.note}
              helperText={errors.note ? errors.note.message : ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Upload Attachment
            </Typography>
          </Box>
          <UploadAttachments
            control={control}
            name="photos"
            multiple={true}
            setValue={setValue}
          />
        </Grid>
      </Grid>
    </>
  )
}
