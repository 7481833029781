import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormWrapper from '../../../shared/components/formWrapper'
import {
  ShowNotification,
  showToast,
} from '../../../shared/components/showNotification'
import useFetch from '../../../shared/components/userFetch'
import { actionIcons } from '../../ActionIcons'
import { NewActionItemForm } from './NewActionItemForm'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '80%',
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: '12px',
  p: 4,
  '.action_status': {
    padding: '5px 12px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '50px',
    fontSize: '14px',
    marginLeft: '8px',
  },
  '.to_do': {
    color: '#2A70D9',
    background: '#EBF3FF',
  },
  '.in_progress': {
    color: '#D69B25',
    background: '#FFF8EA',
  },
  '.completed': {
    color: '#009E1F',
    background: '#E5F8E9',
  },
  '.rejected': {
    color: '#e85342',
    background: '#ffdfdc',
  },
}

const NewActionItem = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  refetch,
  setFilterState,
  isMutating,
  optimisticUpdate
}) => {
  const isEdit = !!initialValues
  const [notification, setNotification] = useState({
    open: false,
    message: 'test',
    severity: 'success',
  })
  const [userUrl, setUserUrl] = useState(null)
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    '/api/v2/actions_management/actions/base_data?create_action=true'
  )
  const {
    data: usersData,
    isLoading: onUserLoading,
    refetch: usersRefetch,
  } = useFetch(userUrl)
  const { data: users = {} } = usersData || {}

  useEffect(() => {
    usersRefetch()
  }, [userUrl])

  useEffect(() => {
    isEdit &&
      initialValues.group_id &&
      setUserUrl(
        `/api/v2/actions_management/actions/group_users?group_id=${initialValues.group_id}&action_id=${initialValues.id}`
      )
  }, [isEdit])
  const {
    statuses = [],
    sub_categories = [],
    priorities = [],
    action_types = [],
    groups = [],
    form_fields = {},
  } = baseData || {}

  const defaultValues = isEdit
    ? {
        ...initialValues,
        action_type_id: initialValues.action_type_id || '',
        sub_category_id: initialValues.sub_category_id || '',
        due_date: initialValues.due_date
          ? new Date(`${initialValues.due_date}Z`)
              .toISOString()
              .replace('T', ' ')
              .split('.')[0]
          : '',
        assignees: initialValues.group_id
          ? initialValues.assigned_users &&
            initialValues.assigned_users.map((v) => v.value)
          : [],
        status: initialValues.status || 'to_do',
      }
    : {
        group_id: '',
        label: '',
        description: '',
        action_type_id: '',
        sub_category_id: '',
        due_date: '',
        priority: '',
        status: 'to_do',
        photos: [],
        allow_to_edit_group: false,
      }
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onSubmit = (data) => {
    setUserUrl(null)
    const formData = new FormData()
    formData.append('audit_action[label]', data.label || '')
    formData.append('audit_action[description]', data.description || '')
    formData.append('audit_action[status]', data.status || '')
    formData.append('audit_action[sub_category_id]', data.sub_category_id || '')
    formData.append('audit_action[priority]', data.priority || '')
    formData.append(
      'audit_action[due_date]',
      (data.due_date && new Date(data.due_date).toISOString()) || ''
    )
    formData.append('audit_action[group_id]', data.group_id || '')
    formData.append('audit_action[action_type_id]', data.action_type_id || '')

    // Append arrays (assignees and attachments)

    if (data.assignees && data.assignees.length) {
      data.assignees.forEach((user) => {
        formData.append(`audit_action[assignees][]`, user)
      })
    }

    data.photos &&
      Object.values(data.photos).forEach((file) => {
        formData.append('audit_action[photos][]', file)
      })

    const payload = formData
    if (!isEdit) {
      mutate(payload, {
        onSuccess: (responseData) => {
          showToast({ message: 'Created Successfully' })
          setOpen(false)
          if (refetch) {
            refetch()
          }
          if (optimisticUpdate && responseData && responseData.data) {
            optimisticUpdate(responseData.data, 'create');
          }
          setFilterState({ page: 1, limit: 20 })
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      })
    } else {
      mutate(
        payload,
        {
          onSuccess: (responseData) => {
            showToast({ message: 'Updated Successfully' })
            setOpen(false)
            if (refetch) {
              refetch()
            }
            if (optimisticUpdate && responseData && responseData.data) {
              optimisticUpdate(responseData.data, 'edit');
            }
          },
          onError: (e) => {
            showToast({
              type: 'error',
              message: typeof e === 'string' ? e : e.errors.toString() || '',
            })
          },
        },
        `/api/v2/actions_management/actions/${initialValues.id}`
      )
    }
  }

  if (baseDataLoading) {
    return null
  }

  const handleCloseNotification = () => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    })) // Hide notification, retain other state values
  }

  const onChangeCompany = (id) => {
    setUserUrl(`/api/v2/actions_management/actions/group_users?group_id=${id}`)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className="popup-title"
        onClick={handleOpen}
      >
        {actionIcons.add} Asset Action
      </Button>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography
              className="popup-title"
              id="modal-title"
              variant="h6"
              component="h2"
            >
              {isEdit ? 'Edit' : 'New '} Action{' '}
              {isEdit && initialValues.status ? (
                <span className={`action_status ${initialValues.status}`}>
                  {initialValues.status_label}
                </span>
              ) : (
                <span className="tag">To Do</span>
              )}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: 'calc(100% - 50px)' }}
            >
              <Box
                sx={{ mt: 2 }}
                style={{ overflowY: 'scroll', height: 'calc(100% - 40px)' }}
              >
                <NewActionItemForm
                  photoDetails={
                    (isEdit && initialValues.photos && initialValues.photos) ||
                    {}
                  }
                  isEdit={isEdit}
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  users={
                    isEdit && Object.keys(users).length === 0
                      ? {
                          [defaultValues.group_name]:
                            defaultValues.assigned_users,
                        }
                      : users
                  }
                  statuses={statuses}
                  sub_categories={sub_categories}
                  priorities={priorities}
                  groups={groups}
                  allow_to_edit_group={defaultValues.allow_to_edit_group}
                  action_types={action_types}
                  onChangeCompany={onChangeCompany}
                  initialValues={initialValues}
                  form_fields={form_fields}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#ECECEC',
                      color: '#5C5C5C',
                      marginRight: '10px',
                      outline: 'none',
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#407797',
                      color: '#fff',
                      outline: 'none',
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    disabled={isMutating}
                    endIcon={
                      isMutating ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : null
                    }
                    color="primary"
                  >
                    {isEdit ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  )
}

export default NewActionItem
