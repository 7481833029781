import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { CustomPagination } from '../../../../shared/components/table'
import useFilterChange from '../../../../shared/components/useFilterChange'
import useFetch from '../../../../shared/components/userFetch'
import { NoData } from '../../../../UserGroup/components/AssignedGlobalProjects/view'
import { actionIcons } from '../../../ActionIcons'

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  margin: 'auto 0',
  padding: '16px',
  maxWidth: '90%',
  marginLeft: '-50px',
}))

const StyledTimelineSeparator = styled(TimelineSeparator)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 'none',
  width: '135px',
  '.MuiTimelineDot-root': {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
}))

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  marginLeft: '-55px',
  marginTop: '15px',
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '16px',
  marginBottom: '15px',
  boxShadow: 'none',
}))

const StyledText = styled('span')(({ theme }) => ({
  color: '#407797',
  fontWeight: '600',
  fontSize: '12px',
}))

const StyledDescription = styled('p')(({ theme }) => ({
  color: '#909090',
  fontWeight: '400',
  fontSize: '14px',
}))

const StyledContentBox = styled('div')(({ theme }) => ({
  background: '#f6f6f6',
  padding: '11px 16px',
  borderRadius: '8px',
  fontWeight: '500',
  fontSize: '14px',
  color: '#5c5c5c',
}))

const StyledTime = styled('span')(({ theme }) => ({
  color: '#080808',
}))

export const HistoryLog = ({ id }) => {
  const initialState = {
    page: 1,
    limit: 20,
  }
  const { handleOnFilterChange, filterState, filterData, setFilterState } =
    useFilterChange(initialState)
  const {
    data: responseData,
    isLoading,
    refetch,
  } = useFetch(`/api/v2/actions_management/actions/${id}/activity_logs`, {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
  })
  useEffect(() => {
    if (!isLoading) {
      refetch({
        filters: filterData,
        pagination: { page: filterState.page, limit: filterState.limit },
      })
    }
  }, [filterState])
  const { data: baseData, isBaseLoading } = useFetch(
    `/api/v2/actions_management/actions/${id}/activity_logs/base_data`
  )

  const { log_types = [] } = baseData || {}
  const { data = [], metadata = {} } = responseData || {}
  const logFilterData = [
    { label: 'All', value: 'All', key: 'All' },
    ...log_types.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]
  return (
    <>
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" style={{}}>
          Historical Logs
        </Typography>
        <div className="d-flex">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginRight: '15px',
            }}
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#080808',
                whiteSpace: 'nowrap',
              }}
            >
              Date Log
            </InputLabel>
            <FormControl sx={{ minWidth: 200 }} size="small">
              <TextField
                name="created_at_eq"
                onChange={(event) =>
                  handleOnFilterChange(event.target, 'created_at_eq')
                }
                size="small"
                type="date"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#080808',
                whiteSpace: 'nowrap',
              }}
            >
              Log Type
            </InputLabel>
            <FormControl sx={{ minWidth: 200 }} size="small">
              <Select
                name="log_type_eq"
                defaultValue="All"
                value={filterData['log_type_eq']}
                onChange={(_, v) =>
                  handleOnFilterChange(_.target || 'All', 'log_type_eq')
                }
                displayEmpty
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '4px',
                }}
              >
                {logFilterData.map((u) => (
                  <MenuItem value={u.value}>{u.key}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <Container>
        <Timeline>
          {data.length > 0 ? (
            data.map((order, index) => (
              <TimelineItem
                key={index}
                sx={{
                  '&:before': { flex: 'none' }, // Remove the default vertical line
                }}
              >
                <StyledTimelineSeparator>
                  <StyledText>{order.log_type_label}</StyledText>
                  <TimelineDot sx={{ alignSelf: 'center' }}>
                    {actionIcons.logs[order.log_type] ||
                      actionIcons.logs.archive}
                  </TimelineDot>
                  {index < data.length + 1 && <TimelineConnector />}
                </StyledTimelineSeparator>
                <StyledTimelineContent>
                  <StyledPaper>
                    <StyledDescription>
                      <span
                        style={{
                          color: '#080808',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        {order.changed_by}{' '}
                      </span>
                      {order.details}
                    </StyledDescription>
                    <StyledContentBox>
                      <StyledTime>{order.created_at}</StyledTime>{' '}
                      {order.description}
                    </StyledContentBox>
                  </StyledPaper>
                </StyledTimelineContent>
              </TimelineItem>
            ))
          ) : (
            <NoData />
          )}
        </Timeline>
      </Container>
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
    </>
  )
}
