import React from 'react'
import { actionIcons } from '../../../ActionIcons'
import { PageView } from '../../ActionsNotices/View/ActionView'
export const AssetPageView = ({ match }) => (
  <PageView
    match={match}
    fetchUrl="/api/v2/actions_management/asset_actions/:id?view_type=asset_action_info"
    breadcrumbItems={[
      { name: 'Asset List', url: '/actions-management?tab=asset_actions' },
      { name: 'View Asset' },
    ]}
    backUrl="/actions-management?tab=asset_actions"
    headerMapper={(data) => [
      { label: 'Asset Name', value: data.asset_name, width: 'w20' },
      { label: 'Description', value: data.description, width: 'w40' },
      {
        label: 'Status',
        value: (
          <span className={`action_status ${data.status}`}>
            {data.status_label}
          </span>
        ),
        width: 'w20',
      },
      {
        label: 'Priority',
        value: (
          <span className={`action_priority ${data.priority}`}>
            {actionIcons[data.priority]} {data.priority}
          </span>
        ),
        width: 'w20',
        isEllips: true,
        noBr: true,
      },
      { isDivider: true },
      { label: 'Action Type', value: data.action_type, width: 'w20' },
      { label: 'Sub Category', value: data.sub_category, width: 'w20' },
      { label: 'Due Date', value: data.due_at, width: 'w20' },
      { label: 'Created By', value: data.created_by, width: 'w20' },
      { label: 'Created On', value: data.created_at, width: 'w20', noBr: true },
    ]}
    page="asset"
  />
)
