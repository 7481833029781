import { Box, createTheme, ThemeProvider } from '@mui/material'
import React from 'react'
import Loader from '../../../../shared/components/loader'
import PlainTabs from '../../../../shared/components/plainTabs'
import useFetch from '../../../../shared/components/userFetch'
import ViewPageHeader, {
  HeaderDetails,
} from '../../../../shared/components/viewPageHeader'
import AuditActionParticipants from './AuditActionParticipants'
import AuditTrails from './AuditTrails'
import { HistoryLog } from './HistoryLog'
import { ActionViewTabList, ViewPageWrapper } from './viewWrapper'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, system-ui, -apple-system', // Change font family globally
  },
})

const PageDetailsHeader = ({
  title,
  breadcrumbItems,
  headerDetails,
  backUrl,
}) => (
  <div className="header">
    <div className="v-container">
      <ViewPageHeader title={title} items={breadcrumbItems} backUrl={backUrl} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <HeaderDetails items={headerDetails} />
      </div>
    </div>
  </div>
)

const DetailsTabs = ({
  id,
  actionParticipants,
  refetch,
  page,
  pdf_link,
  display_audit_trail,
}) => {
  let tabValues = [...ActionViewTabList]

  if (
    page !== 'asset' &&
    !ActionViewTabList.some((item) => item.label === 'History Log')
  ) {
    tabValues = [...tabValues, { label: 'History Log', value: 'log' }]
  } else {
    tabValues = [...ActionViewTabList]
  }

  return (
    <div className="tab-wrap">
      <PlainTabs
        isViewPageTab
        tabList={tabValues || ActionViewTabList}
        page={page}
      >
        {(active) => (
          <>
            {active === 'ad' && (
              <div className="v-container">
                <Box style={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
                  <AuditActionParticipants
                    data={actionParticipants}
                    id={id}
                    callRefetch={refetch}
                    page={page}
                    pdf_link={pdf_link}
                    display_audit_trail={display_audit_trail}
                  />
                </Box>
              </div>
            )}
            {active === 'nat' && (
              <div className="v-container">
                <AuditTrails id={id} page={page} callRefetch={refetch} />
              </div>
            )}
            {active === 'log' && (
              <div className="v-container">
                <HistoryLog id={id} />
              </div>
            )}
          </>
        )}
      </PlainTabs>
    </div>
  )
}

export const PageView = ({
  match,
  fetchUrl,
  breadcrumbItems,
  backUrl,
  headerMapper,
  page = '',
}) => {
  const { id } = match.params
  const {
    data: responseData,
    isLoading,
    refetch,
  } = useFetch(fetchUrl.replace(':id', id))

  if (isLoading) {
    return <Loader />
  }

  const data = responseData.data || {}
  const {
    label,
    action_participants = [],
    pdf_link = '',
    display_audit_trail = false,
    issue,
  } = data

  const headerDetails = headerMapper(data)

  return (
    <ThemeProvider theme={theme}>
      <ViewPageWrapper>
        <PageDetailsHeader
          title={label || issue || ''}
          breadcrumbItems={breadcrumbItems}
          headerDetails={headerDetails}
          backUrl={backUrl}
        />
        <DetailsTabs
          id={id}
          actionParticipants={action_participants}
          pdf_link={page !== 'asset' ? pdf_link : null}
          refetch={refetch}
          page={page}
          display_audit_trail={display_audit_trail}
        />
      </ViewPageWrapper>
    </ThemeProvider>
  )
}

// Reusable Action Page View
