import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Controller, useWatch } from 'react-hook-form'

const UploadAttachments = ({
  multiple = false,
  control,
  name,
  existingFiles = [],
  setValue,
}) => {
  const filesList = useWatch({ control, name, defaultValue: [] })

  const handleDelete = (fileToDelete) => {
    const currentFiles = Array.isArray(filesList)
      ? filesList
      : Object.values(filesList)

    const updatedFilesList = currentFiles.filter(
      (file) => file !== fileToDelete
    )

    setValue(name, updatedFilesList)
  }
  const handleFileChange = (event, onChange) => {
    const file = multiple ? event.target.files : event.target.files[0]
    onChange(file)
  }

  return (
    <>
      <Box
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '125px',
          margin: '20px 0',
        }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field: { onChange } }) => (
            <input
              type="file"
              multiple={multiple}
              accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(event) => handleFileChange(event, onChange)}
              style={{ display: 'none' }}
              id={name}
            />
          )}
        />
        <label htmlFor={name}>
          <Button
            variant="outlined"
            component="span"
            sx={{ textTransform: 'none' }}
          >
            <Typography>Upload File</Typography>
          </Button>
        </label>
      </Box>
      {filesList && (
        <Box mb={1}>
          <Typography variant="body1" className="f-label">
            Attachments
          </Typography>
        </Box>
      )}
      {filesList &&
        Object.values(filesList).map((file) => {
          return (
            <div
              key={file.id}
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <div>{file.name}</div>
              <button
                onClick={() => handleDelete(file)}
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: 'red',
                }}
              >
                ❌
              </button>
            </div>
          )
        })}
      {existingFiles && !filesList && (
        <>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Existing Attachments
            </Typography>
          </Box>
          {existingFiles.map((file) => (
            <img
              src={file.download_url}
              height="100px"
              width="100px"
              style={{ objectFit: 'contain' }}
            />
          ))}
        </>
      )}
    </>
  )
}

export default UploadAttachments
