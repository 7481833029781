import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../../../../config/axiosConfig";
import moment from 'moment';
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button } from '@mui/material';
import Loader from "../../../../components/NewLoader";
import Application from "../../../../config/ApplicationWrapper";
import New from "./New"
import { toast } from "react-toastify";

const TrainingList = ({ id }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState();
    const [usersList, setUsersList] = useState([]);
    const [topicsList, setTopicsList] = useState([]);
    const [selecetedUsers, setSelecetedUsers] = useState([]);
    const [assignee, setAssignee] = useState();
    const [completionDate, setCompletionDate] = useState();
    const [expirationDate, setExpirationDate] = useState();
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, assignee, completionDate, expirationDate, status, limit])

    useMemo(() => {
        userDetail()
        topics()
    }, [])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${id}/internal_trainings`, {
                params: {
                    page: page,
                    limit: limit,
                    topic_name_cont: name,
                    full_name_cont: assignee,
                    completed_date: completionDate,
                    expiry_date: expirationDate,
                    status_cont: status,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setSearchable(details && details.data.length > 0 ? true : false)
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    async function userDetail() {
        const users = await axios.get(`${Application.api_url}/api/v2/internal_subs/${id}/internal_employees`, {
            params:{
                activated_eq: true
            }
        });
        if (users.status == 200 && users.data.success === true) {
            var details = users.data;
            setUsersList(details.data);
        }
    }


    async function topics() {
        const topicsDetails = await axios.get(`${Application.api_url}/api/v2/common/internal_training_topics`, {
            params: {
                identifier: true,
                archived_eq: false,
            }
        });
        if (topicsDetails.status == 200 && topicsDetails.data.success === true) {
            var details = topicsDetails.data;
            setTopicsList(details.data);
        }
    }


    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const hanldeLimitChange = (value) => {
        setPage(1);
        setLimit(value);
    }

    const clearFilter = () => {
        setSearchByName("");
        setAssignee("");
        setCompletionDate("");
        setExpirationDate("");
        setStatus("");
    }

    const open = (data) => {
        setEditData(data)
        setShow(true)
    }

    const close = (data) => {
        setShow(false)
        setEditData("")
        data && getPageDetails()
    }

    function ChipDesign({ data, type }) {
        let finalData = data.filter(Boolean);
        if (data && data.length >= 3) {
            finalData = finalData.slice(0, 2);
        }
        let objName = type === "user" ? "full_name" : "name";

        return (
            <div>
                {finalData &&
                    finalData.map((obj, index) => (
                        <span className={`badge badge-light responsible-party`}>
                            {obj && obj.user && obj.user.full_name}
                        </span>
                    ))}
                {data && data.length >= 3 && (
                    <span className={`badge badge-light responsible-party more-groups`}>
                        + {data.length - 2} More User
                    </span>
                )}
            </div>
        );
    }

    function CheckValidate({ status }) {
        if (status == "Active") {
            return <p className="active-training">Active</p>
        } else {
            return <p className="active-expire">Expired</p>
        }
    }

    const viewPDF = async (data) => {
        window.open(`${Application.api_url}/api/record_of_trainings/${data.training_schedule_id}/edit`, '_blank');
    }

    const deleteTraining = async (data) => {
        // Prompt the user for confirmation
        const isConfirmed = window.confirm("Are you sure you want to delete this training? This action cannot be undone.");
        
        // If the user clicks "Cancel", exit the function
        if (!isConfirmed) {
            return;
        }
    
        try {
            const topicsDetails = await axios.delete(`${Application.api_url}/api/v2/internal_subs/${id}/internal_trainings/${data.identifier}`, {
                params: {
                    training_participants: data.training_participants
                }
            });
    
            if (topicsDetails.status === 200 && topicsDetails.data.success === true) {
                console.log("Training deleted successfully");
                // You can add additional logic here if needed
                getPageDetails();
                toast.success(`Delete Successful`);
            }
        } catch (error) {
            
        }
    };

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Title :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Assignee :</span>
                                            <span className="selected">{assignee ? assignee : "All"}</span>
                                            <span className={assignee ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setAssignee("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Completion Date :</span>
                                            <span className="selected">{completionDate ? completionDate : "All"}</span>
                                            <span className={completionDate ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setCompletionDate("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Expiration Date :</span>
                                            <span className="selected">{expirationDate ? expirationDate : "All"}</span>
                                            <span className={expirationDate ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setExpirationDate("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Status :</span>
                                            <span className="selected">{status ? status : "All"}</span>
                                            <span className={status ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setStatus("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="bulk-action">
                                        <NavLink
                                            to={`/subcontractor/configuration`}
                                            id=""
                                            role="button"
                                            className="mr-2"
                                        >
                                            <Button variant="outlined" className="btn-manage-topic">Manage Topics</Button>
                                        </NavLink>
                                        <Button variant="contained" className="btn-create" onClick={(e) => { setShow(true) }}>+ Add Trainings</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Title
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="name"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setSearchByName(e.target.value)
                                                                }}
                                                                value={name}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Assignee
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="name"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setAssignee(e.target.value)
                                                                }}
                                                                value={assignee}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Completion Date
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="date"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="completionDate"
                                                                aria-describedby="basic-addon1"
                                                                name="completionDate"
                                                                onChange={(e) => {
                                                                    setCompletionDate(e.target.value)
                                                                }}
                                                                value={completionDate}
                                                            />
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Expiration Date
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="date"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="expirationDate"
                                                                aria-describedby="basic-addon1"
                                                                name="expirationDate"
                                                                onChange={(e) => {
                                                                    setExpirationDate(e.target.value)
                                                                }}
                                                                value={expirationDate}
                                                            />
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Status
                                                    </div> 
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <select
                                                                class="form-control risk-type"
                                                                onChange={(e) => {
                                                                    setStatus(e.target.value);
                                                                }}
                                                                value={status}
                                                            >
                                                                <option value="">All</option>
                                                                <option value="active">Active</option>
                                                                <option value="expired">Expired</option>
                                                            </select>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {val.title}
                                                            </td>
                                                            <td>
                                                                <ChipDesign data={val.training_participants} type="user" />
                                                            </td>
                                                            <td>
                                                                {val.completion_date}
                                                            </td>
                                                            <td>
                                                                {val.expiry_date}
                                                            </td>
                                                            <td>
                                                                <CheckValidate status={val.status} />
                                                            </td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                <a
                                                                    aria-expanded="false"
                                                                    aria-haspopup="true"
                                                                    class="btn dropdown-toggle"
                                                                    data-toggle="dropdown"
                                                                    href="#"
                                                                    id="dropdownMenuLink"
                                                                    role="button"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </a>
                                                                <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                                                    <li>
                                                                        <a
                                                                            class="archive_group_invites"
                                                                            data-toggle="tooltip"
                                                                            title="Edit"
                                                                            onClick={(e) => { open(val) }}
                                                                        >
                                                                            <div class="row d-flex">
                                                                                <div class="col-sm-3">
                                                                                    <i
                                                                                        class="fa fa-pencil-square-o"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            fontSize: "16px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-9"
                                                                                    style={{
                                                                                        paddingTop: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >

                                                                                    Edit
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <a
                                                                            class="archive_group_invites"
                                                                            data-toggle="tooltip"
                                                                            title="View PDF"
                                                                            onClick={(e) => { viewPDF(val) }}
                                                                        >
                                                                            <div class="row d-flex">
                                                                                <div class="col-sm-3">
                                                                                    <i
                                                                                        class="fa fa-file-pdf"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            fontSize: "16px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-9"
                                                                                    style={{
                                                                                        paddingTop: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >

                                                                                    PDF
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <a
                                                                            class="archive_group_invites"
                                                                            data-toggle="tooltip"
                                                                            title="Delete"
                                                                            onClick={(e) => { deleteTraining(val) }}
                                                                        >
                                                                            <div class="row d-flex">
                                                                                <div class="col-sm-3">
                                                                                    <i
                                                                                        class="fa fa-trash"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            fontSize: "16px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-9"
                                                                                    style={{
                                                                                        paddingTop: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >

                                                                                    Delete
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Trainings"} open={(e) => { setShow(true) }} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                hanldeLimitChange(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <New show={show} closepopup={close} editObj={editData} details={usersList} parentId={id} rightSide={selecetedUsers} topicsList={topicsList} />
            }
        </>
    );
}

export default TrainingList;
