import {
  Box,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { setActionPageCount } from '../../../../reducers/common'
import CustomModal from '../../../shared/components/CustomModal'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ButtonCreate,
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import usePatch from '../../../shared/components/usePatch'
import useFetch from '../../../shared/components/userFetch'
import { actionIcons } from '../../ActionIcons'
import { SubCategoryTypeCreate, SubCategoryTypeEdit } from './SubCategoryForm'

const SubCategoryTypesList = ({ actionsCount, setActionPageCount }) => {
  const initialState = {
    page: 1,
    limit: 20,
  }
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState)
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/actions_management/sub_categories', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { mutate: mutateRestore, isLoading: isRestoring } = usePatch(
    '/api/v2/actions_management/sub_categories'
  )
  const [addNew, setNew] = useState(false)
  const [edit, setEdit] = useState(false)
  const { data = [], metadata = {} } = responseData || {}

  useEffect(() => {
    metadata.count &&
      setActionPageCount({
        config: {
          actions_type: actionsCount.config.actions_type,
          sub_category_types:
            metadata.count || actionsCount.config.sub_category_types,
        },
      })
  }, [metadata.count])

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])
  const changeActiveInactive = (id, isActive) => {
    const payload = {
      sub_category: {
        archived: !isActive,
      },
    }
    mutateRestore(
      payload,
      {
        onSuccess: () => {
          showToast({ message: 'Updated Successfully' })
          callRefetch()
        },
      },
      `/api/v2/actions_management/sub_categories/${id}`
    )
  }
  const headers = [
    <TableCell>
      <HeaderItem
        title="Label"
        isSort
        handleSort={() => handleSort('label')}
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]
  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const { label } = ele || {}
        return (
          <TableRow
            key={index}
            className={index % 2 === 0 ? 'even-row' : 'odd-row'}
          >
            <TableCell>{label}</TableCell>
            <TableCell style={{ minWidth: '100px' }}>
              <Stack
                spacing={2}
                direction="row"
                style={{ alignItems: 'center' }}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEdit(ele.id)}
                >
                  {actionIcons.edit}
                </div>
                <Switch
                  checked={!ele.archived}
                  disabled={isRestoring}
                  onChange={(_, checked) =>
                    changeActiveInactive(ele.id, checked)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </TableCell>
          </TableRow>
        )
      }),
    [data]
  )
  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
          borderLeft: '1px solid #D3D3D3',
          borderRight: '1px solid #D3D3D3',
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 700, fontSize: '20px' }}
        >
          Sub Category Types
        </Typography>
        <ButtonCreate
          label="Add Sub Category"
          styleOverride={{ color: '#fff', background: '#407797' }}
          iconWhite
          onClick={() => setNew(true)}
        />
      </Box>
      <CustomTable headers={headers} rows={rows} isSliderScroll={false} />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
      {addNew && (
        <CustomModal
          title="+ Add Sub Category"
          width="50%"
          height="auto"
          handleClose={() => setNew(false)}
          open={addNew}
        >
          <SubCategoryTypeCreate
            onClose={() => setNew(false)}
            setFilterState={setFilterState}
            refetch={callRefetch}
          />
        </CustomModal>
      )}
      {edit && (
        <CustomModal
          title="+ Edit Sub Category"
          width="50%"
          height="auto"
          handleClose={() => setEdit(false)}
          open={!!edit}
        >
          <SubCategoryTypeEdit
            onClose={() => setEdit(false)}
            id={edit}
            setFilterState={setFilterState}
            refetch={callRefetch}
          />
        </CustomModal>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setActionPageCount: (value) => dispatch(setActionPageCount(value)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubCategoryTypesList)
