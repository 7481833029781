import React, { useEffect, useState, useLayoutEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextField, Checkbox, FormGroup, FormControlLabel, FormControl, Autocomplete } from '@mui/material';
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import Select from "react-select";

export default function CreateFolder(props) {
    const { show, closepopup, details, parentId } = props;
    const [group, setGroup] = useState([]);
    const [validation, setValidation] = useState(true);
    const [onSubmit, setSubmit] = useState(false);
    const [groupsList, setGroupsList] = useState([]);
    const [docData, setDocData] = useState([]);
    const [selectedDocId, setSelectedDocId] = useState(parentId);
    const [values, setValues] = useState({
        name: "",
        description: "",
        parent_id: "",
        expiry_date: "",
        is_public: false,
        is_global_project: false,
        is_hsms_doc: false,
        group: []
    })

    useLayoutEffect(() => {
        async function fetchGroup() {
            const groupDetails = await axios.get(`${Application.api_url}/api/v2/common/groups?type=PublicGroup`);
            if (groupDetails.status == 200 && groupDetails.data.success === true) {
                var result = groupDetails.data;
                setGroupsList(result.data);
                if (details.global_project_ids) {
                    let groupDetails = result.data.filter(person => details.global_project_ids.includes(person.id));
                    setGroup(groupDetails)
                }
            }

            if (details) {
                if (details.description) {
                    details.description = details.description != "null" && details.description != "undefined" ? details.description : "";
                }
                setValues({ ...values, ...details }); 
            }
        }
        async function fetchDocs() {
            setSelectedDocId(details.parent_id);
            const docDetails = await axios.get(`${Application.api_url}/api/v2/company_documents/${details.slug}`);
            if (docDetails.status == 200 && docDetails.data.success === true) {
                var result = docDetails.data;
                setDocData([...[{id: '', folder_name: "Root Folder"}], ...result.data.parent_details]);
            }
        }
        fetchDocs()
        fetchGroup()
    }, [])

    const onhandleStatusClose = () => {
        closepopup();
        setValues({
            name: "",
            description: "",
            parent_id: "",
            expiry_date: "",
            is_public: false,
            is_global_project: false,
            is_hsms_doc: false,
            group: []
        });
        setGroup([]);
        setSubmit(false)
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }

    const create = async () => {
        setSubmit(true);
        if (!values.name.trim()) {
            setValidation(false)
            return false;
        }
        let doc_type = "folder";
        if (details && details.slug) {
            doc_type = details.is_directory ? "folder" : "file";
        }
        var users = localStorage.getItem('user');
        users = JSON.parse(users);
        const formData = new FormData();
        formData.append("doc_detail[doc_type]", doc_type);
        formData.append("doc_detail[user_id]", users.id);
        formData.append("doc_detail[company_id]", users.company_id);
        formData.append("doc_detail[description]", values.description);
        parentId && formData.append("doc_detail[parent_id]", selectedDocId);
        formData.append("doc_detail[expiry_date]", values.expiry_date);
        formData.append("doc_detail[is_global_project]", values.is_global_project);
        formData.append("doc_detail[is_public]", values.is_public);
        formData.append("doc_detail[is_hsms_doc]", values.is_hsms_doc);

        if (doc_type == "file") {
            formData.append("doc_detail[file_name]", values.name.trim());
        } else {
            formData.append("doc_detail[folder_name]", values.name.trim());
        }

        if (values.is_global_project) {
            [...group].forEach(obj => {
                formData.append("global_project_ids[]", obj.id);
            });
        } else {
            formData.append("global_project_ids[]", "");
        }

        let url = `${Application.api_url}/api/v2/company_documents/${users.company_id}/upload`;

        if (details) {
            url = `${Application.api_url}/api/v2/company_documents/${details.slug}`;
        }

        const result = await axios({
            method: "PATCH",
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (result.data && result.data.success === true) {
            toast.success(`${details ? "Updated" : "Created"} Successfully`)
            setValues("");
            setGroup([]);
            setSubmit(false)
            closepopup(result.data);
        }
    }

    const CustomOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
            <div ref={innerRef} {...innerProps} style={{ padding: '5px' }}>
                <div key={data.id}>
                    <p style={{ color: "black", fontWeight: "bold", padding: "0px", margin: "0px" }}>
                        {data.folder_name}
                    </p>
                    <p style={{ color: "gray", fontSize: "12px", padding: "0px", margin: "0px" }}>
                        {data.path}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <Modal
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model docs-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">{details ? "Update Details" : "Create Folder"} </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        {details &&
                            <div className="col-md-12">
                                <label>
                                    Nest Under
                                </label>
                                <Select
                                    options={docData}
                                    getOptionLabel={(option) => option.folder_name}
                                    getOptionValue={(option) => option.id}
                                    value={
                                    docData.filter(
                                        (val) => val.id === selectedDocId
                                    )}
                                    onChange={(event, value) => {
                                        setSelectedDocId(event.id)
                                    }}
                                    components={{ Option: CustomOption }}
                                />
                            </div>
                        }
                        <div className="col-md-12">
                            <label>
                                Folder Name  <text style={{ color: "red", fontSize: "16px" }}>*</text>
                            </label>
                            <TextField
                                required
                                fullWidth
                                className="text-field"
                                id="outlined-required"
                                error={!validation && onSubmit}
                                helperText={
                                    !validation && onSubmit ? "Folder Name is required" : ""
                                }
                                value={values.name}
                                onChange={(e) => {
                                    setSubmit(false);
                                    handleChange("name", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>Description</label>
                            <textarea
                                className="create-asset-textarea"
                                aria-label="empty textarea"
                                value={values.description}
                                onChange={(e) => {
                                    handleChange("description", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>
                                Expiry Date
                            </label>
                            <input
                                type="date"
                                min={new Date().toJSON().split("T")[0]}
                                class="date-field"
                                id="expiry_date"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="expiry_date"
                                data-date="MM DD YYYY"
                                data-date-format="MM DD YYYY"
                                value={values.expiry_date}
                                onChange={(e) => {
                                    handleChange("expiry_date", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox checked={values.is_public} onChange={(event) => { handleChange("is_public", event.target.checked) }} name="is_public" />}
                                        label="Visible in ComplyLoop"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox checked={values.is_hsms_doc} onChange={(event) => { handleChange("is_hsms_doc", event.target.checked) }} name="is_hsms_doc" />}
                                        label="Visible to HSMS"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox checked={values.is_global_project} onChange={(event) => { handleChange("is_global_project", event.target.checked) }} name="is_global_project" />}
                                        label="Visible ONLY to selected Global Project"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                        {values.is_global_project &&
                            <div className="col-md-12">
                                <label>
                                    Groups
                                </label>
                                <Autocomplete
                                    multiple={true}
                                    limitTags={2}
                                    disablePortal={true}
                                    id="multiple-limit-tags"
                                    disableCloseOnSelect
                                    options={groupsList}
                                    getOptionLabel={(options) => options.name}
                                    defaultValue={group}
                                    onChange={(event, value) => setGroup(value)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
                    <Button variant="contained" onClick={(event) => {
                        event.currentTarget.disabled = true;
                        create()
                    }}>
                        {details ? "Update" : "Create"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
