import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  styled,
  TextField,
} from "@mui/material";
import { TagRender } from "./table";

const emptyBox = (
  <svg
    width="53"
    height="71"
    viewBox="0 0 53 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9749_52560)">
      <path
        d="M0.712957 68.7301V32.171L4.52108 20.7379C4.81357 19.8598 5.63492 19.2676 6.55988 19.2676H17.9047V25.7191C17.9047 26.9069 18.8666 27.8695 20.0535 27.8695H32.9474C34.1343 27.8695 35.0963 26.9069 35.0963 25.7191V19.2676H46.4411C47.366 19.2676 48.1874 19.8598 48.4799 20.7379L52.288 32.171V47.2252V47.2515V42.9241V68.7303C52.288 69.918 51.326 70.8807 50.1391 70.8807H2.8609C1.67401 70.8807 0.712036 69.918 0.712036 68.7303L0.712957 68.7301ZM2.97805 32.171H5.01104V26.0683L2.97805 32.171ZM7.1599 32.171H15.7557V40.7729C15.7557 41.9606 16.7181 42.9237 17.9049 42.9237H35.0962C36.2831 42.9237 37.2454 41.9606 37.2454 40.7729V32.171H45.8412V21.4181H37.2454V25.7192C37.2454 28.0946 35.321 30.0204 32.9473 30.0204H20.0534C17.6798 30.0204 15.7553 28.0946 15.7553 25.7192V21.4181H7.15954L7.1599 32.171ZM47.9903 32.171H50.0233L47.9903 26.0683V32.171ZM13.6069 34.3214H2.86163V68.7302H50.1399V34.3214H39.3946V40.7729C39.3946 43.1483 37.4702 45.0741 35.0966 45.0741H17.9053C15.5316 45.0741 13.6072 43.1483 13.6072 40.7729L13.6069 34.3214ZM23.2773 62.2787H31.8731C32.4667 62.2787 32.9477 62.76 32.9477 63.3537C32.9477 63.9477 32.4667 64.4291 31.8731 64.4291H23.2773C22.684 64.4291 22.2027 63.9477 22.2027 63.3537C22.2027 62.76 22.684 62.2787 23.2773 62.2787ZM23.2773 57.9775H36.1712C36.7644 57.9775 37.2458 58.4589 37.2458 59.0529C37.2458 59.6466 36.7644 60.1279 36.1712 60.1279H23.2773C22.684 60.1279 22.2027 59.6466 22.2027 59.0529C22.2027 58.4589 22.684 57.9775 23.2773 57.9775ZM8.2347 57.9775H18.979C19.5726 57.9775 20.0536 58.4589 20.0536 59.0529V63.3537C20.0536 63.9477 19.5726 64.4291 18.979 64.4291H8.2347C7.6411 64.4291 7.16009 63.9477 7.16009 63.3537V59.0529C7.16009 58.4589 7.6411 57.9775 8.2347 57.9775ZM9.30895 60.1279V62.2787H17.9051V60.1279H9.30895Z"
        fill="#909090"
      />
      <path
        d="M24.4537 1.92937C24.4537 1.35018 23.9916 0.880859 23.4213 0.880859C22.8514 0.880859 22.3893 1.35018 22.3893 1.92937V14.6713C22.3893 15.2501 22.8514 15.7194 23.4213 15.7194C23.9916 15.7194 24.4537 15.2501 24.4537 14.6713V1.92937Z"
        fill="#909090"
      />
      <path
        d="M18.2602 4.02604C18.2602 3.4472 17.7981 2.97752 17.2278 2.97752C16.6579 2.97752 16.1958 3.4472 16.1958 4.02604V14.6713C16.1958 15.2501 16.6579 15.7194 17.2278 15.7194C17.7981 15.7194 18.2602 15.2501 18.2602 14.6713V4.02604Z"
        fill="#909090"
      />
      <path
        d="M30.6472 1.92937C30.6472 1.35018 30.1851 0.880859 29.6149 0.880859C29.0449 0.880859 28.5828 1.35018 28.5828 1.92937V14.6713C28.5828 15.2501 29.0449 15.7194 29.6149 15.7194C30.1851 15.7194 30.6472 15.2501 30.6472 14.6713V1.92937Z"
        fill="#909090"
      />
      <path
        d="M36.8408 4.02604C36.8408 3.4472 36.3787 2.97752 35.8084 2.97752C35.2385 2.97752 34.7764 3.4472 34.7764 4.02604V14.6713C34.7764 15.2501 35.2385 15.7194 35.8084 15.7194C36.3787 15.7194 36.8408 15.2501 36.8408 14.6713V4.02604Z"
        fill="#909090"
      />
    </g>
    <defs>
      <clipPath id="clip0_9749_52560">
        <rect
          width="51.5759"
          height="70"
          fill="white"
          transform="translate(0.712036 0.880859)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DualBoxContainer = styled(Box)({
  ".view-container": {
    padding: "32px 60px",
    ".list-item": {
      span: {
        color: "#080808",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "18px",
      },
    },
    ".tab-header": {
      color: "#080808",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "32px",
    },
  },
  ".dualTitle": {
    color: "#5C5C5C",
    fontWeight: "700",
    fontSize: "14px",
  },
  ".btn-selectall": {
    color: "#080808",
  },
  "li.MuiListItem-root": {
    padding: "3px 11px",
    border: "1px solid #ececec",
    borderBottom: 'none',
    "&:last-child": {
      borderBottom: "1px solid #ececec",
    },
    ".MuiTypography-root": {
      // fontFamily: "Inter",
      color: "#080808",
    },
  },
  ".searchBoxContainer": {
    background: "#F6F6F6",
    padding: "11px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ".MuiFormControl-fullWidth": {
      width: "60%",
      // border: "1px solid #D3D3D3",
      ".MuiInputBase-root": {
        input: {
          display: "flex",
          height: "32px",
          padding: "7px 8px",
          alignItems: "center",
          borderRadius: "6px",
          background: "#fff",
        },
      },
    },
    ".searchBox": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ".box-title": {
        color: "#5C5C5C",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
      },
      ".count": {
        background: "#ECECEC",
        display: "flex",
        height: "22px",
        alignItems: "center",
        borderRadius: "32px",
        height: "24px",
        padding: "0px 8px",
        marginLeft: "7px",
        color: "#080808",
        fontWeight: "500",
        lineHeight: "18px",
      },
    },
  },
});

const DualListbox = ({
  setValue,
  name,
  leftData = [],
  rightData = [],
  title = "",
  showTags = false,
  disabledValue = false
}) => {
  const [leftFilter, setLeftFilter] = useState("");
  const [rightFilter, setRightFilter] = useState("");
  const [selectAllPosition, setSelectAllPosition] = useState({
    left: false,
    right: false,
  });
  const [leftItems, setLeftItems] = useState(leftData);
  const [rightItems, setRightItems] = useState(rightData);
  // Update the form state whenever rightItems changes
  useEffect(() => {
    setValue(name, rightItems);
  }, [rightItems, setValue, name]);

  const handleMoveToRight = () => {
    const selectedItems = leftItems
      .filter((item) => item.selected)
      .map((item) => ({ ...item, selected: false }));
    setRightItems((prev) => [...prev, ...selectedItems]);
    setLeftItems((prev) => prev.filter((item) => !item.selected));
    setSelectAllPosition({ ...selectAllPosition, left: false });
  };

  const handleMoveToLeft = () => {
    const selectedItems = rightItems
      .filter((item) => item.selected)
      .map((item) => ({ ...item, selected: false }));
    setLeftItems((prev) => [...prev, ...selectedItems]);
    setRightItems((prev) => prev.filter((item) => !item.selected));
    setSelectAllPosition({ ...selectAllPosition, right: false });
  };

  const handleSelectAll = (e, isLeft) => {
    const { checked } = e.target;
    console.log(checked);
    if (isLeft) {
      setLeftItems((prev) =>
        prev.map((item) => ({ ...item, selected: checked }))
      );
      setSelectAllPosition({ right: false, left: checked });
    } else {
      setRightItems((prev) =>
        prev.map((item) => ({ ...item, selected: checked }))
      );
      setSelectAllPosition({ left: false, right: checked });
    }
  };

  const handleCheckboxChange = (item, isLeft) => {
    if (isLeft) {
      setLeftItems((prev) =>
        prev.map((i) =>
          i.value === item.value ? { ...i, selected: !i.selected } : i
        )
      );
    } else {
      setRightItems((prev) =>
        prev.map((i) =>
          i.value === item.value ? { ...i, selected: !i.selected } : i
        )
      );
    }
  };

  const filteredLeftItems = leftItems.filter((item) =>
    item.key && item.key.toLowerCase().includes(leftFilter.toLowerCase())
  );

  const filteredRightItems =
    (rightItems.length > 0 &&
      rightItems.filter((item) =>
        item.key.toLowerCase().includes(rightFilter.toLowerCase())
      )) ||
    [];

  return (
    <>
      <DualBoxContainer>
        {showTags && rightData.length > 0 && (
          <>
            <h3 style={{ color: "#5C5C5C", fontWeight: 700, fontSize: "14px" }}>
              Selected Users :{" "}
              <span
                style={{
                  color: "#080808",
                  fontWeight: 400,
                  padding: 5,
                  backgroundColor: "#ECECEC",
                  borderRadius: "24px",
                }}
              >
                {rightData.length}
              </span>
            </h3>
            <TagRender noLimit data={rightData.map(({ key }) => key)} />
          </>
        )}
        <h2 className="dualTitle">{title}</h2>
        <Box display="flex" justifyContent="space-between">
          <Box
            width="45%"
            style={{
              border: "1px solid #F6F6F6",
              borderRadius: "5px",
              background: "#ECECEC",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              className="searchBoxContainer"
            >
              <div className="searchBox">
                <span style={{ fontSize: '14px'}}>Unselected</span>{" "}
                <span className="count">{filteredLeftItems.length}</span>
              </div>
              <TextField
                placeholder="Search"
                value={leftFilter}
                onChange={(e) => setLeftFilter(e.target.value)}
                fullWidth
                size="small"
              />
            </Box>
            <Box style={{ background: "#ECECEC" }}>
              <FormControlLabel
                style={{ padding: "0 15px", marginBottom: 0 }}
                control={
                  <Checkbox
                    style={{ color: '#407797' }} 
                    checked={selectAllPosition.left}
                    disabled={disabledValue}
                    onClick={(e) => handleSelectAll(e, true)}
                  />
                }
                label="Select All"
              />
              <List
                style={{
                  height: "220px",
                  overflow: "auto",
                  background: "#FFF",
                }}
              >
                {filteredLeftItems.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "200px",
                      flexDirection: 'column'
                    }}
                  >
                    {emptyBox}
                    <span style={{ fontSize: '16px', fontWeight: 600, color: '#909090', padding: '6px' }}>No Data</span>
                  </div>
                ) : (
                  filteredLeftItems.map((item) => (
                    <ListItem key={item.value} dense className="list-item">
                      <Checkbox
                        disabled={disabledValue}
                        style={{ color: '#407797', padding: '5px' }} 
                        checked={item.selected || false}
                        onChange={() => handleCheckboxChange(item, true)}
                      />
                      <ListItemText primary={item.key} />
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button style={{ border: '1px solid #ECECEC', background: '#fff'}} onClick={handleMoveToRight}> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M7.08337 16.333L12.9167 10.4997L7.08337 4.66634" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> </Button>
            <Button style={{ border: '1px solid #ECECEC', background: '#fff'}} onClick={handleMoveToLeft}> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M12.9166 4.66699L7.08329 10.5003L12.9166 16.3337" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> </Button>
          </div>
          <Box
            width="45%"
            style={{
              border: "1px solid #F6F6F6",
              borderRadius: "5px",
              background: "#ECECEC",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              className="searchBoxContainer"
            >
              <div className="searchBox">
                <span style={{ fontSize: '14px'}}>Selected</span>{" "}
                <span className="count">{filteredRightItems.length}</span>
              </div>
              <TextField
                placeholder="Search"
                size="small"
                value={rightFilter}
                onChange={(e) => setRightFilter(e.target.value)}
                fullWidth
              />
            </Box>
            <Box style={{ background: "#ECECEC" }}>
              <FormControlLabel
                style={{ padding: "0 15px", marginBottom: 0 }}
                control={
                  <Checkbox
                    disabled={disabledValue}
                    style={{ color: '#407797',  padding: '5px' }} 
                    checked={selectAllPosition.right}
                    onClick={(e) => handleSelectAll(e, false)}
                  />
                }
                label="Select All"
              />
              <List
                style={{
                  height: "220px",
                  overflow: "auto",
                  background: "#FFF",
                }}
              >
                {filteredRightItems.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "200px",
                      flexDirection: 'column'
                    }}
                  >
                    {emptyBox}
                    <span style={{ fontSize: '16px', fontWeight: 600, color: '#909090', padding: '6px' }}>No Data</span>
                  </div>
                ) : (
                  filteredRightItems.map((item) => (
                    <ListItem key={item.value} dense>
                      <Checkbox
                        disabled={disabledValue}
                        style={{ color: '#407797', padding: '5px' }} 
                        checked={item.selected || false}
                        onChange={() => handleCheckboxChange(item, false)}
                      />
                      <ListItemText primary={item.key} />
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          </Box>
        </Box>
      </DualBoxContainer>
    </>
  );
};

export default DualListbox;
