import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import FormWrapper from '../../../shared/components/formWrapper'
import {
  ShowNotification,
  showToast,
} from '../../../shared/components/showNotification'
import useFetch from '../../../shared/components/userFetch'
import { actionIcons } from '../../ActionIcons'
import { NewAssetActionForm } from './NewAssetActionForm'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '80%',
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: '12px',
  p: 4,
  '.action_status': {
    padding: '5px 12px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '50px',
    fontSize: '14px',
    marginLeft: '8px',
  },
  '.to_do': {
    color: '#2A70D9',
    background: '#EBF3FF',
  },
  '.in_progress': {
    color: '#D69B25',
    background: '#FFF8EA',
  },
  '.completed': {
    color: '#009E1F',
    background: '#E5F8E9',
  },
  '.rejected': {
    color: '#e85342',
    background: '#ffdfdc',
  },
}

const NewAssetAction = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  refetch,
  setFilterState,
  isMutating,
}) => {
  const isEdit = !!initialValues
  const [notification, setNotification] = useState({
    open: false,
    message: 'test',
    severity: 'success',
  })
  //activated_eq=true create_job_profile=true
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    '/api/v2/actions_management/asset_actions/base_data?create_action=true'
  )

  const {
    statuses = [],
    sub_categories = [],
    priorities = [],
    action_types = [],
    assets = [],
    company_users = [],
    form_fields = {},
  } = baseData || {}
  const defaultValues = isEdit
    ? {
        ...initialValues,
        asset_tracking_id: initialValues.asset_id,
        due_at: initialValues.due_at
          ? new Date(initialValues.due_at)
              .toISOString()
              .replace('T', ' ')
              .split('.')[0]
          : '',
        assignee_ids:
          (initialValues.assignees &&
            initialValues.assignees.map((v) => v.value)) ||
          [],
      }
    : {
        asset_tracking_id: '',
        issue: '',
        description: '',
        action_type_id: '',
        sub_category_id: '',
        due_at: '',
        priority: '',
        status: 'to_do',
        assignee_ids: [],
        images: [],
      }
  console.log(defaultValues)
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append(
      'asset_action[asset_tracking_id]',
      data.asset_tracking_id || ''
    )
    formData.append('asset_action[issue]', data.issue || '')
    formData.append('asset_action[description]', data.description || '')
    formData.append('asset_action[action_type_id]', data.action_type_id || '')
    formData.append('asset_action[sub_category_id]', data.sub_category_id || '')
    formData.append('asset_action[priority]', data.priority || '')
    formData.append('asset_action[due_at]', data.due_at || '')
    formData.append('asset_action[status]', data.status || '')
    formData.append(
      `asset_action[assignee_ids]`,
      data.assignee_ids.map((e) => e).toString(',')
    )

    // if (data.assignee_ids && data.assignee_ids.length) {
    //   data.assignee_ids.forEach((user) => {
    //     formData.append(`asset_action[assignee_ids][]`, user)
    //   })
    // }

    data.images &&
      Object.values(data.images).forEach((file) => {
        formData.append('asset_action[images][]', file)
      })

    const payload = formData
    if (!isEdit) {
      mutate(payload, {
        onSuccess: () => {
          showToast({ message: 'Created Successfully' })
          setOpen(false)
          if (refetch) {
            refetch()
          }
          setFilterState({ page: 1, limit: 20 })
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      })
    } else {
      mutate(
        payload,
        {
          onSuccess: () => {
            showToast({ message: 'Updated Successfully' })
            setOpen(false)
            if (refetch) {
              refetch()
            }
          },
          onError: (e) => {
            showToast({
              type: 'error',
              message: typeof e === 'string' ? e : e.errors.toString() || '',
            })
          },
        },
        `/api/v2/actions_management/asset_actions/${initialValues.id}`
      )
    }
  }

  if (baseDataLoading) {
    return null
  }

  const handleCloseNotification = () => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    })) // Hide notification, retain other state values
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className="popup-title"
        onClick={handleOpen}
      >
        Action Item
      </Button>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography
              className="popup-title"
              id="modal-title"
              variant="h6"
              component="h2"
            >
              {actionIcons.add} Asset Action{' '}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: 'calc(100% - 50px)' }}
            >
              <Box
                sx={{ mt: 2 }}
                style={{ overflowY: 'scroll', height: 'calc(100% - 40px)' }}
              >
                <NewAssetActionForm
                  photoDetails={
                    (isEdit && initialValues.photos && initialValues.photos) ||
                    {}
                  }
                  isEdit={isEdit}
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  users={company_users}
                  statuses={statuses}
                  sub_categories={sub_categories}
                  priorities={priorities}
                  assets={assets}
                  action_types={action_types}
                  initialValues={initialValues}
                  form_fields={form_fields}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#ECECEC',
                      color: '#5C5C5C',
                      marginRight: '10px',
                      outline: 'none',
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#407797',
                      color: '#fff',
                      outline: 'none',
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    disabled={isMutating}
                    endIcon={
                      isMutating ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : null
                    }
                    color="primary"
                  >
                    {isEdit ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  )
}

export default NewAssetAction
