import Axios from 'axios';
import { useState, useEffect, useRef } from 'react';

const useModify = (url, formDataEnable = false) => {
    const [isLoading, setLoading] = useState(false);
    const isMounted = useRef(true); // Ref to track if the component is mounted

    useEffect(() => {
        // Set isMounted to true when the component mounts
        isMounted.current = true;

        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    const mutate = async (payload, { onSuccess, onError }, overrideUrl = '') => {
        try {
            setLoading(true);

            const { data } = formDataEnable
                ? await Axios.put(overrideUrl || `${url}/${payload.id}`, payload, {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                      },
                  })
                : await Axios.put(overrideUrl || `${url}/${payload.id}`, payload);

            if (isMounted.current && onSuccess) {
                onSuccess(data);
            }
        } catch (error) {
            const errorData = error.response ? error.response.data : error.message;
            if (isMounted.current && onError) {
                onError(errorData);
            }
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    };

    return { mutate, isLoading };
};

export default useModify;
