import {
  Autocomplete,
  Box,
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import { setActionPageCount } from '../../../../reducers/common'
import ActiveFilters from '../../../shared/components/activeFilters'
import AlertDialog from '../../../shared/components/alretDialog'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'
import useDelete from '../../../shared/components/useDelete'
import useFetch from '../../../shared/components/userFetch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { actionIcons } from '../../ActionIcons'

const TableWrapper = styled('div')`
  .action_name,
  .asset_name,
  .asset_priority_name,
  .asset_sub_category,
  .asset_notices_status,
  .asset_action_type,
  .asset_created_by,
  .asset_due_date,
  .asset_created_at
`

const columnSettingsOptions = [
  { value: 'action_name', label: 'Action Name' },
  { value: 'asset_name', label: 'Asset Name' },
  { value: 'asset_priority_name', label: 'Priority' },
  { value: 'asset_sub_category', label: 'Sub Category' },
  { value: 'asset_notices_status', label: 'Status' },
  { value: 'asset_action_type', label: 'Action Type' },
  { value: 'asset_created_by', label: 'Created By' },
  { value: 'asset_created_at', label: 'Created On' },
  { value: 'asset_due_date', label: 'Due' },
]

const AssetActionList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  baseData,
  actionsCount,
  setActionPageCount,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/actions_management/asset_actions?view_type=list_view', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const columnSetting = localStorage.getItem('assetsField') || '{}'
  const parsedColumnSetting = JSON.parse(columnSetting)
  let settingsData = {
    action_name: true,
    asset_name: true,
    asset_priority_name: true,
    asset_sub_category: true,
    asset_notices_status: true,
    asset_action_type: true,
    asset_created_by: true,
    asset_due_date: true,
    asset_created_at: true,
  }
  if (Object.keys(parsedColumnSetting).length) {
    settingsData = parsedColumnSetting
  } else {
    localStorage.setItem('assetsField', JSON.stringify(settingsData))
  }
  const { mutate, isLoading: isDeleting } = useDelete(
    '/api/v2/actions_management/asset_actions'
  )
  const [selectedRows, setSelectedRows] = useState([])
  const [columnSettings, setColumnSettings] = useState(settingsData)
  const [deleteItem, setDeleteItem] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}

  useEffect(() => {
    metadata.count &&
      setActionPageCount({
        asset_actions: {
          asset_list: metadata.count || actionsCount.asset_actions.asset_list,
          asset_archive: actionsCount.asset_actions.asset_archive,
        },
      })
  }, [metadata.count])

  const {
    action_types = [],
    priorities = [],
    sub_categories = [],
    statuses = [],
    permissions: {
      allow_delete = true,
      allow_edit = true,
      allow_view = true,
    } = {},
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const subCategoryFilterData = [
    { label: 'All', value: 'All' },
    ...sub_categories.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]
  const actionTypeFilterData = [
    { label: 'All', value: 'All' },
    ...action_types.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]
  const handleEdit = (row, setClose) => {
    setEdit(row.id)
    if (setClose) {
      setClose()
    }
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleOk = (row, setClose) => {
    const payload = {
      id: deleteItem.id,
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: 'Action Deactivated Successfully' })
        callRefetch()
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }

  const handleSelectedRows = (id) => (event) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id])
    } else {
      const newValues = selectedRows.filter((e) => e !== id)
      setSelectedRows(newValues)
    }
  }

  const headers = [
    <TableCell
      className={`left action_name ${
        columnSettings.action_name ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Action Name"
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`left asset_name ${
        columnSettings.asset_name ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Asset Name"
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`asset_priority_name ${
        columnSettings.asset_priority_name ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Priority"
        isSort
        handleSort={() => handleSort('priority')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              priorities.find((e) => e.value === filterData['priority_eq']) ||
              'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'priority_eq')
            }
            options={[
              { label: 'All', value: 'All' },
              ...priorities.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`asset_sub_category ${
        columnSettings.asset_sub_category ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Sub Category"
        isSort
        handleSort={() => handleSort('sub_category_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              subCategoryFilterData.find(
                (e) => e.value === filterData['sub_category_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'sub_category_id_eq')
            }
            options={subCategoryFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`asset_notices_status ${
        columnSettings.asset_notices_status ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Status"
        isSort
        handleSort={() => handleSort('status')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              statuses.find((e) => e.value === filterData['status_eq']) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'status_eq')
            }
            options={[
              { label: 'All', value: 'All' },
              ...statuses.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`asset_action_type ${
        columnSettings.asset_action_type ? 'tc_show' : 'hide'
      }`}
      style={{ minWidth: '275px' }}
    >
      <HeaderItem
        title="Action Types"
        isSort
        handleSort={() => handleSort('action_type_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              actionTypeFilterData.find(
                (e) => e.value === filterData['action_type_id_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'action_type_id_eq')
            }
            options={actionTypeFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell
      className={`asset_created_by ${
        columnSettings.asset_created_by ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Created By"
        isSort
        handleSort={() => handleSort('created_by')}
      />
    </TableCell>,
    <TableCell
      className={`asset_due_date ${
        columnSettings.asset_due_date ? 'tc_show' : 'hide'
      }`}
    >
      <HeaderItem
        title="Due"
        isSort
        handleSort={() => handleSort('due_date')}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      asset_name,
      issue,
      priority,
      sub_category,
      status,
      status_label,
      action_type,
      action_type_name,
      created_by,
      created_at,
      due_at = '-',
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell
          className={`left action_name ${
            columnSettings.action_name ? 'tc_show' : 'hide'
          }`}
        >
          <div className="flex" style={{ alignItems: 'center' }}>
            <Checkbox
              style={{ color: '#407797' }}
              checked={selectedRows.includes(ele.id)}
              onChange={handleSelectedRows(ele.id)}
            />
            <span>{ele.forced && actionIcons.forced}</span>
            <div className="flex flex-c">
              <Link to={allow_view ? `/assets-actions/view/${ele.id}` : '#'}>
                <div
                  style={{
                    color: '#080808',
                    fontWeight: '700',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {issue}
                </div>
              </Link>
            </div>
          </div>
        </TableCell>
        <TableCell
          className={`asset_name ${
            columnSettings.asset_name ? 'tc_show' : 'hide'
          }`}
        >
          {asset_name || ''}{' '}
        </TableCell>
        <TableCell
          className={`asset_priority_name ${
            columnSettings.asset_priority_name ? 'tc_show' : 'hide'
          }`}
        >
          {priority || ''}
        </TableCell>
        <TableCell
          className={`asset_sub_category ${
            columnSettings.asset_sub_category ? 'tc_show' : 'hide'
          }`}
        >
          {sub_category}
        </TableCell>
        <TableCell
          className={`asset_notices_status ${
            columnSettings.asset_notices_status ? 'tc_show' : 'hide'
          }`}
        >
          <span className={`asset_notices_status ${status}`}>
            {status_label}
          </span>
        </TableCell>
        <TableCell
          className={`asset_action_type ${
            columnSettings.asset_action_type ? 'tc_show' : 'hide'
          }`}
          style={{ minWidth: '275px' }}
        >
          {action_type}
        </TableCell>
        <TableCell
          className={`asset_created_by ${
            columnSettings.asset_created_by ? 'tc_show' : 'hide'
          }`}
        >
          {created_by}
        </TableCell>
        <TableCell
          className={`asset_due_date ${
            columnSettings.asset_due_date ? 'tc_show' : 'hide'
          }`}
        >
          {due_at}
        </TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              if (allow_view) {
                menuItems.push(
                  <MenuItem>
                    <Link to={`/assets-actions/view/${ele.id}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.view}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          View
                        </span>
                      </Box>
                    </Link>
                  </MenuItem>
                )
              }
              if (allow_edit) {
                menuItems.push(
                  <MenuItem onClick={() => handleEdit(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.edit}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Edit
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              if (allow_delete) {
                menuItems.push(
                  <MenuItem onClick={() => handleDelete(ele, setClose)}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.archive}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        Archive
                      </span>
                    </Box>
                  </MenuItem>
                )
              }
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Archive Asset Action"
        description="Are You sure you want to Archive this asset aciton?"
        okText="Confirm"
      />
      <TableWrapper>
        <CustomTable
          headers={headers}
          rows={rows}
          columnSettingsHandler={setColumnSettings}
          columnSettingsState={columnSettings}
          columnSettingsOptions={columnSettingsOptions}
          columnSettingsName="assetsField"
          enableColumnSettings
          renderActiveFilter={
            <>
              <ActiveFilters
                filterData={filterState}
                update={setFilterState}
                keyName="action_notices"
              />
            </>
          }
        />
        {!!metadata.count && (
          <CustomPagination
            totalPage={metadata.last}
            lastPage={metadata.last}
            currentPage={filterState.page}
            handlePageChange={(_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }}
            rowOptions={[20, 50, 100]}
            rowPerPage={filterState.limit}
            handleRowPerPageChange={(targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }}
          />
        )}
      </TableWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setActionPageCount: (value) => dispatch(setActionPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetActionList)
