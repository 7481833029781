import React, { useState, useEffect, useRef } from 'react';
import '../../../../assets/stylesheets/FeatureSection.css';

// New version imports
import ContractorIcon from "../../../../assets/feature-icons/Users.png";
import DocumentIcon from "../../../../assets/feature-icons/Document.png";
import IncidentIcon from "../../../../assets/feature-icons/Incidenet-Management.png";
import InspectionIcon from "../../../../assets/feature-icons/Inspection-Management.png";
import TrainingIcon from "../../../../assets/feature-icons/Training-record.png";
import AutomationIcon from "../../../../assets/feature-icons/Customizable-automations.png";
import HazardIcon from "../../../../assets/feature-icons/Hazard-Management.png";
import CorrectiveIcon from "../../../../assets/feature-icons/Corrective-Actions.png";
import InventoryIcon from "../../../../assets/feature-icons/Inventory.png";
import AssetIcon from "../../../../assets/feature-icons/Assets.png";
import OnboardingIcon from "../../../../assets/feature-icons/Onboarding-Orientation.png";
import TaskIcon from "../../../../assets/feature-icons/Task.png";
import EmergencyIcon from "../../../../assets/feature-icons/Emergency.png";
import QRCodeIcon from "../../../../assets/feature-icons/QR-Code.png";
import DataIcon from "../../../../assets/feature-icons/Data.png";
import AnalyticsIcon from "../../../../assets/feature-icons/Analytics.png";
import TimeTrackingIcon from "../../../../assets/feature-icons/Time-Tracking.png";
import ReportingIcon from "../../../../assets/feature-icons/Reporting.png";
import NotificationIcon from "../../../../assets/feature-icons/Auto-Notification.png";
import PrivateFormsIcon from "../../../../assets/feature-icons/Private Forms.png";
import MobileAppIcon from "../../../../assets/feature-icons/Easy-Mobile-Apps.png";
import ComplianceIcon from "../../../../assets/feature-icons/Compliance-Manage.png";
import FlaggedUserIcon from "../../../../assets/feature-icons/Flagged-Users.png";
import WearableIcon from "../../../../assets/feature-icons/Wearable-Integrations.png";
import IsolationMode from "../../../../assets/feature-icons/Isolation_Mode.png";
import IsolationMode1 from "../../../../assets/feature-icons/Isolation_Mode-1.png";

// Components for new version
import ContractorManagement from "../../../components/Features/ContractorManagement";
import DocumentManagement from "../../../components/Features/DocumentManagement";
import IncidentManagement from "../../../components/Features/IncidentManagement";
import TrainingRecordManagement from "../../../components/Features/TrainingRecordManagement";
import CorrectiveActions from "../../../components/Features/CorrectiveActions";
import InspectionManagement from "../../../components/Features/InspectionManagement";
import InventoryManagement from "../../../components/Features/InventoryManagement";
import AssetManagement from "../../../components/Features/AssetManagement";
import TaskManagement from "../../../components/Features/TaskManagement";

// Old version imports
import Northern from "../../../../javascript/assets/images/Northern.svg";
import Astro from "../../../../javascript/assets/images/Astro.svg";
import Protech from "../../../../javascript/assets/images/Protech.svg";
import BOT from "../../../../javascript/assets/images/BOT.svg";
import Dayson from "../../../../javascript/assets/images/Dayson.svg";
import Instuform from "../../../../javascript/assets/images/Instuform.svg";
import TWBecker from "../../../../javascript/assets/images/TWBecker.svg";
import Ambler from "../../../../javascript/assets/images/Ambler.svg";
import State from "../../../../javascript/assets/images/State.svg";
import TMG from "../../../../javascript/assets/images/TMG.svg";
import Empipe from "../../../../javascript/assets/images/Empipe.svg";
import Newman from "../../../../javascript/assets/images/Newman.svg";
import Selco from "../../../../javascript/assets/images/selco-logo.png";
import Hardrock from "../../../../javascript/assets/images/hardrock-logo.png";
import Summit from "../../../../javascript/assets/images/summit-forming.png";

// FeatureDetailsSection: Responsible for rendering the appropriate feature component
// based on the active feature selected by the user. Each case corresponds to a specific
// feature, allowing the UI to update dynamically as users interact with the feature menu.
function FeatureDetailsSection({ featureName }) {
    switch (featureName) {
        case "Contractor Management":
            return <ContractorManagement />;
        case "Document Management":
            return <DocumentManagement />;
        case "Incident Management":
            return <IncidentManagement />;
        case "Inspection Management":
            return <InspectionManagement />;
        case "Training Record Management":
            return <TrainingRecordManagement />;
        // case "Hazard Management":
        //     return <HazardManagement />;
        case "Corrective Actions":
            return <CorrectiveActions />;
        case "Inventory Management":
            return <InventoryManagement />;
        case "Asset Management":
            return <AssetManagement />;
        case "Onboarding Orientation":
            return <OnboardingOrientation />;
        case "Task Management":
            return <TaskManagement />;
        case "Emergency Management":
            return <EmergencyManagement />;
        case "QR Code Management":
            return <QRCodeManagement />;
        case "Data & Integrations":
            return <DataIntegrations />;
        case "Analytics":
            return <Analytics />;
        case "Time Tracking":
            return <TimeTracking />;
        case "Reporting":
            return <Reporting />;
        case "Auto Notifications":
            return <AutoNotifications />;
        case "Private Forms":
            return <PrivateForms />;
        case "Compliance Management":
            return <ComplianceManagement />;
        case "Flagged User Management":
            return <FlaggedUserManagement />;
        case "Wearable Integrations":
            return <WearableIntegrations />;
        default:
            return <div>No feature selected</div>;
    }
}
export default function CompaniesSliderSection() {
    // State to toggle between new and old sections
    const [showNewVersion, setShowNewVersion] = useState(true); // Toggle this to switch versions
    const [activeFeature, setActiveFeature] = useState("Contractor Management");
    const scrollRef = useRef(null);

    // Data for the new version features
    const featuresData = [
        { name: "Contractor Management", icon: ContractorIcon },
        { name: "Document Management", icon: DocumentIcon },
        { name: "Incident Management", icon: IncidentIcon },
        { name: "Inspection Management", icon: InspectionIcon },
        { name: "Training Record Management", icon: TrainingIcon },
        // { name: "Customizable Automations", icon: AutomationIcon },
        // { name: "Hazard Management", icon: HazardIcon },
        { name: "Corrective Actions", icon: CorrectiveIcon },
        { name: "Inventory Management", icon: InventoryIcon },
        { name: "Asset Management", icon: AssetIcon },
        { name: "Onboarding Orientation", icon: OnboardingIcon },
        { name: "Task Management", icon: TaskIcon },
        { name: "Emergency Management", icon: EmergencyIcon },
        { name: "QR Code Management", icon: QRCodeIcon },
        { name: "Data & Integrations", icon: DataIcon },
        { name: "Analytics", icon: AnalyticsIcon },
        { name: "Time Tracking", icon: TimeTrackingIcon },
        { name: "Reporting", icon: ReportingIcon },
        { name: "Auto Notifications", icon: NotificationIcon },
        { name: "Private Forms", icon: PrivateFormsIcon },
        { name: "Easy Mobile App", icon: MobileAppIcon },
        { name: "Compliance Management", icon: ComplianceIcon },
        { name: "Flagged User Management", icon: FlaggedUserIcon },
        { name: "Wearable Integrations", icon: WearableIcon },
    ];

    const scrollLeft = () => {
        if (scrollRef.current) {
            const buttonWidth = 100 + 8;
            const scrollAmount = buttonWidth * 8;
            scrollRef.current.scrollBy({
                top: 0,
                left: -scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            const buttonWidth = 100 + 8;
            const scrollAmount = buttonWidth * 8;
            scrollRef.current.scrollBy({
                top: 0,
                left: scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                left: 0,
                behavior: 'smooth',
            });
        }
    }, []);

    // Function to render the new section
    const renderNewSection = () => (
        <div className="feature-container" style={{ position: 'relative', width: '100%', height: '100%' }}>
            <img
                src={IsolationMode}
                alt="Background Icon"
                style={{
                    width: '140px',
                    height: 'auto',
                    position: 'absolute',
                    top: '632px',
                    right: '14.8%',
                    transform: 'translateX(-50%)',
                    zIndex: '0',
                }}
            />

            <img
                src={IsolationMode1}
                alt="Background Icon"
                style={{
                    width: '110px',
                    height: 'auto',
                    position: 'absolute',
                    top: '220px',
                    left: '21.9%',
                    transform: 'translateX(-50%)',
                    zIndex: '0',
                }}
            />

            <h2 className="feature-title">Features For Any Organization</h2>
            <div className="feature-scroll-menu">
                <button className="scroll-button left-button" onClick={scrollLeft}>
                    {"<"}
                </button>

                <div className="feature-buttons" ref={scrollRef}>
                    {featuresData.map((feature, index) => (
                        <button
                            key={index}
                            className={`feature-button ${activeFeature === feature.name ? "active" : ""}`}
                            onClick={() => setActiveFeature(feature.name)}
                        >
                            <div className="feature-content">
                                <img src={feature.icon} alt={`${feature.name} Icon`} className="feature-icon" />
                                <span>{feature.name}</span>
                            </div>
                        </button>
                    ))}
                </div>

                <button className="scroll-button right-button" onClick={scrollRight}>
                    {">"}
                </button>
            </div>

            <FeatureDetailsSection featureName={activeFeature} />
        </div>
    );

    // Function to render the old section
    const renderOldSection = () => {
        const sliderRef = useRef(null);

        useEffect(() => {
            const slider = sliderRef.current;

            if (slider) {
                // Clone the slides to create an infinite loop effect
                const cloneFirst = slider.firstElementChild.cloneNode(true);
                const cloneLast = slider.lastElementChild.cloneNode(true);
                slider.appendChild(cloneFirst);
                slider.insertBefore(cloneLast, slider.firstElementChild);

                let scrollAmount = 0;
                const slideWidth = slider.firstElementChild.clientWidth;
                const totalSlides = slider.children.length;

                const startSlider = () => {
                    setInterval(() => {
                        scrollAmount += 1;
                        if (scrollAmount >= slideWidth * (totalSlides - 1)) {
                            scrollAmount = 0;
                            slider.style.transition = "none";
                        } else {
                            slider.style.transition = "transform 0.2s linear";
                        }
                        slider.style.transform = `translateX(-${scrollAmount}px)`;
                    }, 20);
                };

                startSlider();
            }
        }, []);

        return (
            <div>
                <h2 className="sl-slider-text">Features for ANY organization</h2>
                <div className="services-section">
                    <div className="service-card">
                        <img src="/main-images/hp/contractors.png" alt="Service Icon" />
                        <h3>Contractor Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/copy.png" alt="Service Icon" />
                        <h3>Document Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/risk-management.png" alt="Service Icon" />
                        <h3>Incident Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/corrective.png" alt="Service Icon" />
                        <h3>Inspection Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/quality-control.png" alt="Service Icon" />
                        <h3>Training Record Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/settings.png" alt="Service Icon" />
                        <h3>Customizable Automations</h3>
                    </div>
                    {/* <div className="service-card">
                        <img src="/main-images/hp/identification.png" alt="Service Icon" />
                        <h3>Hazard Management</h3>
                    </div> */}
                    <div className="service-card">
                        <img src="/main-images/hp/action.png" alt="Service Icon" />
                        <h3>Corrective Actions</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/inventory.png" alt="Service Icon" />
                        <h3>Inventory Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/excavator.png" alt="Service Icon" />
                        <h3>Asset Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/compliant.png" alt="Service Icon" />
                        <h3>Onboarding Orientation</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/execute.png" alt="Service Icon" />
                        <h3>Task Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/emergency.png" alt="Service Icon" />
                        <h3>Emergency Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/qr-code.png" alt="Service Icon" />
                        <h3>QR Code Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/connection.png" alt="Service Icon" />
                        <h3>Data & Integrations</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/data-analytics.png" alt="Service Icon" />
                        <h3>Analytics</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/timer.png" alt="Service Icon" />
                        <h3>Time Tracking</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/report.png" alt="Service Icon" />
                        <h3>Reporting</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/notification.png" alt="Service Icon" />
                        <h3>Auto Notifications</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/secret-file.png" alt="Service Icon" />
                        <h3>Private Forms</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/check.png" alt="Service Icon" />
                        <h3>Easy Mobile App</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/file.png" alt="Service Icon" />
                        <h3>Compliance Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/restriction.png" alt="Service Icon" />
                        <h3>Flagged User Management</h3>
                    </div>
                    <div className="service-card">
                        <img src="/main-images/hp/watch.png" alt="Service Icon" />
                        <h3>Wearable Integrations</h3>
                    </div>
                </div>
                <div className="sl-slider">
                    <div className="sl-slide-track" ref={sliderRef}>
                        <div className="slide"><img src={Selco} alt="Selco-Logo" height="75" width="200" style={{ marginRight: "35px" }} /></div>
                        <div className="slide"><img src={Hardrock} alt="Hardrock-Logo" height="75" width="200" /></div>
                        <div className="slide"><img src={State} alt="State-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Astro} alt="Astro-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Protech} alt="Protech-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Summit} alt="Summit-Logo" height="70" width="220" /></div>
                        <div className="slide"><img src={BOT} alt="BOT-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Dayson} alt="Dayson-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Instuform} alt="Instuform-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={TWBecker} alt="TWBecker-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Ambler} alt="Ambler-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Northern} alt="Northern-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={TMG} alt="TMG-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Empipe} alt="Empipe-Logo" height="100" width="250" /></div>
                        <div className="slide"><img src={Newman} alt="Newman-Logo" height="100" width="250" /></div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {showNewVersion ? renderNewSection() : renderOldSection()}
        </div>
    );
}