import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import useDelete from '../../../shared/components/useDelete'
import usePatch from '../../../shared/components/usePatch'
import { useFetchInfiniteForKanban } from '../../../shared/components/userFetch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import { actionIcons } from '../../ActionIcons'
import EditAuditAction from './EditAuditAction'
import CreateActionItem from './CreateActionItem'

const statuses = [
  {
    key: 'To Do',
    value: 'to_do',
    icon: (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6.75841" cy="6.53282" r="5.93419" fill="#6929C4" />
      </svg>
    ),
    color: '#6929C4',
  },
  {
    key: 'In Progress',
    value: 'in_progress',
    icon: (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6.29259" cy="6.53282" r="5.93419" fill="#9F1853" />
      </svg>
    ),
    color: '#9F1853',
  },
  {
    key: 'Completed',
    value: 'completed',
    icon: (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6.8297" cy="6.53282" r="5.93419" fill="#00BA25" />
      </svg>
    ),
    color: '#00BA25',
  },
  {
    key: 'Rejected',
    value: 'rejected',
    icon: (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6.36583" cy="6.53282" r="5.93419" fill="#FF3030" />
      </svg>
    ),
    color: '#FF3030',
  },
]

const Task = ({ task, isArchive, tasks, setData, baseData, setEdit }) => {
  const { setNodeRef, attributes, listeners, isDragging, transform } =
    useDraggable({
      id: task.id.toString(),
    })
  const { mutate: mutateToArchive, isLoading: isArchiving } = useDelete(
    '/api/v2/actions_management/actions'
  )
  const { mutate: mutateToRestore, isLoading: isRestoring } = usePatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {
    permissions: {
      allow_delete = true,
      allow_edit = true,
      allow_view = true,
    } = {},
  } = baseData || {}
  const open = Boolean(anchorEl)
  const handleOnMouseOver = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuItemClick = (action) => {
    handleClose()
    const payload = {
      id: task.id,
    }
    if (action === 'archive') {
      const oldData = [...tasks]
      setData((prev) => ({
        ...prev,
        data: prev.data.filter((e) => Number(e.id) !== Number(task.id)),
      }))
      mutateToArchive(payload, {
        onSuccess: () => {
          showToast({ message: 'Action Deactivated Successfully' })
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
          setData((prev) => ({ ...prev, data: oldData }))
        },
      })
    }
    if (action === 'edit') {
      setEdit(task.id)
    }
    if (action === 'restore') {
      const oldData = [...tasks]
      setData((prev) => ({
        ...prev,
        data: prev.data.filter((e) => Number(e.id) !== Number(task.id)),
      }))
      mutateToRestore(
        { id: task.id },
        {
          onSuccess: () => {
            showToast({ message: 'Restored Successfully' })
          },
          onError: (e) => {
            showToast({
              type: 'error',
              message: typeof e === 'string' ? e : e.errors.toString() || '',
            })
            setData((prev) => ({ ...prev, data: oldData }))
          },
        },
        `/api/v2/actions_management/actions/${task.id}/restore`
      )
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      onClick={(e) => e.stopPropagation()}
      style={{
        ...styles.task,
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'move' : 'pointer',
        transform: CSS.Translate.toString(transform),
      }}
    >
      <Stack spacing={1}>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <div>
            {actionIcons.priority[task.priority]}{' '}
            <span style={{ textTransform: 'capitalize', color: '#5C5C5C' }}>
              {task.priority}
            </span>
          </div>
          <div>
            <IconButton aria-label="settings" onClick={handleOnMouseOver}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_11066_4348)">
                  <rect
                    x="0.00683594"
                    y="23.7871"
                    width="23.7368"
                    height="23.7368"
                    rx="1.97806"
                    transform="rotate(-90 0.00683594 23.7871)"
                    fill="#ECECEC"
                  />
                  <path
                    d="M12.8638 11.9182C12.8638 11.372 12.421 10.9292 11.8748 10.9292C11.3285 10.9292 10.8857 11.372 10.8857 11.9182C10.8857 12.4644 11.3285 12.9072 11.8748 12.9072C12.421 12.9072 12.8638 12.4644 12.8638 11.9182Z"
                    fill="#5C5C5C"
                    stroke="#5C5C5C"
                    stroke-width="1.48355"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.7867 11.9182C19.7867 11.372 19.3439 10.9292 18.7976 10.9292C18.2514 10.9292 17.8086 11.372 17.8086 11.9182C17.8086 12.4644 18.2514 12.9072 18.7976 12.9072C19.3439 12.9072 19.7867 12.4644 19.7867 11.9182Z"
                    fill="#5C5C5C"
                    stroke="#5C5C5C"
                    stroke-width="1.48355"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.94095 11.9182C5.94095 11.372 5.49815 10.9292 4.95192 10.9292C4.4057 10.9292 3.96289 11.372 3.96289 11.9182C3.96289 12.4644 4.4057 12.9072 4.95192 12.9072C5.49815 12.9072 5.94095 12.4644 5.94095 11.9182Z"
                    fill="#5C5C5C"
                    stroke="#5C5C5C"
                    stroke-width="1.48355"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11066_4348">
                    <rect
                      x="0.00683594"
                      y="23.7871"
                      width="23.7368"
                      height="23.7368"
                      rx="1.97806"
                      transform="rotate(-90 0.00683594 23.7871)"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              variant="menu"
            >
              {!isArchive && allow_view && (
                <MenuItem onClick={() => handleMenuItemClick('view')}>
                  <Link to={`/actions-notices/view/${task.id}`}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {UserGroupIcons.view}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        View
                      </span>
                    </Box>
                  </Link>
                </MenuItem>
              )}
              {!isArchive && allow_edit && (
                <MenuItem onClick={() => handleMenuItemClick('edit')}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.edit}{' '}
                    <span style={{ paddingLeft: 5, color: '#080808' }}>
                      Edit
                    </span>
                  </Box>
                </MenuItem>
              )}
              {!isArchive && allow_delete ? (
                <MenuItem onClick={() => handleMenuItemClick('archive')}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.archive}{' '}
                    <span style={{ paddingLeft: 5, color: '#080808' }}>
                      Archive
                    </span>
                  </Box>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleMenuItemClick('restore')}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {UserGroupIcons.archive}{' '}
                    <span style={{ paddingLeft: 5, color: '#080808' }}>
                      Restore
                    </span>
                  </Box>
                </MenuItem>
              )}
            </Menu>
          </div>
        </Stack>
        <Stack spacing={1} {...listeners}>
          <div style={{ color: '#080808', fontSize: 14, fontWeight: 700 }}>
            {task.label}
          </div>
          <div style={{ color: '#5C5C5C', fontSize: 12 }}>
            {task.action_type}
          </div>
          <Stack direction="row" justifyContent="space-between">
            <Stack spacing={1}>
              <Typography style={{ color: '#909090', fontSize: '10px' }}>
                {' '}
                Assigned To:{' '}
              </Typography>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
              >
                {task.assigned_users.map((user, index) =>
                  user.user_info && user.user_info.avatar ? (
                    <img
                      width="20px"
                      height="20px"
                      key={index}
                      title={user.user_info.key}
                      alt={user.user_info.key}
                      src={user.user_info.avatar}
                      style={{ objectFit: 'cover', borderRadius: '5px' }}
                    />
                  ) : (
                    <div
                      title={user.user_info.key}
                      key={index}
                      style={{
                        width: '20px',
                        height: '20px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      {user.user_info.key.substr(0, 1)}
                    </div>
                  )
                )}
                {task.assigned_users.length > 0 && (
                  <Typography
                    style={{
                      color: '#407797',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {' '}
                    +{task.undisplayed_users_count}
                  </Typography>
                )}
              </div>
            </Stack>
            <Stack spacing={1}>
              <Typography style={{ color: '#909090', fontSize: '10px' }}>
                {' '}
                Created By:{' '}
              </Typography>
              {task.creator_info && task.creator_info.avatar ? (
                <img
                  title={task.creator_info && task.creator_info.key}
                  width="20px"
                  height="20px"
                  alt={task.creator_info && task.creator_info.key}
                  src={task.creator_info && task.creator_info.avatar}
                  style={{ objectFit: 'cover', borderRadius: '5px' }}
                />
              ) : (
                <div
                  title={task.creator_info && task.creator_info.key}
                  style={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                  }}
                >
                  {task.creator_info && task.creator_info.key.substr(0, 1)}
                </div>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  )
}

const Column = ({
  status,
  tasks,
  isFetching,
  isArchive,
  setData,
  baseData,
  setEdit,
}) => {
  const { setNodeRef } = useDroppable({ id: status.value })
  const filteredTask = useMemo(() => {
    return tasks.filter((task) => task.status === status.value)
  }, [tasks])

  return (
    <div ref={setNodeRef} style={styles.column}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack gap="10px" direction="row" alignItems="center">
          {status.icon}
          <h4 style={{ color: status.color, margin: 0 }}>{status.key}</h4>
        </Stack>
        <h6 style={{ color: '#5C5C5C', fontSize: '12px', margin: 0 }}>
          {filteredTask.length} Actions List
        </h6>
      </Box>
      {filteredTask.map((task) => (
        <Task
          tasks={tasks}
          setData={setData}
          key={task.id}
          task={task}
          isArchive={isArchive}
          baseData={baseData}
          setEdit={setEdit}
        />
      ))}
      {isFetching && (
        <Skeleton
          variant="text"
          width="100%"
          height={200}
          sx={{ margin: 'auto' }}
        />
      )}
    </div>
  )
}

const ActionKanbanBoard = ({
  boardState,
  setBoardState,
  isArchive,
  baseData,
  create,
  setCreate
}) => {
  const [edit, setEdit] = useState('');
  const { page, selectedGroup, searchText } = boardState
  const {
    data: boardResponse = {},
    isLoading,
    isFetching,
    setData,
  } = useFetchInfiniteForKanban(
    !isArchive
      ? `/api/v2/actions_management/actions/kanban_board?group_id=${selectedGroup}&page=${page}&limit=20${
          searchText ? `&label_cont=${searchText}` : ''
        }`
      : `/api/v2/actions_management/actions/kanban_board/archived?group_id=${selectedGroup}&page=${page}&limit=20${
          searchText ? `&label_cont=${searchText}` : ''
        }`
  )
  const { mutate, isLoading: isMutating } = usePatch()
  const loadMoreObserver = useRef(null)
  const { metaData: { next = null } = {}, data = [] } = boardResponse || {}

  const handleDragEnd = (event) => {
    const { active, over } = event
    let isChanged = false
    if (over && !isMutating && !isArchive) {
      const updatedTasks = data.map((task) => {
        if (task.id.toString() === active.id && over.id !== task.status) {
          isChanged = true
          return { ...task, status: over.id }
        }
        return task
      })
      if (isChanged) {
        const oldData = [...data]
        setData((prev) => ({ ...prev, data: updatedTasks }))
        const payload = {
          audit_action: {
            status: over.id,
          },
        }
        mutate(
          payload,
          {
            onSuccess: () => {
              showToast({ message: 'Updated Successfully' })
            },
            onError: (e) => {
              showToast({
                type: 'error',
                message: typeof e === 'string' ? e : e.errors.toString() || '',
              })
              setData((prev) => ({ ...prev, data: oldData }))
            },
          },
          `/api/v2/actions_management/actions/${active.id}/update_status`
        )
      }
    }
  }
  const optimisticUpdate = (dataToUpdate, userAction) => {
    if (userAction === 'edit') {
        const updatedTasks = data.map((task) => {
            if (task.id === dataToUpdate.id) {
              return dataToUpdate;
            }
            return task
        })
        setData((prev) => ({ ...prev, data: updatedTasks }))
    }
    if (userAction === 'create' && Number(selectedGroup) === Number(dataToUpdate.group_id || '')) {
        setData((prev) => ({ ...prev, data: [dataToUpdate, ...data] }))
    }
  }
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetching) {
        setBoardState((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    })

    if (loadMoreObserver.current) {
      observer.observe(loadMoreObserver.current)
    }

    return () => {
      if (loadMoreObserver.current) {
        observer.unobserve(loadMoreObserver.current)
      }
    }
  }, [isFetching, setBoardState])
  useEffect(() => {
    setData((prev) => ({...prev, data: []}));
  }, [selectedGroup])
  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <DndContext onDragEnd={handleDragEnd}>
        <div style={styles.board}>
          {statuses.map((status) => (
            <Column
              key={status.value}
              setData={setData}
              status={status}
              tasks={data}
              isFetching={isFetching}
              isArchive={isArchive}
              baseData={baseData}
              setEdit={setEdit}
            />
          ))}
        </div>
      </DndContext>
      {next && <div ref={loadMoreObserver} />}
      {!!edit && (
        <EditAuditAction edit={edit} setEdit={setEdit} optimisticUpdate={optimisticUpdate} />
      )}
      {create && (
        <CreateActionItem
          open={create}
          setOpen={setCreate}
          optimisticUpdate={optimisticUpdate}
        />
      )}
    </>
  )
}

const styles = {
  board: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  column: {
    width: '24%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  task: {
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
}

export default ActionKanbanBoard
