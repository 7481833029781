import { Box, Button, CircularProgress, FormControl, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from '../../../shared/components/showNotification';
import useModify from '../../../shared/components/userModify';
import useUpdate from '../../../shared/components/useUpdate';
import useFetch from '../../../shared/components/userFetch';
import Loader from '../../../shared/components/loader';

const ActionTypeForm = ({ initialValues = null, mutate, onClose = () => false, refetch, setFilterState, isMutating }) => {
    const isEdit = !!initialValues;
    const defaultValues = isEdit ? { ...initialValues } : {
        label: '',
        description: ''
    };
    const { control, register, handleSubmit, setValue, trigger, formState: { errors } } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
        const payload = {
            action_type: {
                ...data
            }
        }

        if (isEdit) {
            payload.id = initialValues.id;
        }
        mutate(payload, {
            onSuccess: () => {
                showToast({ message: isEdit ? "Action Type Updated Successfully" : "Action Type Created Successfully" });
                onClose();
                if (refetch && isEdit) {
                    refetch();
                }
                if (!isEdit) {
                    setFilterState({page: 1, limit: 20})
                }
            },
            onError: (e) => {
                showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });        
              }
        });
    };
    return <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
            {/*Title */}
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                           Label <span style={{ color: 'red' }}>*</span>
                        </Typography>
                    </Box>
                    <TextField
                        placeholder="Create label"
                        size="small"
                        {...register('label', { required: 'Label is required' })}
                        error={!!errors.label}
                        helperText={errors.label ? errors.label.message : ''}
                    />
                </FormControl>
            </Grid>
            {/* Description */}
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Description</Typography>
                    </Box>
                    <TextField
                        placeholder="Enter Description"
                        size="small"
                        multiline
                        rows={4}
                        {...register('description')}
                    />
                </FormControl>
            </Grid>
            </Grid>
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <div>
                <Button style={{ textTransform: 'none', backgroundColor: '#ECECEC', color: '#5C5C5C', marginRight: '10px', outline: 'none' }} onClick={onClose}>
                    Cancel
                </Button>
                <Button endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} style={{ textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none' }} disabled={isMutating} type="submit" variant="contained" color="primary">
                    {isEdit ? 'Update' : 'Create'}
                </Button>
            </div>
        </Box>
    </form>
}

export const ActionTypeCreate = ({refetch, setFilterState, onClose}) => {
    const {mutate, isLoading: isMutating, error} = useUpdate('/api/v2/actions_management/action_types');
    return <ActionTypeForm refetch={refetch} setFilterState={setFilterState} isMutating={isMutating} mutate={mutate} onClose={onClose} />;
}

export const ActionTypeEdit = ({id, setFilterState, refetch, onClose}) => {
    const {data: actionData, isLoading} = useFetch(`/api/v2/actions_management/action_types/${id}`);
    const {mutate, isLoading: isMutating, error} = useModify('/api/v2/actions_management/action_types');
    const {data = {}} = actionData || {};
    if (isLoading) {
        return <Loader />;
    }
    return <ActionTypeForm initialValues={data} refetch={refetch} setFilterState={setFilterState} isMutating={isMutating} onClose={onClose} mutate={mutate} />;
}