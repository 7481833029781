import React from 'react';
import PlaceholderImage from '../../../../assets/images/placeholder_image.png';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';

export default function FeaturesSection({ headerText, subText, ctaLink = "https://www.go.safetyloop.com/contact-us#anchor", ctaButtonTitle = "Learn More", featureHighlights = [], featureImageSrc = PlaceholderImage, sectionAlign = "image-left", sectionBackground = "white" }) {

    const imageSection = <div className="section-image-container">
        <img src={featureImageSrc} alt="" />
    </div>

    const textSection = <div className="section-text-container">
        <h2><b>{headerText}</b></h2>
        <h4>{subText}</h4>
        <ul>
            {featureHighlights.map(x => (<li>{x}</li>))}
        </ul>
        <a href={ctaLink} target="_blank">
            <button type="button" className="section-cta-btn">{ctaButtonTitle}</button>
        </a>
    </div>
    const iframeData = [
        {
          id: 'gdm-vp-snippet-quotations_551a9898-98fa-4999-be9f-705eb1e298ae',
          src: 'https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/551a9898-98fa-4999-be9f-705eb1e298ae',
        },
        {
          id: 'gdm-vp-snippet-quotations_5feac281-6934-4528-a6f8-6fb4a146b4e5',
          src: 'https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/5feac281-6934-4528-a6f8-6fb4a146b4e5',
        },
        {
          id: 'gdm-vp-snippet-quotations_9610541f-8960-42ca-b052-ce194e851e6c',
          src: 'https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/9610541f-8960-42ca-b052-ce194e851e6c',
        },
        {
          id: 'gdm-vp-snippet-quotations_29b43d9d-85ea-4243-97fe-bb35dc852797',
          src: 'https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/29b43d9d-85ea-4243-97fe-bb35dc852797',
        },
        {
            id: 'gdm-vp-snippet-quotations_29b43d9d-85ea-4243-97fe-bb35dc852797',
            src: 'https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/29b43d9d-85ea-4243-97fe-bb35dc852797',
          },
          {
            id: 'gdm-vp-snippet-quotations_29b43d9d-85ea-4243-97fe-bb35dc852797',
            src: 'https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/29b43d9d-85ea-4243-97fe-bb35dc852797',
          },

      ];
    const UIElements = [];

    if (sectionAlign == "image-left") {
        UIElements.push(imageSection);
        UIElements.push(textSection);
    } else {
        UIElements.push(textSection);
        UIElements.push(imageSection);
    }

    function RandomIframe({ iframeDataArray }) {
        // Select a random iframe from the array
        const randomIndex = Math.floor(Math.random() * iframeDataArray.length);
        const randomIframe = iframeDataArray[randomIndex];
      
        return (
          <iframe
            id={randomIframe.id}
            src={randomIframe.src}
            frameBorder="0"
            style={{
              height: '92vw',
              width: '100%',
              minHeight: '456px',
              minWidth: '500px',
              maxHeight: '100%',
              overflow: 'hidden',
            }}
            scrolling="yes"
          ></iframe>
        );
      }

    return (
        <div className='features-section reviews' style={{ backgroundColor: "#e6e8e8", height:'690px' }}>
            <div className='section-container'>

               
                
            
                <div className='row'>
                    <div className='col-md-7'>
                        <a href="https://www.getapp.com/operations-management-software/safety-management/category-leaders/"> <img border="0" src="https://brand-assets.getapp.com/badge/93e34287-22e5-4f42-a6ea-ca3e2b73cb0b.png" width="300" /> </a>
                        <a href="https://www.capterra.com/safety-management-software/shortlist/"> <img border="0" src="https://brand-assets.capterra.com/badge/07a3dfef-abe2-4dda-a137-fb09a8800dc3.png" width="300" /> </a>
                        <a href="https://www.softwareadvice.com/safety-management/#frontrunners"> <img border="0" src="https://brand-assets.softwareadvice.com/badge/0354c5ec-8e73-4d4a-a2a4-b43cc7bceb76.png" width="280" /> </a>
                    </div>
                    <div className='col-md-5'>

                        <RandomIframe iframeDataArray={iframeData} />
                    </div>
                </div>
            
                
            </div>
        </div>
    )
}
