import React from 'react'
import ActionsList from './ActionsList'
// import ActionKanbanBoard from "./ActionsKanbanBoard";
import { Box } from '@mui/material'
import Loader from '../../../shared/components/loader'
import ActionKanbanWrapper from './ActionKanbanWrapper'

const ActionsNoticesListWrapper = ({
  handleOnFilterChange,
  filterData,
  filterState,
  setFilterState,
  handleSort,
  initialState,
  setEdit,
  baseData,
  actionsCount,
  view,
  setView,
  kanbanCreate,
  setKanbanCreate
}) => {
  const { groups = [] } = baseData || {}
  if (view === 'board' && !groups.length) {
    return <Loader />
  }
  return (
    <Box style={{ border: '1px solid #D3D3D3', background: '#fff' }}>
      {view === 'list' ? (
        <ActionsList
          handleOnFilterChange={handleOnFilterChange}
          filterData={filterData}
          filterState={filterState}
          setFilterState={setFilterState}
          handleSort={handleSort}
          initialState={initialState}
          setEdit={setEdit}
          baseData={baseData}
          setView={setView}
          actionsCount={actionsCount}
        />
      ) : (
        <ActionKanbanWrapper
          setView={setView}
          baseData={baseData}
          create={kanbanCreate}
          setCreate={setKanbanCreate}
        />
      )}
    </Box>
  )
}

export default ActionsNoticesListWrapper
