import { useState } from 'react'

const useFilterChange = (initalState) => {
  const [filterState, setFilterState] = useState({
    ...initalState,
    sort: {},
    activeFilters: {},
  })
  const filterData = { ...filterState }
  delete filterData.page
  delete filterData.limit
  delete filterData.activeFilters
  delete filterData.extraState
  delete filterData.sort
  const handleOnFilterChange = (e, name) => {
    console.log(e, name)
    if (typeof e === 'string' || typeof e === 'number') {
      setFilterState((pre) => ({
        ...pre,
        [name]: !e || e === 'All' ? undefined : e,
        activeFilters: {
          ...pre.activeFilters,
          [name]: !e || e === 'All' ? undefined : e,
        },
        page: 1,
      }))
      return
    }
    if (typeof e === 'object' && !Array.isArray(e)) {
      setFilterState((pre) => ({
        ...pre,
        [name]: !e.value || e.value === 'All' ? undefined : e.value,
        activeFilters: {
          ...pre.activeFilters,
          [name]: !e.value || e.value === 'All' ? undefined : e.label,
        },
        page: 1,
      }))
      return
    }
    if (Array.isArray(e)) {
      setFilterState((pre) => ({
        ...pre,
        [name]: !e[0] || !e[1] ? undefined : { start: e[0], end: e[1] },
        activeFilters: {
          ...pre.activeFilters,
          [name]: !e.length ? undefined : e.join(' - '),
        },
        page: 1,
      }))
      return
    }
    setFilterState((pre) => ({
      ...pre,
      [e.target.id]: e.target.value || undefined,
      page: 1,
    }))
  }
  const handleSort = (fieldName) => {
    const isExist = filterState.sort.name === fieldName
    setFilterState((prev) => ({
      ...prev,
      sort: {
        name: fieldName,
        value: isExist && filterState.sort.value === 'asc' ? 'desc' : 'asc',
      },
    }))
  }
  return {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  }
}

export default useFilterChange
