import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Application from "../../../../config/ApplicationWrapper";
import Upload from "../../../../assets/images/Upload.svg";
import DocsFav from "../../../../assets/images/form.svg";
import { Modal, FormControl as Input } from "react-bootstrap";
import FormHelperText from "@mui/material/FormHelperText";
import Cookies from "js-cookie";
import { Button, Autocomplete, Tabs, Tab } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";

import axios from "axios";

export default function Documents(props) {
  const { show, closepopup, data, parentId } = props;
  const UPLOAD_FILE_TAB = "1";
  const SELECT_EXISTING_TAB = "2";
  const ADD_FOLDER_TAB = "3";
  const cookieDetails = Cookies.get("token");
  const [onSubmit, setSubmit] = useState(false);
  const [onApiCalling, setOnApiCalling] = useState(false);
  const [docsName, setDocsName] = useState("");
  const [documents, setDocuments] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [docsType, setDocsType] = useState("");
  const [fileName, setFileName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [existingCompanyDocuments, setExistingCompanyDocuments] = useState([]);
  const [parentFolderId, setParentFolderId] = useState();
  const [selectedCompanyDocument, setSelectedCompanyDocument] = useState({
    int_id: "",
    name: "",
  });
  const [selectedTab, setSelectedTab] = useState(UPLOAD_FILE_TAB);

  useEffect(() => {
    if (show) {
      fetchCompanyDocuments();
    }
  }, [show]);

  useEffect(() => {
    if (data && data.id) {
      setDocsName(data.doc_name);
      setImageFile(data.doc_detail ? data.doc_detail.file_url : "");
      setFileName(data.doc_detail ? data.doc_detail.file_name : "");
      let docsType = data.doc_detail
        ? data.doc_detail.file_name.split(".")
        : "";
      if (
        docsType[1] == "png" ||
        docsType[1] == "jpg" ||
        docsType[1] == "jpeg"
      ) {
        setDocsType("image");
      } else {
        setDocsType("docs");
      }
       if (data.is_shared_doc_detail) {
        setSelectedCompanyDocument({
          int_id: data.doc_detail.id,
          name: data.doc_detail.file_name,
        });
        setSelectedTab(SELECT_EXISTING_TAB);
      } else if(data.doc_type == "folder"){ 
        setSelectedTab(ADD_FOLDER_TAB);
        setFolderName(data.folder_name)
      }
    } else {
      resetForm();
    }
  }, [data]);

  const resetForm = () => {
    setSelectedTab(UPLOAD_FILE_TAB);
    setDocsType("");
    setFileName("");
    setDocsName("");
    setImageFile("");
    setDocuments("");
    setImageChanged(false);
    setOnApiCalling(false);
    setSubmit(false);
    setFolderName("");
    setSelectedCompanyDocument({
      int_id: "",
      name: "",
    });
  };

  const imageUpload = (value) => {
    setImageFile(value ? URL.createObjectURL(value) : "");
    setDocuments(value);
    setImageChanged(true);
  };

  const updateDocs = async () => {
    try {
      setSubmit(true);
      setOnApiCalling(true); 

      let getAssetId = localStorage.getItem("assetInfo");
      let assetId = getAssetId ? JSON.parse(getAssetId) : "";

      let apiUrl;
      let method;

      if (!imageFile && !selectedCompanyDocument.int_id && !folderName) {
        return false;
      }

      let newAssetId;
      if (parentId) {
        newAssetId = parentId;
      } else if (assetId && assetId.asstId) {
        newAssetId = assetId.asstId;
      }

      if (data && data.id) {
        apiUrl = `${Application.api_url}/api/v2/asset_trackings/${newAssetId}/documents/${data.id}`;
        method = "PUT";
      } else {
        apiUrl = `${Application.api_url}/api/v2/asset_trackings/${newAssetId}/documents`;
        method = "POST";
        if (!documents && !selectedCompanyDocument) {
          return false;
        }
      }

      var assetData = localStorage.getItem("asset_data");
      assetData = JSON.parse(assetData);

      const formData = new FormData();
      if (imageChanged === true && isUploadVisible()) {
        formData.append("file", documents);
      } else if (!isUploadVisible() && !folderName && selectedCompanyDocument) {
        formData.append("company_document_id", selectedCompanyDocument.int_id);
      } else if (folderName) {
        formData.append("folder_name", folderName);
      }
      assetData && assetData.id  && formData.append("parent_id", assetData.id);
      // assetData && assetData.doc_detail && formData.append("doc_parent_id", assetData.doc_detail.id);
      // formData.append("doc_name", "Testing");

      axios.defaults.headers.common["Authorization"] = cookieDetails;
      const result = await axios({
        method: method,
        url: apiUrl,
        data: formData,
      });

      if (result && result.status === 200) {
        resetForm();
        closepopup(Date.now());
      }
    } catch (err) {
      console.log("err===>", err);
    } finally {
      setOnApiCalling(false);
    }
  };

  const fetchCompanyDocuments = async () => {
    axios.defaults.headers.common["Authorization"] = cookieDetails;
    const result = await axios({
      method: "GET",
      url: `${Application.api_url}/api/v2/company_documents`,
      params: {
        limit: 1000,
        file_only: true,
      },
    });
    setExistingCompanyDocuments(result.data.data);

    const docsDetails = await axios({
      method: "GET",
      url: `${Application.api_url}/api/v2/company_documents`,
      params: {
        is_asset_doc_eq: true,
        file_only: true,
      },
    });

    setParentFolderId(docsDetails.data.data.length > 0 ? docsDetails.data.data[0].parent_id
      : "");

  };

  const isUploadVisible = () => {
    return selectedTab === "1";
  };

  const isImage = (mimeType) => {
    return (
      mimeType === "image/png" ||
      mimeType === "image/jpg" ||
      mimeType === "image/jpeg"
    );
  };

  const fetchSelectedFile = async (id) => {
    axios.defaults.headers.common["Authorization"] = cookieDetails;
    axios({
      method: "GET",
      url: `${Application.api_url}/api/v2/company_documents/${id}`,
    }).then((result) => {
      const file = result.data.data.file;
      if (file && isImage(file.type)) {
        setDocsType("image");
        setImageFile(file.url);
      } else {
        setDocsType("docs");
        setImageFile(DocsFav);
      }
      setFileName(file ? file.name : result.data.data.name);
    });
  };
  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
        setDocsName("");
        setImageFile("");
        setDocuments("");
        setImageChanged(false);
        setSubmit(false);
      }}
      className="create-maintanance-popup docs-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <b> {data && data.id ? "Edit" : "Add"} Documents </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-form">
          {/* <div className="row">
            <div className="col-md-12">
              <label>
                Document Name <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!docsName && onSubmit}
                helperText={
                  !docsName && onSubmit ? "Document Name is required" : ""
                }
                value={docsName}
                onChange={(e) => {
                  setDocsName(e.target.value);

                }}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <TabContext value={selectedTab}>
                <TabList
                  aria-label="File Upload Tabs"
                  onChange={(e, value) => {
                    console.log(value);
                    setSelectedTab(value);
                  }}
                >
                  {data && data.id ? selectedTab == "UPLOAD_FILE_TAB" ?
                    <Tab
                      label="Upload File"
                      value={UPLOAD_FILE_TAB}
                      wrapped={true}
                    />
                    : "" : <Tab
                    label="Upload File"
                    value={UPLOAD_FILE_TAB}
                    wrapped={true}
                  />}
                  {data && data.id ? selectedTab == "ADD_FOLDER_TAB" ?
                    <Tab
                      label="Add Folder"
                      value={ADD_FOLDER_TAB}
                      wrapped={true}
                    />
                    : "" : <Tab
                    label="Add Folder"
                    value={ADD_FOLDER_TAB}
                    wrapped={true}
                  />}
                  {data && data.id ? selectedTab == "SELECT_EXISTING_TAB" ?
                    <Tab
                      label="Select Existing"
                      value={SELECT_EXISTING_TAB}
                      wrapped={true}
                    />
                    : "" : <Tab
                    label="Select Existing"
                    value={SELECT_EXISTING_TAB}
                    wrapped={true}
                  />}
                </TabList>
                <TabPanel value={UPLOAD_FILE_TAB}>
                  <label>
                    Upload Documents <text style={{ color: "red" }}>*</text>
                  </label>
                  <div className="create-asset-upload">
                    <div>
                      <Button className="uploadButton" component="label">
                        <img src={Upload} alt="" /> Upload Docs
                        <input
                          style={{ display: "none" }}
                          hidden
                          type="file" 
                          multiple=""
                          onChange={(e) => {
                            imageUpload(e.target.files[0]);
                            setFileName(e.target.files[0].name);
                            setDocsType(
                              e.target.files[0].type == "image/png" ||
                                e.target.files[0].type == "image/jpg" ||
                                e.target.files[0].type == "image/jpeg"
                                ? "image"
                                : "doc"
                            );
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                  <FormHelperText
                    className="inputErrorMsg"
                    error={
                      !imageFile && onSubmit === true && isUploadVisible()
                        ? true
                        : false
                    }
                  >
                    {!imageFile && onSubmit === true && isUploadVisible()
                      ? "Document is required"
                      : ""}
                  </FormHelperText>
                  <div className="row">
                    <div className="col-md-12">
                      {imageFile && docsType == "image" && (
                        <div className="uploadedImage" style={{ textAlign: "center" }}>
                          <img
                            src={imageFile ? imageFile : ""}
                            alt=""
                            className="assetimage"
                          />
                          <p style={{ fontSize: "12px", marginLeft: "14px" }}>
                            {fileName}
                            <i
                              className="fa fa-trash text-red clickable ml-2"
                              onClick={(e) => {
                                imageUpload("");
                              }}
                            ></i>
                          </p>
                        </div>
                      )}

                      {imageFile && docsType != "image" && (
                        <div className="uploadedImage">
                          <img src={DocsFav} alt="" className="assetimage" />
                          <p style={{ fontSize: "12px", marginLeft: "14px" }}>
                            {fileName}
                            <i
                              className="fa fa-trash text-red clickable ml-2"
                              style={{ bottom: "38px" }}
                              onClick={(e) => {
                                imageUpload("");
                              }}
                            ></i>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={ADD_FOLDER_TAB}>
                  <label>
                    {data && data.id ? "Update" : "Add New"} Folder <text style={{ color: "red" }}>*</text>
                  </label>
                  <TextField
                    required
                    fullWidth
                    className="text-field"
                    id="outlined-required"
                    error={!folderName && onSubmit}
                    helperText={
                      !folderName && onSubmit ? "Folder Name is required" : ""
                    }
                    value={folderName}
                    onChange={(e) => {
                      setFolderName(e.target.value);
                      setSelectedCompanyDocument({
                        int_id: "",
                        name: "",
                      });
                    }}
                  />
                </TabPanel>
                <TabPanel value={SELECT_EXISTING_TAB}>
                  <Autocomplete
                    id="document-tags"
                    options={existingCompanyDocuments}
                    getOptionLabel={(options) => {
                      return options.name;
                    }}
                    value={selectedCompanyDocument}
                    isOptionEqualToValue={(option, value) =>
                      option.int_id === value.int_id
                    }
                    onChange={(event, value) => {
                      fetchSelectedFile(value.slug).then((res) => { });
                      setSelectedCompanyDocument(value);
                      setFolderName("");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Document *" error={!selectedCompanyDocument.int_id && onSubmit && !isUploadVisible()} />
                    )}
                  />
                  <FormHelperText
                    className="inputErrorMsg"
                    error={
                      !selectedCompanyDocument.int_id &&
                      onSubmit &&
                      !isUploadVisible()
                    }
                  >
                    {!selectedCompanyDocument.int_id &&
                      onSubmit &&
                      !isUploadVisible()
                      ? "Document is required"
                      : ""}
                  </FormHelperText>
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            resetForm();
            closepopup();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={onApiCalling}
          onClick={(event) => {
            updateDocs();
          }}
        >
          {data && data.id ? "Update" : "+ Add"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
