import React, { createContext, useEffect, useState } from 'react'
import { Nav, NavItem, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CloseButton from '../../../../assets/images/CloseMenu.svg'
import LeftArrow from '../../../../assets/images/LeftArrow.svg'
import SideNav from '../../../../components/SideNav'
import AssetDocumnets from './AssetDocuments'
import CreateAssetInfo from './CreateAssetInfo'
import GeoLocation from './GeoLocation'
import AssetMaintance from './Maintenance'

export const AssetCreateContext = createContext()

const CreateAsset = () => {
  const [tabKey, setTabKey] = useState('1')

  useEffect(() => {
    let getAssetId = localStorage.getItem('assetInfo')
    if (getAssetId) {
      let assetInfo = JSON.parse(getAssetId)
      if (assetInfo.step) {
        setTabKey(assetInfo.step)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav />
          <div className="content-wrap asset-tracking-wrap risk-assessment create-new-asset">
            <div className="title-tab-wrap">
              <h1>
                <img src={CloseButton} alt="" className="header-closer-img" />
                <b>Asset Tracking</b>
              </h1>
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#risk-assessment">
                    Asset Tracking
                  </a>
                </li>
              </ul>
            </div>
            <div className="asset-tracking-create-column">
              <div className="create-body">
                <div className="create-title">
                  <Link to="/asset-tracking">
                    <img src={LeftArrow} alt="" />
                  </Link>
                  &nbsp;
                  <span className="create-header"> Create Asset </span>
                </div>
                <div className="create-pages">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                    activeKey={tabKey}
                  >
                    <div className="clearfix asset-create-new">
                      <div className="create-tab">
                        <Nav bsStyle="pills" stacked className="steppers-step">
                          <NavItem
                            eventKey="1"
                            onClick={(e) => {
                              setTabKey('1')
                            }}
                            className={
                              tabKey && tabKey > 1
                                ? 'asset-lables tab-completed'
                                : 'asset-lables'
                            }
                          >
                            <div className="test">
                              <div className="tab-content"></div>
                            </div>
                            Asset Information
                          </NavItem>
                          {/* <NavItem
                              eventKey="2"
                              onClick={(e) => {
                                setTabKey("2");
                              }}
                              className={
                                tabKey && tabKey > 2
                                  ? "asset-lables tab-completed"
                                  : "asset-lables"
                              }
                            >
                              <div className="test">
                                <div className="tab-content"></div>
                              </div>
                              Add Related Assets
                            </NavItem> */}
                          <NavItem
                            eventKey="3"
                            onClick={(e) => {
                              setTabKey('3')
                            }}
                            className={
                              tabKey && tabKey > 3
                                ? 'asset-lables tab-completed'
                                : 'asset-lables'
                            }
                          >
                            <div className="test">
                              <div className="tab-content"></div>
                            </div>
                            Maintenance Schedule
                          </NavItem>
                          <NavItem
                            eventKey="4"
                            onClick={(e) => {
                              setTabKey('4')
                            }}
                            className={
                              tabKey && tabKey > 4
                                ? 'asset-lables tab-completed'
                                : 'asset-lables'
                            }
                          >
                            <div className="test">
                              <div className="tab-content"></div>
                            </div>
                            Geo Location Tracking
                          </NavItem>
                          <NavItem
                            eventKey="5"
                            onClick={(e) => {
                              setTabKey('5')
                            }}
                          >
                            <div className="test last-check">
                              <div className="tab-content"></div>
                            </div>
                            Asset Documents
                          </NavItem>
                        </Nav>
                      </div>
                      <AssetCreateContext.Provider value={{ setTabKey }}>
                        <div className="create-tab-pages">
                          <Tab.Content animation>
                            <Tab.Pane eventKey="1" className="completed">
                              <CreateAssetInfo />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="2">
                              <RelatedAssetInfo />
                            </Tab.Pane> */}
                            <Tab.Pane eventKey="3">
                              <AssetMaintance />
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                              <GeoLocation />
                            </Tab.Pane>
                            <Tab.Pane eventKey="5">
                              <AssetDocumnets />
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </AssetCreateContext.Provider>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateAsset
