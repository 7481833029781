import {
  Autocomplete,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import ActiveFilters from '../../../shared/components/activeFilters'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
} from '../../../shared/components/table'
import useFetch from '../../../shared/components/userFetch'
// import BulkAssignJobProfile from "./bulkAssignJobProfile";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setActionPageCount } from '../../../../reducers/common'
import AlertDialog from '../../../shared/components/alretDialog'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'
import { actionIcons } from '../../ActionIcons'

const TableWrapper = styled('div')`
  .action_name,
  .asset_name,
  .action_priority_name,
  .asset_sub_category,
  .asset_notices_status,
  .asset_action_type,
  .asset_group,
  .asset_created_by,
  asset_due_date,
  .asset_created_at
`

const AssetArchieveList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  actionsCount,
  setActionPageCount,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/actions_management/asset_actions/archived', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    '/api/v2/actions_management/asset_actions/base_data?activated_eq=false'
  )
  const { mutate, isLoading: isRestoring } = usePatch()
  const [deleteItem, setDeleteItem] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}

  useEffect(() => {
    metadata.count &&
      setActionPageCount({
        asset_actions: {
          asset_list: actionsCount.asset_actions.asset_list,
          asset_archive:
            metadata.count || actionsCount.asset_actions.asset_archive,
        },
      })
  }, [metadata.count])

  const {
    action_types = [],
    priorities = [],
    sub_categories = [],
    statuses = [],
    groups = [],
    permissions: { allow_restore = true } = {},
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const subCategoryFilterData = [
    { label: 'All', value: 'All' },
    ...sub_categories.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]
  const actionTypeFilterData = [
    { label: 'All', value: 'All' },
    ...action_types.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]

  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }
  const handleOk = () => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Restored Successfully' })
          callRefetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/actions_management/asset_actions/${deleteItem.id}/restore`
    )
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }

  const headers = [
    <TableCell>
      <HeaderItem
        title="Action Name"
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Asset Name"
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Priority"
        isSort
        handleSort={() => handleSort('priority')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              priorities.find((e) => e.value === filterData['priority_eq']) ||
              'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'priority_eq')
            }
            options={[
              { label: 'All', value: 'All' },
              ...priorities.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Sub Category"
        isSort
        handleSort={() => handleSort('sub_category_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              subCategoryFilterData.find(
                (e) => e.value === filterData['sub_category_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'sub_category_id_eq')
            }
            options={subCategoryFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Status"
        isSort
        handleSort={() => handleSort('status')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              statuses.find((e) => e.value === filterData['status_eq']) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'status_eq')
            }
            options={[
              { label: 'All', value: 'All' },
              ...statuses.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Action Types"
        isSort
        handleSort={() => handleSort('action_type_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              actionTypeFilterData.find(
                (e) => e.value === filterData['action_type_id_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'action_type_id_eq')
            }
            options={actionTypeFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Created By"
        isSort
        handleSort={() => handleSort('created_by')}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Due"
        isSort
        handleSort={() => handleSort('archived_eq')}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = data.map((ele, index) => {
    const {
      asset_name,
      issue,
      priority,
      sub_category,
      status,
      status_label,
      action_type,
      action_type_name,
      created_by,
      created_at,
      due_at = '',
    } = ele || {}
    return (
      <TableRow key={index}>
        <TableCell>
          <div className="flex" style={{ alignItems: 'center' }}>
            <span>{ele.forced && actionIcons.forced}</span>
            <div className="flex flex-c">
              <Link to={`/assets-actions/view/${ele.id}`}>
                <div
                  style={{
                    color: '#080808',
                    fontWeight: '700',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {issue}
                </div>
              </Link>
            </div>
          </div>
        </TableCell>
        <TableCell>{asset_name || ''} </TableCell>
        <TableCell>{priority || ''}</TableCell>
        <TableCell>{sub_category}</TableCell>
        <TableCell>
          <span className={`asset_status ${status}`}>{status_label}</span>
        </TableCell>
        <TableCell style={{ minWidth: '275px' }}>{action_type}</TableCell>
        <TableCell>{created_by}</TableCell>
        <TableCell>{created_at}</TableCell>
        <TableCell>{due_at}</TableCell>
        <TableCell className="right" style={{ minWidth: '100px' }}>
          <ActionMenu>
            {(setClose) => {
              const menuItems = []
              if (allow_restore) {
                menuItems.push(
                  <MenuItem onClick={() => handleRestore(ele, setClose)}>
                    Restore
                  </MenuItem>
                )
              }
              return menuItems
            }}
          </ActionMenu>
        </TableCell>
      </TableRow>
    )
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Restore Asset acion"
        description="Are You sure you want to restore this asset action?"
        okText="Restore"
      />
      <TableWrapper>
        <CustomTable
          headers={headers}
          rows={rows}
          renderActiveFilter={
            <ActiveFilters
              filterData={filterState}
              update={setFilterState}
              keyName="action_notices"
            />
          }
        />
        {!!metadata.count && (
          <CustomPagination
            totalPage={metadata.last}
            lastPage={metadata.last}
            currentPage={filterState.page}
            handlePageChange={(_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }}
            rowOptions={[20, 50, 100]}
            rowPerPage={filterState.limit}
            handleRowPerPageChange={(targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }}
          />
        )}
      </TableWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setActionPageCount: (value) => dispatch(setActionPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetArchieveList)
