import React from 'react';
import Loader from '../../../shared/components/loader';
import useFetch from '../../../shared/components/userFetch';
import ActionKanbanWrapper from './ActionKanbanWrapper';

const ActionNoticesArchiveKanbanWrapper = ({setView}) => {
    const { data: baseData, isLoading: baseDataLoading } = useFetch(
        '/api/v2/actions_management/actions/base_data?activated_eq=false'
      )
      if (baseDataLoading) {
        return <Loader />
      }
      return <ActionKanbanWrapper setView={setView} baseData={baseData} isArchive />
}

export default ActionNoticesArchiveKanbanWrapper;
