import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { showToast } from '../../../shared/components/showNotification'
import useFetch from '../../../shared/components/userFetch'
import useModify from '../../../shared/components/userModify'
import useUpdate from '../../../shared/components/useUpdate'
import Loader from '../../../shared/components/loader'

const SubCategoryTypeForm = ({
  initialValues = null,
  mutate,
  onClose = () => false,
  refetch,
  setFilterState,
  isMutating,
}) => {
  const isEdit = !!initialValues
  const defaultValues = isEdit
    ? { ...initialValues }
    : {
        label: '',
      }
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const onSubmit = (data) => {
    const payload = {
      sub_category: {
        ...data,
      },
    }

    if (isEdit) {
      payload.id = initialValues.id
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({
          message: isEdit
            ? 'Sub Category Updated Successfully'
            : 'Sub Category Created Successfully',
        })
        onClose()
        if (refetch && isEdit) {
          refetch()
        }
        if (!isEdit) {
          setFilterState({ page: 1, limit: 20 })
        }
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/*Title */}
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Label <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              placeholder="Create label"
              size="small"
              {...register('label', { required: 'Label is required' })}
              error={!!errors.label}
              helperText={errors.label ? errors.label.message : ''}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box
        mt={2}
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
      >
        <div>
          <Button
            style={{
              textTransform: 'none',
              backgroundColor: '#ECECEC',
              color: '#5C5C5C',
              marginRight: '10px',
              outline: 'none',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            endIcon={
              isMutating ? <CircularProgress size={16} color="inherit" /> : null
            }
            style={{
              textTransform: 'none',
              backgroundColor: '#407797',
              color: '#fff',
              outline: 'none',
            }}
            disabled={isMutating}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </div>
      </Box>
    </form>
  )
}

export const SubCategoryTypeCreate = ({ refetch, setFilterState, onClose }) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useUpdate('/api/v2/actions_management/sub_categories')
  return (
    <SubCategoryTypeForm
      refetch={refetch}
      mutate={mutate}
      setFilterState={setFilterState}
      onClose={onClose}
      isMutating={isMutating}
    />
  )
}

export const SubCategoryTypeEdit = ({
  id,
  refetch,
  setFilterState,
  onClose,
}) => {
  const { data: categoryData, isLoading } = useFetch(
    `/api/v2/actions_management/sub_categories/${id}`
  )
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useModify('/api/v2/actions_management/sub_categories')
  const { data = {} } = categoryData || {}
  if (isLoading) {
    return <Loader />;
  }
  return (
    <SubCategoryTypeForm
      initialValues={data}
      refetch={refetch}
      setFilterState={setFilterState}
      onClose={onClose}
      isMutating={isMutating}
      mutate={mutate}
    />
  )
}
