import React, { useEffect, useRef } from 'react'

export default function PDFViewer({ pdfUrl }) {
  const iframeRef = useRef(null)
  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = pdfUrl
    }
  }, [pdfUrl])
  return (
    <iframe
      ref={iframeRef}
      src={pdfUrl}
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="PDF Viewer"
    ></iframe>
  )
}
