import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { showToast } from '../../../../shared/components/showNotification'

export default function SignatureModal(props) {
  const { signature, closepopup, status, mutate, ratId, id, page } = props
  const [signatureType, setSignatureType] = React.useState('write')
  const [image, setImage] = React.useState(null)
  const signPad = useRef(null)
  const basePath = `/api/v2/actions_management`
  const type = page === 'asset' ? 'asset_actions' : 'actions'
  const actionNotesType = page === 'asset' ? 'asset' : 'audit'

  const apiEndpoint = `${basePath}/${type}/${id}/update_${actionNotesType}_action_status/${ratId}`

  const onhandleStatusClose = () => {
    closepopup()
  }

  const clear = () => {
    signPad.current.clear()
  }

  const submit = async () => {
    let photoparams
    if (signatureType === 'write') {
      photoparams = signPad.current.getTrimmedCanvas().toDataURL('image/png')
    } else {
      photoparams = image
    }

    try {
      const formData = new FormData()
      if(page === 'asset') {
        formData.append('assign_asset_action[status]', status)
        formData.append('assign_asset_action[signature]', photoparams)
      }
      else {
        formData.append('audit_action_user[status]', status)
        formData.append('audit_action_user[signature]', photoparams)
      }
      const payload = formData
      mutate(
        payload,
        {
          onSuccess: () => {
            showToast({ message: 'Action status has been changed' })
            closepopup(true)
          },
        },
        apiEndpoint
      )
    } catch (err) {
      console.error('Error:', err)
    }
  }

  return (
    <Modal
      open={signature}
      onClose={onhandleStatusClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" mb={2}>
          <b>Add Signature</b>
        </Typography>
        <FormControl component="fieldset" fullWidth>
          <FormLabel>Signature Type</FormLabel>
          <RadioGroup
            row
            name="signature-type"
            value={signatureType}
            onChange={(e) => setSignatureType(e.target.value)}
          >
            <FormControlLabel
              value="write"
              control={<Radio />}
              label="Signature"
            />
            <FormControlLabel
              value="upload"
              control={<Radio />}
              label="Upload Signature"
            />
          </RadioGroup>
        </FormControl>

        {signatureType === 'upload' && (
          <Box mt={2}>
            <FormLabel>Upload Signature</FormLabel>
            <input
              type="file"
              onChange={(event) => setImage(event.target.files[0])}
              style={{ display: 'block', marginTop: '8px' }}
            />
          </Box>
        )}

        {signatureType === 'write' && (
          <Box mt={2}>
            <FormLabel>Signature</FormLabel>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 1,
                backgroundColor: '#cccccc',
                mt: 1,
                mb: 2,
              }}
            >
              <SignatureCanvas
                penColor="black"
                backgroundColor="transparent"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: 'sigCanvas',
                }}
                ref={signPad}
              />
            </Box>
            <Box display="flex" gap={2}>
              <Button variant="outlined" color="error" onClick={clear}>
                Clear Signature
              </Button>
            </Box>
          </Box>
        )}

        <Box mt={4} display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" onClick={onhandleStatusClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={submit}>
            Mark As Completed
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
