import { styled } from "@mui/material";

const FormWrapper = styled("div")`
  input,
  textarea {
    font-size: 14px;
    &::placeholder {
      font-style: italic;
    }
  }
  .f-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #909090;
    &.d-label {
      color: #909090;
    }
  }
  .grey-box {
    background-color: #f6f6f6;
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 4px;
  }
  .f-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 80%;
    background-color: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    padding: 4px;
  }
  .MuiSwitch-thumb {
    color: #407797;
  }
  .MuiStepLabel-alternativeLabel {
    cursor: pointer;
  }
  .tag {
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.03px;
    display: flex;
    height: 24px;
    padding: 0px 8px;
    align-items: center;
    gap: 2px;
    border-radius: 32px;
    background: #08ade1;
  }
`;

export default FormWrapper;
