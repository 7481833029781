import { Box } from '@mui/material'
import React from 'react'
import ActionArchieveList from './ActionArchieveList'
import ActionNoticesArchiveKanbanWrapper from './ActionNoticesAtchiveKanbanWrapper';

const ActionsNoticesArchiveWrapper = ({
  handleOnFilterChange,
  filterData,
  filterState,
  setFilterState,
  handleSort,
  initialState,
  view,
  actionsCount,
  setView,
}) => {
  return (
    <Box style={{ border: '1px solid #D3D3D3', background: '#fff' }}>
      {view === 'list' ? (
                <ActionArchieveList
                    handleOnFilterChange={handleOnFilterChange}
                    filterState={filterData}
                    filterData={filterState}
                    setFilterState={setFilterState}
                    handleSort={handleSort}
                    initialState={initialState}
                    view={view}
                    setView={setView}
                    actionsCount={actionsCount}
                />
            ) : (
                <ActionNoticesArchiveKanbanWrapper setView={setView} />
            )}
    </Box>
  )
}

export default ActionsNoticesArchiveWrapper
