import React from "react";
import SideNav from "../../components/SideNav";
import SearchFilter from "./SearchFilter";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DNDLayout from "./DNDLayout";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import QuickLinks from "./QuickLinks";
import QuickSettings from "./QuickSettings";
import Cookies from 'js-cookie';
// import html2canvas from 'html2canvas';

const initialState = {
  query: {
    template_id: "",
    group_id: "",
    subcontractor_id: "",
    auditor: "",
    hourly_chart: "",
    chart_type: "",
    date: "",
    date_range: "",
    start_date: "",
    end_date: "",
    group_type: ""
  },
  userDetails: "",
  role: "",
  minimize: false,
  tab: "1"
};

class AnalyticsDashboard extends React.Component {
  state = initialState;

  componentDidMount() {
    let getUser = localStorage.getItem("user");
    getUser = JSON.parse(getUser);
    let userRole = localStorage.getItem("role");
    userRole = JSON.parse(userRole); 
    this.setState({ role: userRole })
    this.setState({ userDetails: getUser })
    this.setState({ minimize: getUser.dashboard_positions && getUser.dashboard_positions.banner ? JSON.parse(getUser.dashboard_positions.banner) : false })
    Cookies.set('current_link', 'main_dashboard_link');
    Cookies.set('parent_link', 'dashboard_parent_link');
  }

  onFilterSelect = (fields) => {
    this.setState({ query: fields });
  };

  onFilterReset = () => {
    this.setState({ query: initialState.query });
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  minimizeBanner = (value) => {
    let getUser = localStorage.getItem("user");
    getUser = JSON.parse(getUser);
    getUser.dashboard_positions.banner = value;
    this.setState({ minimize: value });
    let newObj = {
      user: {
        dashboard_positions: getUser.dashboard_positions,
      },
    };

    $.ajax({
      type: "PATCH",
      url: "/entity_management/update_dashboard_positions",
      dataType: "json",
      data: newObj,
      error: function (error) {
        console.log("error")
      },
      success: function (res) {
        console.log("updated successfully")
      },
    });

    localStorage.setItem("user", JSON.stringify({ ...this.state.userDetails, ...{ dashboard_positions: getUser.dashboard_positions } }))
  }

  render() {
    const { query, tab, minimize, userDetails, role } = this.state;

    return (
      <React.Fragment>
        <div className="msmcontainer dashboard-container">
          <div className="row d-flex">
            <SideNav />
            <div className="content-wrap col-sm-12">
              <div className="filter-wrap card filter-wrap-unique">
                <div className={minimize ? "display-hide" : "forms-group-filter-banner"}>
                  <div className="welcome-dash-message">
                    <p> 
                      <span style={{fontWeight:'400'}} >{userDetails && userDetails.first_name.charAt(0).toUpperCase() + userDetails.first_name.slice(1)} {userDetails && userDetails.last_name.charAt(0).toUpperCase() + userDetails.last_name.slice(1)}! </span>Welcome to <span style={{fontWeight:"400"}}>SafetyLoop</span></p>
                    <div className="welcome-dash-sub">
                      Hey! We are glad to see you again.
                    </div>
                  </div>
                </div>
                <div className={minimize ? "welcome-dash-message maximize" : "display-hide"}>
                  <p>👋 <b>{userDetails && userDetails.first_name.charAt(0).toUpperCase() + userDetails.first_name.slice(1)} {userDetails && userDetails.last_name.charAt(0).toUpperCase() + userDetails.last_name.slice(1)}! </b>Welcome to <b>SafetyLoop</b></p>
                  <div className="welcome-dash-sub">
                    Hey! We are glad to see you again.
                  </div>
                </div>
                <div className="minimize" onClick={(e) => { this.minimizeBanner(!minimize) }}>
                  <p>{minimize ? "+" : "-"}</p>
                </div>
              </div>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                    <Tab label="My SafetyLoop" value="1" />
                    <Tab label="My Analytics" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="my-saftyloop">
                    <QuickLinks />
                    {role && role.name == "Admin" &&
                      <QuickSettings />
                    }
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <SearchFilter query={query}
                    onFilterSelect={this.onFilterSelect}
                    onFilterReset={this.onFilterReset} />
                  <DndProvider backend={HTML5Backend}>
                    <DNDLayout query={query} onFilterSelect={this.onFilterSelect} />
                  </DndProvider>
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AnalyticsDashboard;
