import React from 'react';
import FormWrapper from './formWrapper';
import { Box, Typography, Modal } from '@mui/material';

const CustomModal = ({open = false, width, height, handleClose = () => false, overrideStyle={}, children, title}) => {
    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width || '50%',
            height: height || "50%",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.30)",
            borderRadius: "12px",
            p: 2,
            ...overrideStyle
          }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <Typography style={{fontSize: 24, fontWeight: 700}}>{title}</Typography>
                <div style={{cursor: 'pointer'}} onClick={handleClose}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10562_14328)">
                        <path d="M21.5679 10.4326L10.4326 21.5679M10.4326 10.4326L21.5679 21.5679L10.4326 10.4326Z" stroke="#080808" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_10562_14328">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            {children}
          </Box>
        </FormWrapper>
      </Modal>
    )

}

export default CustomModal;