//ActionsNoticesWrapper
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ExportCSV from '../../../shared/components/exportCsv'
import SubTabs from '../../../shared/components/subTabs'
import { ButtonCreate } from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import useFetch from '../../../shared/components/userFetch'
import ActionsNoticesArchiveWrapper from './ActionNoticesArchiveWrapper'
import ActionsNoticesListWrapper from './ActionsNoticesListWrapper'
import CreateActionItem from './CreateActionItem'
import EditAuditAction from './EditAuditAction'

const subTabs = {
  action_notices: [
    {
      label: 'Actions List',
      value: 'actions_list',
    },
    {
      label: 'Archived',
      value: 'actions_archive',
    },
  ],
}

const ActionsNoticesWrapper = ({ title, active, actionsCount = {} }) => {
  const initialState = {
    page: 1,
    limit: 20,
  }
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch('/api/v2/actions_management/actions/base_data')
  const [view, setView] = useState('list')
  const {
    data: iframeData,
    isLoading: iframeLoading,
    refetch: iframeRefetch,
  } = useFetch('/api/v2/actions_management/actions/metabase_iframe')

  const {
    permissions: { allow_create = true, allow_to_export_actions = true } = {},
  } = baseData || {}

  const [create, setCreate] = useState(false)
  const [kanbanCreate, setKanbanCreate] = useState(false)
  const [edit, setEdit] = useState('')
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState)
  const {
    handleOnFilterChange: handleOnFilterChangeArchive,
    filterState: filterStateArchive,
    filterData: filterDataArchive,
    setFilterState: setFilterStateArchive,
    handleSort: handleSortArchive,
  } = useFilterChange(initialState)
  const refetch = () => {
    setFilterState({ ...filterState })
  }

  const { iframe_url = '' } = iframeData || {}

  const sendStylesToIframe = () => {
    const iframe = document.getElementById('iframeChart')

    iframe.contentWindow.postMessage(
      {
        type: 'applyStyles',
        styles: { footer: 'background-color: #333; display: none;' },
      },
      '*'
    )
  }

  return (
    <>
      <iframe
        src={iframe_url}
        style={{ width: '100%', height: '30vh', border: 'none' }}
        title="Chart Viewer"
        id="iframeChart"
        onLoad={sendStylesToIframe}
      />
      <SubTabs
        tabList={subTabs[active] || []}
        withTitle
        title={title}
        pageCount={actionsCount.action_notices}
        extras={[
          <div style={{ display: 'flex', gap: '10px' }}>
            {allow_to_export_actions && (
              <ExportCSV
                url={'/api/v2/actions_management/actions'}
                fileName="actions"
                params={{
                  ...filterData,
                }}
              />
            )}
            {allow_create && (
              <ButtonCreate label="Create" onClick={() => view === 'list' ? setCreate(true) : setKanbanCreate(true)} />
            )}
          </div>,
        ]}
      >
        {(activeKey) => (
          <>
            {activeKey === 'actions_list' && (
              <>
                <ActionsNoticesListWrapper
                  handleOnFilterChange={handleOnFilterChange}
                  filterData={filterData}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  handleSort={handleSort}
                  initialState={initialState}
                  setEdit={setEdit}
                  baseData={baseData}
                  actionsCount={actionsCount}
                  view={view}
                  setView={setView}
                  kanbanCreate={kanbanCreate}
                  setKanbanCreate={setKanbanCreate}
                />
              </>
            )}
            {activeKey === 'actions_archive' && (
              <>
                <ActionsNoticesArchiveWrapper
                  handleOnFilterChange={handleOnFilterChangeArchive}
                  filterData={filterDataArchive}
                  filterState={filterStateArchive}
                  setFilterState={setFilterStateArchive}
                  handleSort={handleSortArchive}
                  initialState={initialState}
                  actionsCount={actionsCount}
                  view={view}
                  setView={setView}
                />
              </>
            )}
          </>
        )}
      </SubTabs>
      {create && (
        <CreateActionItem
          open={create}
          setOpen={setCreate}
          setFilterState={setFilterState}
          refetch={refetchBaseData}
        />
      )}
      {!!edit && (
        <EditAuditAction edit={edit} setEdit={setEdit} refetch={refetch} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}

export default connect(mapStateToProps)(ActionsNoticesWrapper)
