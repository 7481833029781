import React from 'react';
import { Controller, useForm } from "react-hook-form";
import FormWrapper from "../../../shared/components/formWrapper";
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Typography } from '@mui/material';
import usePatch from '../../../shared/components/usePatch';
import { showToast } from '../../../shared/components/showNotification';

const StatusChange = ({selectedRows = [], onCancel, refetch, setSelectedRows, baseData}) => {
    const {mutate, isLoading: isMutating} = usePatch();
    const {statuses: statusOptions = []} = baseData || {};
    const { control, register, handleSubmit, setValue, trigger, formState: { errors } } = useForm({
        defaultValues: {
            status: ''
        }
    });
    const onSubmit = (data) => {
        const payload = {
            audit_action: {
                status: data.status,
                action_ids: selectedRows
            }
        }
        mutate(payload,
            {onSuccess: () => {
                onCancel();
                showToast({message: 'Status updated successfully'});
                refetch();
                setSelectedRows([]);
            }}, '/api/v2/actions_management/actions/bulk_update_status'
        )
    }

    return (
        <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)} style={{height: 'calc(100% - 55px)', paddingInline: '24px'}}>
                <Box sx={{ mt: 2 }} style={{ overflowY: 'auto', height: 'calc(100% - 100px)' }}>
                    <div style={{background: '#F6F6F6', padding: '8px'}}>
                        <Typography variant='body3'>Selected Actions: <span style={{fontWeight: 700}}>{selectedRows.length}</span></Typography>
                    </div>
                    <FormControl fullWidth>
                        <Box mb={1}>
                            <Typography variant="body1" className='f-label'>Status</Typography>
                        </Box>
                        <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select
                                    size='small'
                                    {...field}
                                    {...register('status', { required: 'Status required' })} 
                                    displayEmpty
                                    error={!!errors.status}
                                >
                                    <MenuItem value="">
                                        <em>Select Status</em>
                                    </MenuItem>
                                    {statusOptions.map(u => (
                                        <MenuItem value={u.value}>
                                            {u.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                    <Button style={{textTransform: 'none', backgroundColor: '#ECECEC', color: '#5C5C5C', marginRight: '10px', outline: 'none'}} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} style={{textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none'}} disabled={isMutating} type="submit" variant="contained" color="primary">
                        Update
                    </Button>
                </Box>
            </form>
        </FormWrapper>
    )
}
export default StatusChange;
