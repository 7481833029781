import React from 'react'
import useFetch from '../../../shared/components/userFetch'
import useModify from '../../../shared/components/userModify'
import NewActionItem from './NewActionItem'

const EditAuditAction = ({ edit, setEdit, refetch, optimisticUpdate }) => {
  const { data, isLoading } = useFetch(
    `/api/v2/actions_management/actions/${edit}?view_type=form`
  )
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useModify('/api/v2/actions_management/actions')
  if (isLoading) {
    return null
  }
  return (
    <NewActionItem
      mutate={mutate}
      open={!!edit}
      setOpen={setEdit}
      initialValues={data.data}
      refetch={refetch}
      isMutating={isMutating}
      optimisticUpdate={optimisticUpdate}
    />
  )
}

export default EditAuditAction
