import { createTheme, ThemeProvider } from '@mui/material'
import React from 'react'
import PlainTabs from '../shared/components/plainTabs'
import { actionIcons } from './ActionIcons'
import ActionsNoticesWrapper from './Components/ActionsNotices/ActionsNoticesWrapper'
import AssetActionsWrapper from './Components/AssetActions/AssetActionsWrapper'
import ConfigWrapper from './Components/Configuration/ConfigWrapper'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, system-ui, -apple-system', // Change font family globally
  },
})

const tabList = [
  {
    label: 'Actions',
    value: 'action_notices',
  },
  {
    label: 'Asset Actions',
    value: 'asset_actions',
  },
  {
    label: 'Configuration',
    value: 'config',
  },
]

const ActionsManagement = () => {
  //   const tabList = [];

  //   if (allow_view_company_users) {
  //     tabList.push({
  //       label: "Actions and Notices",
  //       value: "action_notices",
  //     });
  //   }
  //   if (allow_view_company_job_profiles) {
  //     tabList.push({
  //       label: "Asset Actions",
  //       value: "asset_actions",
  //     });
  //   }
  //   if (allow_view_company_groups) {
  //     tabList.push({
  //       label: "Configuration",
  //       value: "config",
  //     });
  //   }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={{ paddingTop: 20 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '30px',
              paddingBottom: '10px',
            }}
          >
            {actionIcons.action}
            <span
              style={{
                fontSize: '24px',
                color: '#080808',
                fontWeight: '700',
                padding: '0 5px',
              }}
            >
              Actions and Notices
            </span>
          </div>
          <PlainTabs tabList={tabList}>
            {(active) => {
              const title = tabList.find((e) => e.value === active).label
              return (
                <>
                  <div style={{ padding: '20px 32px', background: '#F6F6F6' }}>
                    {active === 'action_notices' && (
                      <ActionsNoticesWrapper title={title} active={active} />
                    )}
                    {active === 'asset_actions' && (
                      <AssetActionsWrapper title={title} active={active} />
                    )}
                    {active === 'config' && (
                      <ConfigWrapper title={title} active={active} />
                    )}
                  </div>
                </>
              )
            }}
          </PlainTabs>
        </div>
      </ThemeProvider>
    </>
  )
}

export default ActionsManagement
