import React from 'react'
import useUpdate from '../../../shared/components/useUpdate'
import NewAssetAction from './NewAssetAction'

const CreateAssetAction = ({ open, setOpen, setFilterState, refetch }) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useUpdate('/api/v2/actions_management/asset_actions')
  return (
    <NewAssetAction
      open={open}
      mutate={mutate}
      setOpen={setOpen}
      setFilterState={setFilterState}
      refetch={refetch}
      isMutating={isMutating}
    />
  )
}

export default CreateAssetAction
