import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import usePatch from '../../../shared/components/usePatch';
import { showToast } from '../../../shared/components/showNotification';

const BulkArchive = ({selectedRows = [], onCancel, refetch, setSelectedRows}) => {
    const {mutate, isLoading: isMutating} = usePatch();
    const onSubmit = () => {
        const payload = {
            audit_action: {
                action_ids: selectedRows
            }
        }
        mutate(payload,
            {onSuccess: () => {
                onCancel();
                showToast({message: 'Actions archived successfully'});
                refetch();
                setSelectedRows([]);
            }}, '/api/v2/actions_management/actions/bulk_archive'
        )
    }

    return (
        <>
        <div style={{background: '#F6F6F6', padding: '8px'}}>
            <Typography variant='body3'>Selected Actions: <span style={{fontWeight: 700}}>{selectedRows.length}</span></Typography>
        </div>
        <p style={{color: '#5C5C5C'}}>Are you sure want to archive this <span style={{fontWeight: 700}}>Selected Actions?.</span> This will move all to the Archived.</p>
        <Box mt={2} sx={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
            <Button style={{textTransform: 'none', backgroundColor: '#ECECEC', color: '#5C5C5C', marginRight: '10px', outline: 'none'}} onClick={onCancel}>
                Cancel
            </Button>
            <Button onClick={onSubmit} endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} style={{textTransform: 'none', backgroundColor: '#D92929', color: '#fff', outline: 'none'}} disabled={isMutating} variant="contained" color="primary">
                Archive
            </Button>
        </Box>
        </>
    )
}
export default BulkArchive;
