import { Box, styled, Typography } from '@mui/material'
import React from 'react'

// Styled Components
const ViewPageWrapper = styled('div')`
  background: #fff;
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;

  .header {
    background: #f6f6f6;
  }
  .v-container {
    width: 90%;
    margin: 0 auto;
    padding: 24px 0px;
  }
  .tab-container {
    width: 90%;
    margin: 0 auto;
  }
`

const ActionViewTabList = [
  { label: 'Action Details', value: 'ad' },
  { label: 'Notes / Audit Trail', value: 'nat' },
]

// NoData Component
const NoData = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="400px"
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <svg
        width="52"
        height="71"
        viewBox="0 0 52 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9101_39195)">
          <path
            d="M0.212835 68.2301V31.671L4.02095 20.2379C4.31344 19.3598 5.1348 18.7676 6.05976 18.7676H17.4045V25.2191C17.4045 26.4069 18.3665 27.3695 19.5534 27.3695H32.4473C33.6342 27.3695 34.5962 26.4069 34.5962 25.2191V18.7676H45.9409C46.8659 18.7676 47.6872 19.3598 47.9797 20.2379L51.7879 31.671V46.7252V46.7515V42.4241V68.2303C51.7879 69.418 50.8259 70.3807 49.639 70.3807H2.36077C1.17389 70.3807 0.211914 69.418 0.211914 68.2303L0.212835 68.2301ZM2.47792 31.671H4.51092V25.5683L2.47792 31.671ZM6.65978 31.671H15.2556V40.2729C15.2556 41.4606 16.2179 42.4237 17.4048 42.4237H34.5961C35.7829 42.4237 36.7453 41.4606 36.7453 40.2729V31.671H45.3411V20.9181H36.7453V25.2192C36.7453 27.5946 34.8209 29.5204 32.4472 29.5204H19.5533C17.1796 29.5204 15.2552 27.5946 15.2552 25.2192V20.9181H6.65941L6.65978 31.671ZM47.4901 31.671H49.5231L47.4901 25.5683V31.671ZM13.1067 33.8214H2.36151V68.2302H49.6397V33.8214H38.8945V40.2729C38.8945 42.6483 36.9701 44.5741 34.5964 44.5741H17.4052C15.0315 44.5741 13.1071 42.6483 13.1071 40.2729L13.1067 33.8214ZM22.7772 61.7787H31.373C31.9666 61.7787 32.4476 62.26 32.4476 62.8537C32.4476 63.4477 31.9666 63.9291 31.373 63.9291H22.7772C22.1839 63.9291 21.7025 63.4477 21.7025 62.8537C21.7025 62.26 22.1839 61.7787 22.7772 61.7787ZM22.7772 57.4775H35.671C36.2643 57.4775 36.7457 57.9589 36.7457 58.5529C36.7457 59.1466 36.2643 59.6279 35.671 59.6279H22.7772C22.1839 59.6279 21.7025 59.1466 21.7025 58.5529C21.7025 57.9589 22.1839 57.4775 22.7772 57.4775ZM7.73458 57.4775H18.4789C19.0725 57.4775 19.5535 57.9589 19.5535 58.5529V62.8537C19.5535 63.4477 19.0725 63.9291 18.4789 63.9291H7.73458C7.14097 63.9291 6.65997 63.4477 6.65997 62.8537V58.5529C6.65997 57.9589 7.14097 57.4775 7.73458 57.4775ZM8.80883 59.6279V61.7787H17.405V59.6279H8.80883Z"
            fill="#909090"
          />
          <path
            d="M23.9537 1.42913C23.9537 0.849938 23.4916 0.380615 22.9213 0.380615C22.3514 0.380615 21.8893 0.849938 21.8893 1.42913V14.171C21.8893 14.7499 22.3514 15.2192 22.9213 15.2192C23.4916 15.2192 23.9537 14.7499 23.9537 14.171V1.42913Z"
            fill="#909090"
          />
        </g>
      </svg>
      <Typography>No Data Available</Typography>
    </Box>
  </Box>
)

export { ActionViewTabList, NoData, ViewPageWrapper }
