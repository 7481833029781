import { Tab, Tabs, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

const AntTabs = styled(Tabs)`
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  &.MuiTabs-indicator {
    background-color: #407797;
    height: 1px;
    font-weight: 600;
  }
  padding-right: 32px;
  padding-left: 32px;
  .MuiTab-root {
    border-bottom: 1px solid #5c5c5c;
  }
  &.no_padding {
    padding-right: 0px;
    padding-left: 0px;
  }
  &.isViewTab {
    padding-right: 0px;
    padding-left: 0px;
    border-top: 0;
    border-bottom: 1px solid #5c5c5c;
    background: #f6f6f6;
    .MuiTabs-scroller {
      width: 90%;
      margin: 0 auto;
      flex: unset;
    }
    .MuiTabs-indicator {
      background-color: transparent;
    }
  }
`

const AntTab = styled(Tab)`
  text-transform: none;
  color: #5c5c5c;
  font-weight: 700;
  &.Mui-selected {
    color: #407797;
    font-weight: 600;
    background-color: #ecf1f5;
  }
  .MuiTab-root {
    border-bottom: 1px solid #5c5c5c;
    padding: 11px 24px;
  }
  &.is-view {
    &.Mui-selected {
      border-top: 2px solid #5c5c5c !important;
      border-left: 2px solid #5c5c5c !important;
      border-right: 2px solid #5c5c5c !important;
      border-bottom: 0px solid transparent;
    }
    &.MuiTab-root {
      border-top: 1px solid #d3d3d3;
      border-left: 1px solid #d3d3d3;
      border-right: 1px solid #d3d3d3;
      border-bottom: 0px solid transparent;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
`

const PlainTabs = ({
  location,
  children,
  tabList,
  isViewPageTab = false,
  noPadding = false,
  activeCallback,
}) => {
  console.log(tabList)
  const [active, setActive] = useState(tabList[0].value)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const filterParam = params.get('tab')
    console.log(filterParam)
    const findObj = tabList.find((e) => e.value === filterParam)
    setActive((findObj && findObj.value) || tabList[0].value)
  }, [location.search])

  useEffect(() => {
    if (activeCallback) {
      activeCallback(active)
    }
  }, [active])

  const handleChange = (_, value) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', value)
    window.history.pushState(
      {}, // State object (can be left empty)
      '', // Title (can be left empty)
      `${location.pathname}?${searchParams.toString()}`
    )
    setActive(value)
  }
  return (
    <>
      <AntTabs
        className={`${isViewPageTab ? 'isViewTab' : ''} ${
          noPadding ? 'no_padding' : ''
        }`}
        value={active}
        onChange={handleChange}
      >
        {tabList.map((tab) => (
          <AntTab
            className={isViewPageTab ? 'is-view' : ''}
            value={tab.value}
            label={tab.label}
          />
        ))}
      </AntTabs>
      {children && (
        <div style={{ backgroundColor: '#fff' }}>{children(active)}</div>
      )}
    </>
  )
}

export default withRouter(PlainTabs)
