import {
  Autocomplete,
  Box,
  Collapse,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import { setPageCount } from '../../../../reducers/common'
import ActiveFilters from '../../../shared/components/activeFilters'
import CompanyArchiveAlert from '../../../shared/components/companyArchiveAlert'
import { showToast } from '../../../shared/components/showNotification'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  DynamicSVG,
  HeaderItem,
} from '../../../shared/components/table'
import useDelete from '../../../shared/components/useDelete'
import useFetch from '../../../shared/components/userFetch'
import { UserGroupIcons } from '../../../shared/components/userGroupIcons'
import useUpdate from '../../../shared/components/useUpdate'
import RequiredDocuments from './requiredDocuments'
import ViewQR from './ViewQR'

const CompanyGroupsList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  baseData,
  pageCount,
  setPageCount,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
  } = useFetch('/api/v2/entity_management/groups', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { mutate: mutateDuplicate, isLoading: loadingDuplicate } = useUpdate()
  const { mutate, isLoading: isDeleting } = useDelete(
    '/api/v2/entity_management/groups'
  )
  const [duplicate, setDuplicate] = useState({})
  const [viewQR, setViewQR] = useState({})
  const [requiredDocument, setRequiredDocument] = useState({})
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteItem, setDeleteItem] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}
  const {
    admin_users = [],
    groups = [],
    group_types = [],
    permissions: {
      allow_view = true,
      allow_edit = true,
      allow_delete = true,
      allow_to_view_required_document,
      allow_duplicate = true,
    } = {},
  } = baseData || {}

  useEffect(() => {
    metadata.count &&
      setPageCount({
        cg: {
          comp_group: metadata.count || pageCount.cg.comp_group,
          group_arch: pageCount.cg.group_arch,
        },
      })
  }, [metadata.count])

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const groupsFilterData = [
    { label: 'All', value: 'All' },
    ...groups.map((e) => ({ ...e, label: e.key, id: e.value })),
  ]

  const adminFilterData = [
    { label: 'All', value: 'All' },
    ...admin_users.map((e) => ({ ...e, label: e.key, id: e.value })),
  ]

  const handleEdit = (row, setClose) => {
    setEdit(row.id)
    if (setClose) {
      setClose()
    }
  }

  const handleDelete = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleOk = (selectItem) => {
    const payload = {
      id: deleteItem.id,
      associations: {
        archive_audit_actions: selectItem.action,
        archive_group_audits: selectItem.form,
      },
    }
    mutate(
      payload,
      {
        onSuccess: () => {
          showToast({ message: 'Deleted Successfully' })
          callRefetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      }
      // `/api/v2/entity_management/groups/${deleteItem.id}/destroy`
    )
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }

  const handleSelectedRows = (id) => {
    if (!selectedRows.includes(id)) {
      setSelectedRows((prev) => [...prev, id])
    } else {
      const newValues = selectedRows.filter((e) => e !== id)
      setSelectedRows(newValues)
    }
  }

  const handleDuplicateService = (e) => {
    mutateDuplicate(
      {},
      {
        onSuccess: () => {
          setDuplicate({})
          setFilterState({ page: 1, limit: 20 })
          showToast({ message: 'Duplicated Successfully' })
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/entity_management/groups/${e.id}/duplicate`
    )
  }

  const handleDucplicate = (ele, setClose) => {
    setDuplicate(ele)
    setClose(false)
  }

  const handleRequiredDocument = (ele, setClose) => {
    setRequiredDocument(ele)
    setClose(false)
  }
  const groupTypeFilterOption = [
    { label: 'All', value: 'All' },
    ...group_types.map((e) => ({
      ...e,
      label: e.key,
      value: e.value.toString(),
    })),
  ]
  const headers = [
    <TableCell style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Group Name"
        isSort
        handleSort={() => handleSort('name')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              groupsFilterData.find((e) => e.value === filterData['id_eq']) ||
              'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'id_eq')}
            options={groupsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem title="Description" />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Admin"
        // isSort
        // handleSort={() => handleSort("admin_name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              adminFilterData.find(
                (e) => e.value === filterData['admin_id_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'admin_id_eq')}
            options={adminFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Type"
        isSort
        handleSort={() => handleSort('type')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              groupTypeFilterOption.find(
                (e) => e.value === filterData['type_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'type_eq')}
            options={groupTypeFilterOption}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="mw150">
      <HeaderItem title="Shared Forms" />
    </TableCell>,
    // <TableCell className="mw150">
    //     <HeaderItem title="Company Docs" />
    // </TableCell>,
    // <TableCell className="mw150">
    //     <HeaderItem title="QR Code" />
    // </TableCell>,
    <TableCell className="right mw100">
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const childrenRow = (childrenData = [], expandId) => (
    <TableRow
      sx={{ display: !selectedRows.includes(expandId) ? 'none' : 'table-row' }}
      style={{ minWidth: '275px' }}
      key={expandId}
    >
      <TableCell
        style={{
          paddingBottom: 10,
          paddingTop: 10,
          paddingRight: 10,
          paddingLeft: 10,
        }}
        colSpan={12}
      >
        <Collapse
          in={selectedRows.includes(expandId)}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ margin: 0, border: '1px solid #ddd' }}>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {childrenData.map((item, index) => (
                  <>
                    <TableRow
                      key={index}
                      className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                    >
                      <TableCell>
                        <div
                          className="flex g10"
                          style={{ alignItems: 'center' }}
                        >
                          {selectedRows.includes(item.id) ? (
                            <span
                              style={{
                                rotate: '90deg',
                                cursor: 'pointer',
                                visibility:
                                  item.children && item.children.length
                                    ? 'visible'
                                    : 'hidden',
                              }}
                              onClick={() => handleSelectedRows(item.id)}
                            >
                              {UserGroupIcons.ArrowRight}
                            </span>
                          ) : (
                            <span
                              style={{
                                cursor: 'pointer',
                                visibility:
                                  item.children && item.children.length
                                    ? 'visible'
                                    : 'hidden',
                              }}
                              onClick={() => handleSelectedRows(item.id)}
                            >
                              {UserGroupIcons.ArrowRight}
                            </span>
                          )}
                          <div className="flex flex-c">
                            <Link
                              className="link-text"
                              to={`/company_groups/view/${item.id}?tab=cg`}
                            >
                              <div
                                className="elips150 elips275"
                                title={item.name}
                              >
                                <div style={{ width: '35px', height: '35px' }}>
                                  <DynamicSVG
                                    text={item.name}
                                    imgUrl={
                                      item.logo_details.download_url || null
                                    }
                                  />
                                </div>
                                <span style={{ padding: '0 5px' }}>
                                  {item.name}
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{item.description || '-'}</TableCell>
                      <TableCell>{item.admin_name || '-'}</TableCell>
                      <TableCell>{item.type_name || '-'}</TableCell>
                      <TableCell className="mw150">
                        {item.shared_forms_count || '0'}
                      </TableCell>
                      {/* <TableCell className="mw150">
                                                <Button variant="text" style={{textDecoration: 'underline', textTransform: "none"}} onClick={() => setViewQR(item)}>View QR Code</Button>
                                            </TableCell> */}
                      <TableCell className="right mw100">
                        <ActionMenu>
                          {(setClose) => {
                            const menuItems = [
                              <MenuItem key={1}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setViewQR(item)}
                                >
                                  {UserGroupIcons.QRCode}{' '}
                                  <span
                                    style={{ paddingLeft: 5, color: '#080808' }}
                                  >
                                    View QR Code
                                  </span>
                                </Box>
                              </MenuItem>,
                            ]
                            if (allow_view) {
                              menuItems.push(
                                <MenuItem key={2}>
                                  <Link
                                    to={`/company_groups/view/${item.id}?tab=cg`}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      {UserGroupIcons.view}{' '}
                                      <span
                                        style={{
                                          paddingLeft: 5,
                                          color: '#080808',
                                        }}
                                      >
                                        View
                                      </span>
                                    </Box>
                                  </Link>
                                </MenuItem>
                              )
                            }
                            if (allow_edit) {
                              menuItems.push(
                                <MenuItem
                                  key={3}
                                  onClick={() => handleEdit(item, setClose)}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {UserGroupIcons.edit}{' '}
                                    <span
                                      style={{
                                        paddingLeft: 5,
                                        color: '#080808',
                                      }}
                                    >
                                      Edit
                                    </span>
                                  </Box>
                                </MenuItem>
                              )
                            }
                            if (allow_duplicate) {
                              menuItems.push(
                                <MenuItem
                                  key={4}
                                  onClick={() =>
                                    handleDucplicate(item, setClose)
                                  }
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {UserGroupIcons.duplicate}{' '}
                                    <span
                                      style={{
                                        paddingLeft: 5,
                                        color: '#080808',
                                      }}
                                    >
                                      Duplicate
                                    </span>
                                  </Box>
                                </MenuItem>
                              )
                            }
                            if (item.allow_to_view_required_document) {
                              menuItems.push(
                                <MenuItem
                                  key={5}
                                  onClick={() =>
                                    handleRequiredDocument(item, setClose)
                                  }
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {UserGroupIcons.requiredDocument}{' '}
                                    <span
                                      style={{
                                        paddingLeft: 5,
                                        color: '#080808',
                                      }}
                                    >
                                      Required Document
                                    </span>
                                  </Box>
                                </MenuItem>
                              )
                            }
                            if (allow_delete) {
                              menuItems.push(
                                <MenuItem
                                  key={6}
                                  onClick={() => handleDelete(item, setClose)}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {UserGroupIcons.archive}{' '}
                                    <span
                                      style={{
                                        paddingLeft: 5,
                                        color: '#080808',
                                      }}
                                    >
                                      Archive
                                    </span>
                                  </Box>
                                </MenuItem>
                              )
                            }
                            return menuItems
                          }}
                        </ActionMenu>
                      </TableCell>
                    </TableRow>
                    {item.children &&
                      item.children.length > 0 &&
                      childrenRow(item.children, item.id)}
                  </>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )

  const rows = data.map((ele, index) => {
    const {
      name = '',
      shared_forms_count = 0,
      company_docs_count = 0,
      description = '-',
      admin_name = '-',
      type_name = '-',
      logo_details = {},
    } = ele || {}
    return (
      <>
        <TableRow
          key={index}
          className={index % 2 === 0 ? 'even-row' : 'odd-row'}
        >
          <TableCell style={{ minWidth: '275px' }}>
            <div className="flex g10" style={{ alignItems: 'center' }}>
              {selectedRows.includes(ele.id) ? (
                <span
                  style={{
                    rotate: '90deg',
                    cursor: 'pointer',
                    visibility:
                      ele.children && ele.children.length
                        ? 'visible'
                        : 'hidden',
                  }}
                  onClick={() => handleSelectedRows(ele.id)}
                >
                  {UserGroupIcons.ArrowRight}
                </span>
              ) : (
                <span
                  style={{
                    cursor: 'pointer',
                    visibility:
                      ele.children && ele.children.length
                        ? 'visible'
                        : 'hidden',
                  }}
                  onClick={() => handleSelectedRows(ele.id)}
                >
                  {UserGroupIcons.ArrowRight}
                </span>
              )}
              <div className="flex flex-c">
                <Link
                  className="link-text"
                  to={`/company_groups/view/${ele.id}?tab=cg`}
                >
                  <div className="elips150 elips275" title={name}>
                    <div style={{ width: '35px', height: '35px' }}>
                      <DynamicSVG
                        text={name}
                        imgUrl={logo_details.download_url}
                      />
                    </div>
                    <span style={{ padding: '0 5px' }}>{name}</span>
                  </div>
                </Link>
              </div>{' '}
              {ele.children.length > 0 && (
                <span
                  style={{
                    padding: '0 8px',
                    height: '20px',
                    background: '#ECECEC',
                    borderRadius: '16px',
                  }}
                >
                  {ele.children.length}
                </span>
              )}
            </div>
          </TableCell>
          <TableCell>{description}</TableCell>
          <TableCell>{admin_name}</TableCell>
          <TableCell>{type_name}</TableCell>
          <TableCell className="mw150">{shared_forms_count}</TableCell>
          {/* <TableCell className="mw150">
                        <Button variant="text" style={{textDecoration: 'underline', textTransform: "none"}} onClick={() => setViewQR(ele)}>View QR Code</Button>
                    </TableCell> */}
          <TableCell className="right mw100">
            <ActionMenu>
              {(setClose) => {
                const menuItems = [
                  <MenuItem>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                      onClick={() => setViewQR(ele)}
                    >
                      {UserGroupIcons.QRCode}{' '}
                      <span style={{ paddingLeft: 5, color: '#080808' }}>
                        View QR Code
                      </span>
                    </Box>
                  </MenuItem>,
                ]
                if (allow_view) {
                  menuItems.push(
                    <MenuItem>
                      <Link to={`/company_groups/view/${ele.id}?tab=cg`}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {UserGroupIcons.view}{' '}
                          <span style={{ paddingLeft: 5, color: '#080808' }}>
                            View
                          </span>
                        </Box>
                      </Link>
                    </MenuItem>
                  )
                }
                if (allow_edit) {
                  menuItems.push(
                    <MenuItem onClick={() => handleEdit(ele, setClose)}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.edit}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          Edit
                        </span>
                      </Box>
                    </MenuItem>
                  )
                }
                if (allow_duplicate) {
                  menuItems.push(
                    <MenuItem onClick={() => handleDucplicate(ele, setClose)}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.duplicate}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          Duplicate
                        </span>
                      </Box>
                    </MenuItem>
                  )
                }
                if (ele.allow_to_view_required_document) {
                  menuItems.push(
                    <MenuItem
                      onClick={() => handleRequiredDocument(ele, setClose)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.requiredDocument}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          Required Document
                        </span>
                      </Box>
                    </MenuItem>
                  )
                }

                if (allow_delete) {
                  menuItems.push(
                    <MenuItem onClick={() => handleDelete(ele, setClose)}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {UserGroupIcons.archive}{' '}
                        <span style={{ paddingLeft: 5, color: '#080808' }}>
                          Archive
                        </span>
                      </Box>
                    </MenuItem>
                  )
                }
                return menuItems
              }}
            </ActionMenu>
          </TableCell>
        </TableRow>
        {ele.children &&
          ele.children.length > 0 &&
          childrenRow(ele.children, ele.id)}
      </>
    )
  })

  return (
    <>
      {alertOpen && (
        <CompanyArchiveAlert
          alertOpen={alertOpen}
          handleClose={() => setAlertOpen(false)}
          handleOk={handleOk}
          title="Archive Company Group"
          description="Are You sure you want to delete this company group?"
          okText="Yes, Archive"
          data={deleteItem}
        />
      )}
      <CustomTable
        headers={headers}
        rows={rows}
        renderActiveFilter={
          <>
            <ActiveFilters
              filterData={filterState}
              update={setFilterState}
              keyName="cg"
            />
          </>
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }))
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }))
          }}
        />
      )}
      {!!duplicate.id && (
        <AlertDialog
          icon={<></>}
          loading={loadingDuplicate}
          alertOpen={!!duplicate.id}
          title="Confirm !"
          description={`Are you want to duplicate ${duplicate.name}`}
          handleClose={() => setDuplicate({})}
          handleOk={() => handleDuplicateService(duplicate)}
          okText="Yes, Duplicate"
          okButtonStyle={{ background: '#407797' }}
        />
      )}
      {!!requiredDocument.id && (
        <RequiredDocuments
          open={!!requiredDocument.id}
          data={requiredDocument}
          handleClose={() => setRequiredDocument({})}
        />
      )}
      {!!viewQR.id && (
        <ViewQR
          onClose={() => setViewQR({})}
          open={!!viewQR.id}
          recordId={viewQR.id}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    pageCount: state.CommonReducer.pageCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyGroupsList)
