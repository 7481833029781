import {
  Autocomplete,
  Box,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import SearchIcon from '../../../../assets/fonts/Search.svg'
import ActiveFilters from '../../../shared/components/activeFilters'
import InputSearchFilter from '../../../shared/components/InputSearchFilter'
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
  TagRender,
} from '../../../shared/components/table'
import useFetch from '../../../shared/components/userFetch'
// import BulkAssignJobProfile from "./bulkAssignJobProfile";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setActionPageCount } from '../../../../reducers/common'
import AlertDialog from '../../../shared/components/alretDialog'
import Loader from '../../../shared/components/loader'
import { showToast } from '../../../shared/components/showNotification'
import usePatch from '../../../shared/components/usePatch'
import { actionIcons } from '../../ActionIcons'

const TableWrapper = styled('div')`
  .action_name,
  .action_priority_name,
  .action_sub_category,
  .action_notices_status,
  .action_action_type,
  .action_group,
  .action_created_by,
  .action_created_at,
  .action_create_on,
`

const ActionArchieveList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  actionsCount,
  setActionPageCount,
  setView,
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetch('/api/v2/actions_management/actions/archived', {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  })
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    '/api/v2/actions_management/actions/base_data?activated_eq=false'
  )
  const { mutate, isLoading: isRestoring } = usePatch()
  const [deleteItem, setDeleteItem] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { data = [], metadata = {} } = responseData || {}
  const { permissions: { allow_restore = true } = {} } = baseData || {}

  useEffect(() => {
    metadata.count &&
      setActionPageCount({
        action_notices: {
          actions_list: actionsCount.action_notices.actions_list,
          actions_archive:
            metadata.count || actionsCount.action_notices.actions_archive,
        },
      })
  }, [metadata.count])

  const {
    action_types = [],
    priorities = [],
    sub_categories = [],
    statuses = [],
    groups = [],
    company_users = [],
  } = baseData || {}

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    })
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState])

  const createFilterData = (data) => [
    { label: 'All', value: 'All' },
    ...data.map((e) => ({ ...e, label: e.key, value: e.value })),
  ]
  const groupsFilterData = createFilterData(groups)
  const subCategoryFilterData = createFilterData(sub_categories)
  const statusFilterData = createFilterData(statuses)
  const actionTypeFilterData = createFilterData(action_types)
  const assigneesFilterData = createFilterData(company_users)

  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose })
    setAlertOpen(true)
  }

  const handleOk = () => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: 'Restored Successfully' })
          callRefetch()
        },
        onError: (e) => {
          showToast({
            type: 'error',
            message: typeof e === 'string' ? e : e.errors.toString() || '',
          })
        },
      },
      `/api/v2/actions_management/actions/${deleteItem.id}/restore`
    )
    if (alertOpen) {
      setDeleteItem(null)
      setAlertOpen(false)
    }
  }

  const headers = [
    <TableCell>
      <HeaderItem
        title="Action Name"
        filter={
          <InputSearchFilter
            name="label_cont"
            filterData={filterData}
            handleOnFilterChange={handleOnFilterChange}
            filterState={filterState}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Priority"
        isSort
        handleSort={() => handleSort('priority')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              priorities.find((e) => e.value === filterData['priority_eq']) ||
              'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || 'All', 'priority_eq')
            }
            options={[
              { label: 'All', value: 'All' },
              ...priorities.map((e) => ({
                ...e,
                label: e.key || '',
                id: e.value,
              })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Sub Category"
        isSort
        handleSort={() => handleSort('sub_category_type_label')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              subCategoryFilterData.find(
                (e) => e.value === filterData['sub_category_type_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'sub_category_type_id_eq')
            }
            options={subCategoryFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Status"
        isSort
        handleSort={() => handleSort('status')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={
              statusFilterData.find(
                (e) => e.value === filterData['status_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'status_eq')}
            options={statusFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Action Types"
        isSort
        handleSort={() => handleSort('action_type_id')}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              actionTypeFilterData.find(
                (e) => e.value === filterData['action_type_id_eq']
              ) || 'All'
            }
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'action_type_id_eq')
            }
            options={actionTypeFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Assignees"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              assigneesFilterData.find(
                (e) => e.value === filterData['audit_action_users_user_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'audit_action_users_user_id_eq')
            }
            options={assigneesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Groups / Project Sites"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              groupsFilterData.find(
                (e) => e.value === filterData['group_id_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) => handleOnFilterChange(v || 'All', 'group_id_eq')}
            options={groupsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Created by"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              assigneesFilterData.find(
                (e) => e.value === filterData['created_by_eq']
              ) || 'All'
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || 'All', 'created_by_eq')
            }
            options={assigneesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: '100%', background: '#fff', border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Created On"
        isSort
        handleSort={() => handleSort('created_on')}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Due"
        isSort
        handleSort={() => handleSort('archived_eq')}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ]

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          label,
          priority,
          status_label,
          sub_category_type,
          action_type_name,
          status,
          action_type,
          group_name,
          created_by,
          created_at,
          assigned_user_names = [],
          due_date = '-',
          updated_at,
        } = ele || {}
        return (
          <TableRow key={index}>
            <TableCell>
              <div className="flex" style={{ alignItems: 'center' }}>
                <span>{ele.is_action_overdue && actionIcons.forced}</span>
                <div className="flex flex-c">
                  <Link to={`/actions-notices/view/${ele.id}`}>
                    <div
                      style={{
                        color: '#080808',
                        fontWeight: '700',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {label}
                    </div>
                  </Link>
                  {action_type_name && (
                    <div
                      className="desc elips150"
                      style={{
                        color: '#909090',
                        textDecoration: 'none',
                        fontSize: '12px',
                        fontWeight: '400',
                      }}
                      title={action_type_name}
                    >
                      {action_type_name || ''}
                    </div>
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <span className={`action_priority ${priority}`}>
                {actionIcons[priority]} {priority}
              </span>
            </TableCell>
            <TableCell>{sub_category_type}</TableCell>
            <TableCell>
              <span className={`action_status ${status}`}>{status_label}</span>
            </TableCell>
            <TableCell style={{ minWidth: '275px' }}>{action_type}</TableCell>
            <TableCell>
              <TagRender data={assigned_user_names} />
            </TableCell>
            <TableCell style={{ minWidth: '275px' }}>{group_name}</TableCell>
            <TableCell>{created_by}</TableCell>
            <TableCell>{created_at}</TableCell>
            <TableCell>{due_date}</TableCell>
            <TableCell className="right" style={{ minWidth: '100px' }}>
              <ActionMenu>
                {(setClose) => {
                  const menuItems = []
                  if (allow_restore) {
                    menuItems.push(
                      <MenuItem onClick={() => handleRestore(ele, setClose)}>
                        Restore
                      </MenuItem>
                    )
                  }
                  return menuItems
                }}
              </ActionMenu>
            </TableCell>
          </TableRow>
        )
      }),
    [data]
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center',
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Typography variant="h6">List View</Typography>
        <Stack direction="row" alignItems="center">
          <div style={{ cursor: 'not-allowed' }}>
            {actionIcons.listViewSelected}
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => setView('board')}>
            {actionIcons.boardView}
          </div>
        </Stack>
      </Box>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Restore Action"
        description="Are You sure you want to restore this action?"
        okText="Restore"
      />
      <TableWrapper>
        <CustomTable
          headers={headers}
          rows={rows}
          renderActiveFilter={
            <>
              <ActiveFilters
                filterData={filterState}
                update={setFilterState}
                keyName="action_notices"
              />
            </>
          }
        />
        {!!metadata.count && (
          <CustomPagination
            totalPage={metadata.last}
            lastPage={metadata.last}
            currentPage={filterState.page}
            handlePageChange={(_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }}
            rowOptions={[20, 50, 100]}
            rowPerPage={filterState.limit}
            handleRowPerPageChange={(targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }}
          />
        )}
      </TableWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setActionPageCount: (value) => dispatch(setActionPageCount(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionArchieveList)
