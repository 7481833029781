//AssetActionsWrapper
import React, { useState } from 'react'
import { connect } from 'react-redux'
import ExportCSV from '../../../shared/components/exportCsv'
import SubTabs from '../../../shared/components/subTabs'
import { ButtonCreate } from '../../../shared/components/table'
import useFilterChange from '../../../shared/components/useFilterChange'
import useFetch from '../../../shared/components/userFetch'
import AssetActionList from './AssetActionList'
import AssetArchieveList from './AssetArchieveList'
import CreateAssetAction from './CreateAssetAction'
import EditAssetAction from './EditAssetAction'

const subTabs = {
  asset_actions: [
    {
      label: 'Asset Action List',
      value: 'asset_list',
    },
    {
      label: 'Archived',
      value: 'asset_archive',
    },
  ],
}

const AssetActionsWrapper = ({ title, active, actionsCount = {} }) => {
  const initialState = {
    page: 1,
    limit: 20,
  }
  const {
    data: baseData,
    isLoading: baseDataLoading,
    refetch: refetchBaseData,
  } = useFetch('/api/v2/actions_management/actions/base_data')

  const { permissions: { allow_create, allow_to_export_actions } = {} } =
    baseData || {}

  const [create, setCreate] = useState(false)
  const [edit, setEdit] = useState('')
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState)
  const {
    handleOnFilterChange: handleOnFilterChangeArchive,
    filterState: filterStateArchive,
    filterData: filterDataArchive,
    setFilterState: setFilterStateArchive,
    handleSort: handleSortArchive,
  } = useFilterChange(initialState)
  const refetch = () => {
    setFilterState({ ...filterState })
  }

  return (
    <>
      <SubTabs
        tabList={subTabs[active] || []}
        withTitle
        title={title}
        pageCount={actionsCount.asset_actions}
        extras={[
          <div style={{ display: 'flex', gap: '10px' }}>
            {allow_to_export_actions && (
              <ExportCSV
                url={'/api/v2/actions_management/asset_actions'}
                fileName="asset-actions"
                params={{
                  ...filterData,
                }}
              />
            )}
            {allow_create && (
              <ButtonCreate label="Create" onClick={() => setCreate(true)} />
            )}
          </div>,
        ]}
      >
        {(activeKey) => (
          <>
            {activeKey === 'asset_list' && (
              <>
                <AssetActionList
                  handleOnFilterChange={handleOnFilterChange}
                  filterData={filterData}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  handleSort={handleSort}
                  initialState={initialState}
                  actionsCount={actionsCount}
                  setEdit={setEdit}
                  baseData={baseData}
                />
              </>
            )}
            {activeKey === 'asset_archive' && (
              <>
                <AssetArchieveList
                  handleOnFilterChange={handleOnFilterChangeArchive}
                  filterData={filterDataArchive}
                  filterState={filterStateArchive}
                  setFilterState={setFilterStateArchive}
                  handleSort={handleSortArchive}
                  initialState={initialState}
                  actionsCount={actionsCount}
                />
              </>
            )}
          </>
        )}
      </SubTabs>
      {create && (
        <CreateAssetAction
          open={create}
          setOpen={setCreate}
          setFilterState={setFilterState}
          refetch={refetchBaseData}
        />
      )}
      {!!edit && (
        <EditAssetAction edit={edit} setEdit={setEdit} refetch={refetch} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}

export default connect(mapStateToProps)(AssetActionsWrapper)
