import React from 'react'
import { connect } from 'react-redux'
import SubTabs from '../../../shared/components/subTabs'
import ActionsTypeList from './ActionsTypeList'
import SubCategoryTypesList from './SubCategoryTypesList'

const subTabs = {
  config: [
    {
      label: 'Actions Type',
      value: 'actions_type',
    },
    {
      label: 'Sub Category Types',
      value: 'sub_category_types',
    },
  ],
}

const ConfigWrapper = ({ active, title, actionsCount = {} }) => {
  return (
    <SubTabs
      tabList={subTabs[active] || []}
      withTitle
      title={title}
      pageCount={actionsCount.config}
    >
      {(activeKey) => (
        <>
          {activeKey === 'actions_type' && (
            <ActionsTypeList actionsCount={actionsCount} />
          )}
          {activeKey === 'sub_category_types' && (
            <SubCategoryTypesList actionsCount={actionsCount} />
          )}
        </>
      )}
    </SubTabs>
  )
}
const mapStateToProps = (state) => {
  return {
    actionsCount: state.CommonReducer.actionsCount,
  }
}

export default connect(mapStateToProps)(ConfigWrapper)
