import React from 'react'

export const actionIcons = {
  forced: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_10559_12754)">
        <path
          d="M9 6.75V8.25M9 11.25V11.2575"
          stroke="#FF3030"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.75039 14.2504H14.2504C14.4951 14.2487 14.7357 14.1871 14.9512 14.071C15.1666 13.955 15.3504 13.788 15.4865 13.5846C15.6226 13.3812 15.7069 13.1475 15.732 12.9041C15.757 12.6606 15.7222 12.4148 15.6304 12.1879L10.3054 3.00038C10.1757 2.76593 9.98552 2.5705 9.7547 2.43442C9.52389 2.29833 9.26083 2.22656 8.99289 2.22656C8.72494 2.22656 8.46189 2.29833 8.23107 2.43442C8.00026 2.5705 7.8101 2.76593 7.68039 3.00038L2.35539 12.1879C2.26534 12.4096 2.22964 12.6496 2.25125 12.8879C2.27286 13.1262 2.35117 13.3558 2.47963 13.5577C2.60808 13.7595 2.78297 13.9278 2.98968 14.0483C3.19639 14.1688 3.42893 14.2381 3.66789 14.2504"
          stroke="#FF3030"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12754">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  action: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_10542_26399)">
        <path
          d="M12 4C12 4.26522 12.1054 4.51957 12.2929 4.70711C12.4804 4.89464 12.7348 5 13 5C13.2652 5 13.5196 4.89464 13.7071 4.70711C13.8946 4.51957 14 4.26522 14 4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3C12.7348 3 12.4804 3.10536 12.2929 3.29289C12.1054 3.48043 12 3.73478 12 4Z"
          stroke="#5C5C5C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 17L9 18L9.75 16.5"
          stroke="#5C5C5C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 21V17L11 14L12 8"
          stroke="#5C5C5C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 12V9L12 8L15 11L18 12"
          stroke="#5C5C5C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10542_26399">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  high: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_10559_12352)">
        <path
          d="M10.4142 20.8807L3.11927 13.5858C2.29358 12.7601 2.29358 11.2399 3.11927 10.4142L10.4142 3.11927C11.2399 2.29358 12.7601 2.29358 13.5858 3.11927L20.8807 10.4142C21.7064 11.2399 21.7064 12.7601 20.8807 13.5858L13.5858 20.8807C12.7601 21.7064 11.2399 21.7064 10.4142 20.8807Z"
          fill="#FF3030"
        />
        <path
          d="M7.84863 13.5C8.67706 13.5 9.34863 12.8284 9.34863 12C9.34863 11.1716 8.67706 10.5 7.84863 10.5C7.02021 10.5 6.34863 11.1716 6.34863 12C6.34863 12.8284 7.02021 13.5 7.84863 13.5Z"
          fill="white"
        />
        <path
          d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
          fill="white"
        />
        <path
          d="M16.1514 13.5C16.9798 13.5 17.6514 12.8284 17.6514 12C17.6514 11.1716 16.9798 10.5 16.1514 10.5C15.3229 10.5 14.6514 11.1716 14.6514 12C14.6514 12.8284 15.3229 13.5 16.1514 13.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12352">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  low: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_10559_12356)">
        <path
          d="M10.4142 20.8807L3.11927 13.5858C2.29358 12.7601 2.29358 11.2399 3.11927 10.4142L10.4142 3.11927C11.2399 2.29358 12.7601 2.29358 13.5858 3.11927L20.8807 10.4142C21.7064 11.2399 21.7064 12.7601 20.8807 13.5858L13.5858 20.8807C12.7601 21.7064 11.2399 21.7064 10.4142 20.8807Z"
          fill="#FDCD40"
        />
        <path
          d="M15.9166 12L8.08301 12"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12356">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  medium: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10559_12368)">
        <path
          d="M10.4142 20.8807L3.11927 13.5858C2.29358 12.7601 2.29358 11.2399 3.11927 10.4142L10.4142 3.11927C11.2399 2.29358 12.7601 2.29358 13.5858 3.11927L20.8807 10.4142C21.7064 11.2399 21.7064 12.7601 20.8807 13.5858L13.5858 20.8807C12.7601 21.7064 11.2399 21.7064 10.4142 20.8807Z"
          fill="#FF9300"
        />
        <path
          d="M9.9248 13.5C10.7532 13.5 11.4248 12.8284 11.4248 12C11.4248 11.1716 10.7532 10.5 9.9248 10.5C9.09638 10.5 8.4248 11.1716 8.4248 12C8.4248 12.8284 9.09638 13.5 9.9248 13.5Z"
          fill="white"
        />
        <path
          d="M14.0762 13.5C14.9046 13.5 15.5762 12.8284 15.5762 12C15.5762 11.1716 14.9046 10.5 14.0762 10.5C13.2477 10.5 12.5762 11.1716 12.5762 12C12.5762 12.8284 13.2477 13.5 14.0762 13.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12368">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  alert: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_10559_12748)">
        <path
          d="M9 6.75V8.25M9 11.25V11.2575"
          stroke="#FF3030"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.75039 14.2504H14.2504C14.4951 14.2487 14.7357 14.1871 14.9512 14.071C15.1666 13.955 15.3504 13.788 15.4865 13.5846C15.6226 13.3812 15.7069 13.1475 15.732 12.9041C15.757 12.6606 15.7222 12.4148 15.6304 12.1879L10.3054 3.00038C10.1757 2.76593 9.98552 2.5705 9.7547 2.43442C9.52389 2.29833 9.26083 2.22656 8.99289 2.22656C8.72494 2.22656 8.46189 2.29833 8.23107 2.43442C8.00026 2.5705 7.8101 2.76593 7.68039 3.00038L2.35539 12.1879C2.26534 12.4096 2.22964 12.6496 2.25125 12.8879C2.27286 13.1262 2.35117 13.3558 2.47963 13.5577C2.60808 13.7595 2.78297 13.9278 2.98968 14.0483C3.19639 14.1688 3.42893 14.2381 3.66789 14.2504"
          stroke="#FF3030"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12748">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  arrowDown: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <g clip-path="url(#clip0_10450_39826)">
        <path
          d="M5.08203 6.75L9.58203 11.25L14.082 6.75"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10450_39826">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.582031)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  arrowUp: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M14.832 11.625L9.58203 6.375L4.33203 11.625"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  download: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M4 17.6953V19.6953C4 20.2257 4.21071 20.7345 4.58579 21.1095C4.96086 21.4846 5.46957 21.6953 6 21.6953H18C18.5304 21.6953 19.0391 21.4846 19.4142 21.1095C19.7893 20.7345 20 20.2257 20 19.6953V17.6953"
        stroke="#407797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 11.6953L12 16.6953L17 11.6953"
        stroke="#407797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 4.69531V16.6953"
        stroke="#407797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  edit: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10559_17747)">
        <path
          d="M7.49967 5.83301H4.99967C4.55765 5.83301 4.13372 6.0086 3.82116 6.32116C3.5086 6.63372 3.33301 7.05765 3.33301 7.49967V14.9997C3.33301 15.4417 3.5086 15.8656 3.82116 16.1782C4.13372 16.4907 4.55765 16.6663 4.99967 16.6663H12.4997C12.9417 16.6663 13.3656 16.4907 13.6782 16.1782C13.9907 15.8656 14.1663 15.4417 14.1663 14.9997V12.4997"
          stroke="#5C5C5C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 12.4995H10L17.0833 5.4162C17.4149 5.08468 17.6011 4.63505 17.6011 4.1662C17.6011 3.69736 17.4149 3.24773 17.0833 2.9162C16.7518 2.58468 16.3022 2.39844 15.8333 2.39844C15.3645 2.39844 14.9149 2.58468 14.5833 2.9162L7.5 9.99954V12.4995Z"
          stroke="#5C5C5C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.333 4.16699L15.833 6.66699"
          stroke="#5C5C5C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_17747">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  pdf: (
    <svg
      width="44"
      height="56"
      viewBox="0 0 44 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.985 0H1.963C1.155 0 0.5 0.655 0.5 1.926V55C0.5 55.345 1.155 56 1.963 56H42.037C42.845 56 43.5 55.345 43.5 55V12.978C43.5 12.282 43.407 12.058 43.243 11.893L31.607 0.257C31.442 0.093 31.218 0 30.985 0Z"
        fill="#E9E9E0"
      />
      <path d="M31.5 0.151367V12.0004H43.349L31.5 0.151367Z" fill="#D9D7CA" />
      <path
        d="M13.514 33.3242C13.166 33.3242 12.832 33.2112 12.547 32.9982C11.506 32.2172 11.366 31.3482 11.432 30.7562C11.614 29.1282 13.627 27.4242 17.417 25.6882C18.921 22.3922 20.352 18.3312 21.205 14.9382C20.207 12.7662 19.237 9.9482 19.944 8.2952C20.192 7.7162 20.501 7.2722 21.078 7.0802C21.306 7.0042 21.882 6.9082 22.094 6.9082C22.598 6.9082 23.041 7.5572 23.355 7.9572C23.65 8.3332 24.319 9.1302 22.982 14.7592C24.33 17.5432 26.24 20.3792 28.07 22.3212C29.381 22.0842 30.509 21.9632 31.428 21.9632C32.994 21.9632 33.943 22.3282 34.33 23.0802C34.65 23.7022 34.519 24.4292 33.94 25.2402C33.383 26.0192 32.615 26.4312 31.72 26.4312C30.504 26.4312 29.088 25.6632 27.509 24.1462C24.672 24.7392 21.359 25.7972 18.681 26.9682C17.845 28.7422 17.044 30.1712 16.298 31.2192C15.273 32.6542 14.389 33.3242 13.514 33.3242ZM16.176 28.1982C14.039 29.3992 13.168 30.3862 13.105 30.9422C13.095 31.0342 13.068 31.2762 13.536 31.6342C13.685 31.5872 14.555 31.1902 16.176 28.1982ZM29.813 23.7562C30.628 24.3832 30.827 24.7002 31.36 24.7002C31.594 24.7002 32.261 24.6902 32.57 24.2592C32.719 24.0502 32.777 23.9162 32.8 23.8442C32.677 23.7792 32.514 23.6472 31.625 23.6472C31.12 23.6482 30.485 23.6702 29.813 23.7562ZM22.343 17.1742C21.628 19.6482 20.684 22.3192 19.669 24.7382C21.759 23.9272 24.031 23.2192 26.165 22.7182C24.815 21.1502 23.466 19.1922 22.343 17.1742ZM21.736 8.7122C21.638 8.7452 20.406 10.4692 21.832 11.9282C22.781 9.8132 21.779 8.6982 21.736 8.7122Z"
        fill="#CC4B4C"
      />
      <path
        d="M42.037 56H1.963C1.155 56 0.5 55.345 0.5 54.537V39H43.5V54.537C43.5 55.345 42.845 56 42.037 56Z"
        fill="#CC4B4C"
      />
      <path
        d="M11.3849 52.9998H9.7439V42.9238H12.6419C13.0699 42.9238 13.4939 42.9918 13.9129 43.1288C14.3319 43.2658 14.7079 43.4708 15.0409 43.7438C15.3739 44.0168 15.6429 44.3478 15.8479 44.7348C16.0529 45.1218 16.1559 45.5568 16.1559 46.0408C16.1559 46.5518 16.0689 47.0138 15.8959 47.4288C15.7229 47.8438 15.4809 48.1928 15.1709 48.4748C14.8609 48.7568 14.4869 48.9758 14.0499 49.1308C13.6129 49.2858 13.1289 49.3628 12.6009 49.3628H11.3839L11.3849 52.9998ZM11.3849 44.1678V48.1598H12.8889C13.0889 48.1598 13.2869 48.1258 13.4839 48.0568C13.6799 47.9888 13.8599 47.8768 14.0239 47.7218C14.1879 47.5668 14.3199 47.3508 14.4199 47.0728C14.5199 46.7948 14.5699 46.4508 14.5699 46.0408C14.5699 45.8768 14.5469 45.6868 14.5019 45.4738C14.4559 45.2598 14.3629 45.0548 14.2219 44.8588C14.0799 44.6628 13.8819 44.4988 13.6269 44.3668C13.3719 44.2348 13.0339 44.1688 12.6149 44.1688L11.3849 44.1678Z"
        fill="white"
      />
      <path
        d="M26.219 47.6818C26.219 48.5108 26.13 49.2198 25.952 49.8078C25.774 50.3958 25.549 50.8878 25.275 51.2848C25.001 51.6818 24.694 51.9938 24.352 52.2218C24.01 52.4498 23.68 52.6198 23.361 52.7348C23.042 52.8488 22.75 52.9218 22.486 52.9538C22.222 52.9838 22.026 52.9998 21.898 52.9998H18.084V42.9238H21.119C21.967 42.9238 22.712 43.0588 23.354 43.3268C23.996 43.5948 24.53 43.9538 24.954 44.3998C25.378 44.8458 25.694 45.3548 25.904 45.9238C26.114 46.4938 26.219 47.0798 26.219 47.6818ZM21.352 51.7968C22.464 51.7968 23.266 51.4418 23.758 50.7308C24.25 50.0198 24.496 48.9898 24.496 47.6408C24.496 47.2218 24.446 46.8068 24.346 46.3968C24.245 45.9868 24.052 45.6158 23.765 45.2828C23.478 44.9498 23.088 44.6808 22.596 44.4758C22.104 44.2708 21.466 44.1678 20.682 44.1678H19.725V51.7968H21.352Z"
        fill="white"
      />
      <path
        d="M30.2659 44.1678V47.3398H34.4769V48.4608H30.2659V52.9998H28.5979V42.9238H34.8999V44.1678H30.2659Z"
        fill="white"
      />
    </svg>
  ),
  png: (
    <svg
      width="44"
      height="56"
      viewBox="0 0 44 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.985 0H1.963C1.155 0 0.5 0.655 0.5 1.926V55C0.5 55.345 1.155 56 1.963 56H42.037C42.845 56 43.5 55.345 43.5 55V12.978C43.5 12.282 43.407 12.058 43.243 11.893L31.607 0.257C31.442 0.093 31.218 0 30.985 0Z"
        fill="#E9E9E0"
      />
      <path d="M31.5 0.151367V12.0004H43.349L31.5 0.151367Z" fill="#D9D7CA" />
      <path
        d="M12.9311 19.0003C15.4544 19.0003 17.5001 16.9547 17.5001 14.4313C17.5001 11.9079 15.4544 9.8623 12.9311 9.8623C10.4077 9.8623 8.36206 11.9079 8.36206 14.4313C8.36206 16.9547 10.4077 19.0003 12.9311 19.0003Z"
        fill="#F3D55B"
      />
      <path
        d="M0.5 39H11.5H43.5V28L33.5 18.5L23 30L17.517 24.517L0.5 39Z"
        fill="#26B99A"
      />
      <path
        d="M42.037 56H1.963C1.155 56 0.5 55.345 0.5 54.537V39H43.5V54.537C43.5 55.345 42.845 56 42.037 56Z"
        fill="#14A085"
      />
      <path
        d="M15.426 42.6504V50.4984C15.426 50.9724 15.339 51.3714 15.166 51.6944C14.993 52.0174 14.76 52.2774 14.469 52.4734C14.177 52.6694 13.842 52.8064 13.464 52.8834C13.085 52.9614 12.696 53.0004 12.295 53.0004C12.094 53.0004 11.859 52.9794 11.591 52.9384C11.322 52.8974 11.044 52.8344 10.757 52.7474C10.47 52.6604 10.194 52.5624 9.93001 52.4534C9.66501 52.3444 9.44201 52.2214 9.26001 52.0844L9.95701 50.9774C10.048 51.0404 10.178 51.1074 10.347 51.1754C10.515 51.2434 10.7 51.3074 10.901 51.3664C11.101 51.4264 11.311 51.4774 11.53 51.5234C11.749 51.5694 11.954 51.5914 12.145 51.5914C12.628 51.5914 13.013 51.4974 13.3 51.3114C13.587 51.1254 13.739 50.8074 13.758 50.3614V42.6504H15.426Z"
        fill="white"
      />
      <path
        d="M19.514 52.9325H17.873V42.8555H20.771C21.199 42.8555 21.623 42.9235 22.042 43.0605C22.461 43.1975 22.837 43.4025 23.17 43.6755C23.503 43.9485 23.772 44.2795 23.977 44.6665C24.182 45.0535 24.285 45.4885 24.285 45.9725C24.285 46.4835 24.198 46.9455 24.025 47.3605C23.852 47.7755 23.61 48.1245 23.3 48.4065C22.99 48.6885 22.616 48.9075 22.179 49.0625C21.742 49.2175 21.258 49.2945 20.73 49.2945H19.513L19.514 52.9325ZM19.514 44.1005V48.0925H21.018C21.218 48.0925 21.416 48.0585 21.613 47.9895C21.809 47.9215 21.989 47.8095 22.153 47.6545C22.317 47.4995 22.449 47.2835 22.549 47.0055C22.649 46.7275 22.699 46.3835 22.699 45.9735C22.699 45.8095 22.676 45.6195 22.631 45.4065C22.585 45.1925 22.492 44.9875 22.351 44.7915C22.209 44.5955 22.011 44.4315 21.756 44.2995C21.5 44.1665 21.163 44.1005 20.744 44.1005H19.514Z"
        fill="white"
      />
      <path
        d="M33.5 47.7364V51.6324C33.29 51.8974 33.056 52.1124 32.796 52.2814C32.536 52.4504 32.263 52.5894 31.976 52.6984C31.689 52.8074 31.393 52.8854 31.087 52.9304C30.781 52.9784 30.479 53.0004 30.178 53.0004C29.576 53.0004 29.023 52.8914 28.517 52.6724C28.011 52.4534 27.569 52.1304 27.191 51.7014C26.813 51.2724 26.516 50.7354 26.302 50.0884C26.088 49.4414 25.981 48.6934 25.981 47.8464C25.981 46.9994 26.088 46.2534 26.302 45.6114C26.516 44.9684 26.812 44.4334 27.191 44.0054C27.569 43.5764 28.013 43.2514 28.524 43.0274C29.034 42.8034 29.586 42.6924 30.178 42.6924C30.725 42.6924 31.235 42.7834 31.709 42.9654C32.183 43.1484 32.606 43.4214 32.98 43.7854L31.845 44.7974C31.626 44.5324 31.375 44.3414 31.093 44.2234C30.81 44.1054 30.519 44.0454 30.218 44.0454C29.881 44.0454 29.559 44.1084 29.254 44.2364C28.948 44.3644 28.675 44.5804 28.434 44.8854C28.192 45.1914 28.003 45.5844 27.867 46.0684C27.731 46.5524 27.657 47.1434 27.648 47.8454C27.657 48.5294 27.728 49.1214 27.86 49.6224C27.992 50.1234 28.174 50.5334 28.407 50.8524C28.64 51.1714 28.904 51.4084 29.2 51.5634C29.496 51.7184 29.808 51.7954 30.137 51.7954C30.237 51.7954 30.371 51.7884 30.54 51.7744C30.708 51.7604 30.877 51.7384 31.046 51.7064C31.214 51.6744 31.376 51.6314 31.531 51.5764C31.686 51.5214 31.8 51.4444 31.873 51.3444V48.8564H30.164V47.7354L33.5 47.7364Z"
        fill="white"
      />
    </svg>
  ),
  doc: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M36.985 0H7.963C7.155 0 6.5 0.655 6.5 1.926V55C6.5 55.345 7.155 56 7.963 56H48.037C48.845 56 49.5 55.345 49.5 55V12.978C49.5 12.282 49.407 12.058 49.243 11.893L37.607 0.257C37.442 0.093 37.218 0 36.985 0Z"
        fill="#E9E9E0"
      />
      <path d="M37.5 0.151367V12.0004H49.349L37.5 0.151367Z" fill="#D9D7CA" />
      <path
        d="M18.5 13H12.5C11.948 13 11.5 12.552 11.5 12C11.5 11.448 11.948 11 12.5 11H18.5C19.052 11 19.5 11.448 19.5 12C19.5 12.552 19.052 13 18.5 13Z"
        fill="#8697CB"
      />
      <path
        d="M21.5 18H12.5C11.948 18 11.5 17.552 11.5 17C11.5 16.448 11.948 16 12.5 16H21.5C22.052 16 22.5 16.448 22.5 17C22.5 17.552 22.052 18 21.5 18Z"
        fill="#8697CB"
      />
      <path
        d="M25.5 18.0002C25.24 18.0002 24.98 17.8902 24.79 17.7102C24.61 17.5202 24.5 17.2602 24.5 17.0002C24.5 16.7402 24.61 16.4802 24.79 16.2902C25.16 15.9202 25.84 15.9202 26.21 16.2902C26.39 16.4802 26.5 16.7402 26.5 17.0002C26.5 17.2602 26.39 17.5202 26.21 17.7102C26.02 17.8902 25.76 18.0002 25.5 18.0002Z"
        fill="#8697CB"
      />
      <path
        d="M37.5 18H29.5C28.948 18 28.5 17.552 28.5 17C28.5 16.448 28.948 16 29.5 16H37.5C38.052 16 38.5 16.448 38.5 17C38.5 17.552 38.052 18 37.5 18Z"
        fill="#8697CB"
      />
      <path
        d="M12.5 33.0002C12.24 33.0002 11.98 32.8902 11.79 32.7102C11.61 32.5202 11.5 32.2602 11.5 32.0002C11.5 31.7402 11.61 31.4802 11.79 31.2902C12.16 30.9202 12.84 30.9202 13.21 31.2902C13.39 31.4802 13.5 31.7302 13.5 32.0002C13.5 32.2602 13.39 32.5202 13.21 32.7102C13.02 32.8902 12.76 33.0002 12.5 33.0002Z"
        fill="#8697CB"
      />
      <path
        d="M24.5 33H16.5C15.948 33 15.5 32.552 15.5 32C15.5 31.448 15.948 31 16.5 31H24.5C25.052 31 25.5 31.448 25.5 32C25.5 32.552 25.052 33 24.5 33Z"
        fill="#8697CB"
      />
      <path
        d="M43.5 18H41.5C40.948 18 40.5 17.552 40.5 17C40.5 16.448 40.948 16 41.5 16H43.5C44.052 16 44.5 16.448 44.5 17C44.5 17.552 44.052 18 43.5 18Z"
        fill="#8697CB"
      />
      <path
        d="M34.5 23H12.5C11.948 23 11.5 22.552 11.5 22C11.5 21.448 11.948 21 12.5 21H34.5C35.052 21 35.5 21.448 35.5 22C35.5 22.552 35.052 23 34.5 23Z"
        fill="#8697CB"
      />
      <path
        d="M43.5 23H37.5C36.948 23 36.5 22.552 36.5 22C36.5 21.448 36.948 21 37.5 21H43.5C44.052 21 44.5 21.448 44.5 22C44.5 22.552 44.052 23 43.5 23Z"
        fill="#8697CB"
      />
      <path
        d="M16.5 28H12.5C11.948 28 11.5 27.552 11.5 27C11.5 26.448 11.948 26 12.5 26H16.5C17.052 26 17.5 26.448 17.5 27C17.5 27.552 17.052 28 16.5 28Z"
        fill="#8697CB"
      />
      <path
        d="M30.5 28H20.5C19.948 28 19.5 27.552 19.5 27C19.5 26.448 19.948 26 20.5 26H30.5C31.052 26 31.5 26.448 31.5 27C31.5 27.552 31.052 28 30.5 28Z"
        fill="#8697CB"
      />
      <path
        d="M43.5 28H34.5C33.948 28 33.5 27.552 33.5 27C33.5 26.448 33.948 26 34.5 26H43.5C44.052 26 44.5 26.448 44.5 27C44.5 27.552 44.052 28 43.5 28Z"
        fill="#8697CB"
      />
      <path
        d="M48.037 56H7.963C7.155 56 6.5 55.345 6.5 54.537V39H49.5V54.537C49.5 55.345 48.845 56 48.037 56Z"
        fill="#0096E6"
      />
      <path
        d="M23.5 47.6818C23.5 48.5108 23.411 49.2198 23.233 49.8078C23.055 50.3958 22.83 50.8878 22.556 51.2848C22.282 51.6818 21.975 51.9938 21.633 52.2218C21.291 52.4498 20.961 52.6198 20.642 52.7348C20.323 52.8488 20.031 52.9218 19.767 52.9538C19.503 52.9838 19.307 52.9998 19.18 52.9998H15.366V42.9238H18.4C19.248 42.9238 19.993 43.0588 20.635 43.3268C21.277 43.5948 21.811 43.9538 22.235 44.3998C22.659 44.8458 22.975 45.3548 23.185 45.9238C23.395 46.4938 23.5 47.0798 23.5 47.6818ZM18.633 51.7968C19.745 51.7968 20.547 51.4418 21.039 50.7308C21.531 50.0198 21.777 48.9898 21.777 47.6408C21.777 47.2218 21.727 46.8068 21.627 46.3968C21.526 45.9868 21.333 45.6158 21.046 45.2828C20.759 44.9498 20.369 44.6808 19.877 44.4758C19.385 44.2708 18.747 44.1678 17.963 44.1678H17.006V51.7968H18.633Z"
        fill="white"
      />
      <path
        d="M33.4751 47.9138C33.4751 48.7618 33.3681 49.5088 33.1541 50.1558C32.9401 50.8028 32.6431 51.3408 32.2651 51.7688C31.8871 52.1978 31.4451 52.5208 30.9391 52.7398C30.4331 52.9588 29.8791 53.0678 29.2781 53.0678C28.6771 53.0678 28.1231 52.9588 27.6171 52.7398C27.1111 52.5208 26.6691 52.1978 26.2911 51.7688C25.9131 51.3398 25.6161 50.8028 25.4021 50.1558C25.1881 49.5088 25.0811 48.7608 25.0811 47.9138C25.0811 47.0668 25.1881 46.3208 25.4021 45.6788C25.6161 45.0358 25.9121 44.5008 26.2911 44.0728C26.6691 43.6438 27.1111 43.3188 27.6171 43.0948C28.1231 42.8708 28.6771 42.7598 29.2781 42.7598C29.8791 42.7598 30.4331 42.8708 30.9391 43.0948C31.4451 43.3188 31.8871 43.6438 32.2651 44.0728C32.6431 44.5018 32.9391 45.0368 33.1541 45.6788C33.3671 46.3208 33.4751 47.0658 33.4751 47.9138ZM29.2361 51.7288C29.5731 51.7288 29.8941 51.6628 30.2001 51.5308C30.5051 51.3988 30.7791 51.1818 31.0201 50.8818C31.2611 50.5808 31.4511 50.1868 31.5871 49.6988C31.7231 49.2108 31.7961 48.6168 31.8061 47.9148C31.7971 47.2308 31.7261 46.6498 31.5941 46.1718C31.4621 45.6928 31.2801 45.2988 31.0471 44.9888C30.8141 44.6788 30.5501 44.4558 30.2541 44.3188C29.9581 44.1818 29.6461 44.1138 29.3171 44.1138C28.9801 44.1138 28.6581 44.1768 28.3531 44.3048C28.0471 44.4328 27.7741 44.6488 27.5331 44.9538C27.2911 45.2598 27.1021 45.6528 26.9661 46.1368C26.8301 46.6208 26.7561 47.2118 26.7471 47.9138C26.7561 48.5978 26.8271 49.1808 26.9591 49.6638C27.0911 50.1468 27.2731 50.5408 27.5061 50.8468C27.7391 51.1528 28.0031 51.3748 28.2991 51.5168C28.5961 51.6578 28.9081 51.7288 29.2361 51.7288Z"
        fill="white"
      />
      <path
        d="M42.6069 51.9748C42.2329 52.3388 41.8089 52.6128 41.3359 52.7948C40.8619 52.9778 40.3519 53.0678 39.8049 53.0678C39.2029 53.0678 38.6499 52.9588 38.1439 52.7398C37.6379 52.5208 37.1959 52.1978 36.8179 51.7688C36.4399 51.3398 36.1429 50.8028 35.9289 50.1558C35.7149 49.5088 35.6079 48.7608 35.6079 47.9138C35.6079 47.0668 35.7149 46.3208 35.9289 45.6788C36.1429 45.0358 36.4389 44.5008 36.8179 44.0728C37.1959 43.6438 37.6399 43.3188 38.1509 43.0948C38.6609 42.8708 39.2129 42.7598 39.8049 42.7598C40.3519 42.7598 40.8619 42.8508 41.3359 43.0328C41.8099 43.2158 42.2329 43.4888 42.6069 43.8528L41.4719 44.8648C41.2439 44.5998 40.9909 44.4088 40.7129 44.2908C40.4349 44.1728 40.1459 44.1128 39.8449 44.1128C39.5079 44.1128 39.1859 44.1758 38.8809 44.3038C38.5749 44.4318 38.3019 44.6478 38.0609 44.9528C37.8189 45.2588 37.6299 45.6518 37.4939 46.1358C37.3579 46.6198 37.2839 47.2108 37.2749 47.9128C37.2839 48.5968 37.3549 49.1798 37.4869 49.6628C37.6189 50.1458 37.8009 50.5398 38.0339 50.8458C38.2669 51.1518 38.5309 51.3738 38.8269 51.5158C39.1229 51.6578 39.4349 51.7278 39.7639 51.7278C40.0929 51.7278 40.3999 51.6678 40.6869 51.5498C40.9739 51.4318 41.2359 51.2398 41.4729 50.9758L42.6069 51.9748Z"
        fill="white"
      />
    </svg>
  ),
  png: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M36.985 0H7.963C7.155 0 6.5 0.655 6.5 1.926V55C6.5 55.345 7.155 56 7.963 56H48.037C48.845 56 49.5 55.345 49.5 55V12.978C49.5 12.282 49.407 12.058 49.243 11.893L37.607 0.257C37.442 0.093 37.218 0 36.985 0Z"
        fill="#E9E9E0"
      />
      <path
        d="M48.037 56H7.963C7.155 56 6.5 55.345 6.5 54.537V39H49.5V54.537C49.5 55.345 48.845 56 48.037 56Z"
        fill="#659C35"
      />
      <path d="M37.5 0.151367V12.0004H49.349L37.5 0.151367Z" fill="#D9D7CA" />
      <path
        d="M17.3849 52.9998H15.7439V42.9238H18.6419C19.0699 42.9238 19.4939 42.9918 19.9129 43.1288C20.3319 43.2658 20.7079 43.4708 21.0409 43.7438C21.3739 44.0168 21.6429 44.3478 21.8479 44.7348C22.0529 45.1218 22.1559 45.5568 22.1559 46.0408C22.1559 46.5518 22.0689 47.0138 21.8959 47.4288C21.7229 47.8438 21.4809 48.1928 21.1709 48.4748C20.8609 48.7568 20.4869 48.9758 20.0499 49.1308C19.6129 49.2858 19.1289 49.3628 18.6009 49.3628H17.3839L17.3849 52.9998ZM17.3849 44.1678V48.1598H18.8889C19.0889 48.1598 19.2869 48.1258 19.4839 48.0568C19.6799 47.9888 19.8599 47.8768 20.0239 47.7218C20.1879 47.5668 20.3199 47.3508 20.4199 47.0728C20.5199 46.7948 20.5699 46.4508 20.5699 46.0408C20.5699 45.8768 20.5469 45.6868 20.5019 45.4738C20.4559 45.2598 20.3629 45.0548 20.2219 44.8588C20.0799 44.6628 19.8819 44.4988 19.6269 44.3668C19.3719 44.2348 19.0339 44.1688 18.6149 44.1688L17.3849 44.1678Z"
        fill="white"
      />
      <path
        d="M31.3158 42.9238V52.9998H29.6478L25.6968 46.0548V52.9998H24.0288V42.9238H25.6968L29.6478 49.8688V42.9238H31.3158Z"
        fill="white"
      />
      <path
        d="M41.1599 47.8048V51.7008C40.9499 51.9658 40.7159 52.1808 40.4559 52.3498C40.1959 52.5188 39.9229 52.6578 39.6359 52.7668C39.3489 52.8758 39.0519 52.9538 38.7469 52.9998C38.4409 53.0458 38.1389 53.0678 37.8379 53.0678C37.2359 53.0678 36.6829 52.9588 36.1769 52.7398C35.6709 52.5208 35.2289 52.1978 34.8509 51.7688C34.4729 51.3398 34.1759 50.8028 33.9619 50.1558C33.7479 49.5088 33.6409 48.7608 33.6409 47.9138C33.6409 47.0668 33.7479 46.3208 33.9619 45.6788C34.1759 45.0358 34.4719 44.5008 34.8509 44.0728C35.2289 43.6438 35.6729 43.3188 36.1839 43.0948C36.6939 42.8708 37.2459 42.7598 37.8379 42.7598C38.3849 42.7598 38.8949 42.8508 39.3689 43.0328C39.8429 43.2158 40.2659 43.4888 40.6399 43.8528L39.5049 44.8648C39.2859 44.5998 39.0349 44.4088 38.7529 44.2908C38.4699 44.1728 38.1789 44.1128 37.8779 44.1128C37.5409 44.1128 37.2189 44.1758 36.9139 44.3038C36.6079 44.4318 36.3349 44.6478 36.0939 44.9528C35.8519 45.2588 35.6629 45.6518 35.5269 46.1358C35.3909 46.6198 35.3169 47.2108 35.3079 47.9128C35.3169 48.5968 35.3879 49.1888 35.5199 49.6898C35.6519 50.1908 35.8339 50.6008 36.0669 50.9198C36.2999 51.2388 36.5639 51.4758 36.8599 51.6308C37.1559 51.7858 37.4679 51.8628 37.7969 51.8628C37.8969 51.8628 38.0309 51.8558 38.1999 51.8418C38.3679 51.8278 38.5369 51.8058 38.7059 51.7738C38.8739 51.7418 39.0359 51.6988 39.1909 51.6438C39.3459 51.5888 39.4599 51.5118 39.5329 51.4118V48.9238H37.8239V47.8028H41.1599V47.8048Z"
        fill="white"
      />
      <path
        d="M18.9311 19.0003C21.4544 19.0003 23.5001 16.9547 23.5001 14.4313C23.5001 11.9079 21.4544 9.8623 18.9311 9.8623C16.4077 9.8623 14.3621 11.9079 14.3621 14.4313C14.3621 16.9547 16.4077 19.0003 18.9311 19.0003Z"
        fill="#F3D55B"
      />
      <path
        d="M6.5 39H17.5H49.5V28L39.5 18.5L29 30L23.517 24.517L6.5 39Z"
        fill="#88C057"
      />
    </svg>
  ),
  add: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_10583_54470)">
        <path
          d="M23.8738 15.9998L8.12616 15.9998M16 8.12598L16 23.8737L16 8.12598Z"
          stroke="#080808"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10583_54470">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  listViewSelected: (
    <svg
      width="44"
      height="36"
      viewBox="0 0 44 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43" height="35" rx="3.5" fill="#D9E4EA" />
      <rect x="0.5" y="0.5" width="43" height="35" rx="3.5" stroke="#407797" />
      <g clip-path="url(#clip0_10559_12869)">
        <path
          d="M22.833 12.167H29.4997"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.833 15.5H26.9997"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.833 20.5H29.4997"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.833 23.833H26.9997"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6667 11.333H15.3333C14.8731 11.333 14.5 11.7061 14.5 12.1663V15.4997C14.5 15.9599 14.8731 16.333 15.3333 16.333H18.6667C19.1269 16.333 19.5 15.9599 19.5 15.4997V12.1663C19.5 11.7061 19.1269 11.333 18.6667 11.333Z"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6667 19.667H15.3333C14.8731 19.667 14.5 20.0401 14.5 20.5003V23.8337C14.5 24.2939 14.8731 24.667 15.3333 24.667H18.6667C19.1269 24.667 19.5 24.2939 19.5 23.8337V20.5003C19.5 20.0401 19.1269 19.667 18.6667 19.667Z"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12869">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 8)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  listView: (
    <svg
      width="43"
      height="34"
      viewBox="0 0 43 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="43" height="34" rx="4" fill="white" />
      <g clip-path="url(#clip0_10559_17246)">
        <path
          d="M22.833 11.167H29.4997"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.833 14.5H26.9997"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.833 19.5H29.4997"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.833 22.833H26.9997"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6667 10.333H15.3333C14.8731 10.333 14.5 10.7061 14.5 11.1663V14.4997C14.5 14.9599 14.8731 15.333 15.3333 15.333H18.6667C19.1269 15.333 19.5 14.9599 19.5 14.4997V11.1663C19.5 10.7061 19.1269 10.333 18.6667 10.333Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6667 18.667H15.3333C14.8731 18.667 14.5 19.0401 14.5 19.5003V22.8337C14.5 23.2939 14.8731 23.667 15.3333 23.667H18.6667C19.1269 23.667 19.5 23.2939 19.5 22.8337V19.5003C19.5 19.0401 19.1269 18.667 18.6667 18.667Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_17246">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 7)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  boardView: (
    <svg
      width="43"
      height="34"
      viewBox="0 0 43 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="43" height="34" rx="4" fill="white" />
      <g clip-path="url(#clip0_10559_12866)">
        <path
          d="M15.333 10.333H20.333"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.667 10.333H28.667"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6663 13.667H16.9997C16.0792 13.667 15.333 14.4132 15.333 15.3337V22.0003C15.333 22.9208 16.0792 23.667 16.9997 23.667H18.6663C19.5868 23.667 20.333 22.9208 20.333 22.0003V15.3337C20.333 14.4132 19.5868 13.667 18.6663 13.667Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.0003 13.667H25.3337C24.4132 13.667 23.667 14.4132 23.667 15.3337V17.0003C23.667 17.9208 24.4132 18.667 25.3337 18.667H27.0003C27.9208 18.667 28.667 17.9208 28.667 17.0003V15.3337C28.667 14.4132 27.9208 13.667 27.0003 13.667Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_12866">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 7)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  boardViewSelected: (
    <svg
      width="44"
      height="36"
      viewBox="0 0 44 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43" height="35" rx="3.5" fill="#D9E4EA" />
      <rect x="0.5" y="0.5" width="43" height="35" rx="3.5" stroke="#407797" />
      <g clip-path="url(#clip0_10559_17243)">
        <path
          d="M15.333 11.333H20.333"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.667 11.333H28.667"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6663 14.667H16.9997C16.0792 14.667 15.333 15.4132 15.333 16.3337V23.0003C15.333 23.9208 16.0792 24.667 16.9997 24.667H18.6663C19.5868 24.667 20.333 23.9208 20.333 23.0003V16.3337C20.333 15.4132 19.5868 14.667 18.6663 14.667Z"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.0003 14.667H25.3337C24.4132 14.667 23.667 15.4132 23.667 16.3337V18.0003C23.667 18.9208 24.4132 19.667 25.3337 19.667H27.0003C27.9208 19.667 28.667 18.9208 28.667 18.0003V16.3337C28.667 15.4132 27.9208 14.667 27.0003 14.667Z"
          stroke="#407797"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10559_17243">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 8)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  priority: {
    low: (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.37345 14.5064L0.96229 9.09526C0.34981 8.48278 0.34981 7.35507 0.96229 6.74259L6.37345 1.33143C6.98593 0.71895 8.11364 0.71895 8.72612 1.33143L14.1373 6.74259C14.7498 7.35507 14.7498 8.48278 14.1373 9.09526L8.72612 14.5064C8.11364 15.1189 6.98593 15.1189 6.37345 14.5064V14.5064Z"
          fill="#FDCD40"
        />
      </svg>
    ),
    medium: (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.37345 13.7076L0.96229 8.29643C0.34981 7.68395 0.34981 6.55624 0.96229 5.94376L6.37345 0.532602C6.98593 -0.0798778 8.11364 -0.0798778 8.72612 0.532602L14.1373 5.94376C14.7498 6.55624 14.7498 7.68395 14.1373 8.29643L8.72612 13.7076C8.11364 14.3201 6.98593 14.3201 6.37345 13.7076V13.7076Z"
          fill="#FF9300"
        />
      </svg>
    ),
    high: (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.90762 14.5054L0.496469 9.09429C-0.116011 8.48181 -0.116011 7.35409 0.496469 6.74161L5.90762 1.33045C6.5201 0.717974 7.64782 0.717974 8.2603 1.33045L13.6715 6.74161C14.2839 7.35409 14.2839 8.48181 13.6715 9.09429L8.2603 14.5054C7.64782 15.1179 6.5201 15.1179 5.90762 14.5054V14.5054Z"
          fill="#FF3030"
        />
      </svg>
    ),
  },
  logs: {
    participant_added: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="20.5"
          fill="#65B5E3"
          stroke="white"
          stroke-width="3"
        />
        <g clip-path="url(#clip0_11374_14755)">
          <path
            d="M29.7782 21.7782H14.2218M22 14V29.5563V14Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11374_14755">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    reply: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="20.5"
          fill="#65B5E3"
          stroke="white"
          stroke-width="3"
        />
        <g clip-path="url(#clip0_11374_14749)">
          <path
            d="M23.0002 14V18C16.4252 19.028 13.9802 24.788 13.0002 30C12.9632 30.206 18.3842 24.038 23.0002 24V28L31.0002 21L23.0002 14Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11374_14749">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    refresh: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="20.5"
          fill="#65B5E3"
          stroke="white"
          stroke-width="3"
        />
        <g clip-path="url(#clip0_11374_14746)">
          <path
            d="M14 22V19C14 18.2044 14.3161 17.4413 14.8787 16.8787C15.4413 16.3161 16.2044 16 17 16H30M27 13L30 16L27 19"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30 22V25C30 25.7956 29.6839 26.5587 29.1213 27.1213C28.5587 27.6839 27.7956 28 27 28H14M17 31L14 28L17 25"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11374_14746">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    added_notes: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="20.5"
          fill="#65B5E3"
          stroke="white"
          stroke-width="3"
        />
        <g clip-path="url(#clip0_11374_14740)">
          <path
            d="M24 13V17C24 17.2652 24.1054 17.5196 24.2929 17.7071C24.4804 17.8946 24.7348 18 25 18H29"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27 31H17C16.4696 31 15.9609 30.7893 15.5858 30.4142C15.2107 30.0391 15 29.5304 15 29V15C15 14.4696 15.2107 13.9609 15.5858 13.5858C15.9609 13.2107 16.4696 13 17 13H24L29 18V29C29 29.5304 28.7893 30.0391 28.4142 30.4142C28.0391 30.7893 27.5304 31 27 31Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19 22H23"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19 26H21"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11374_14740">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    action_created: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="20.5"
          fill="#65B5E3"
          stroke="white"
          stroke-width="3"
        />
        <path
          d="M22 31C26.9706 31 31 26.9706 31 22C31 17.0294 26.9706 13 22 13C17.0294 13 13 17.0294 13 22C13 26.9706 17.0294 31 22 31Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19 22L21 24L25 20"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    archive: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="20.5"
          fill="#65B5E3"
          stroke="white"
          stroke-width="3"
        />
        <g clip-path="url(#clip0_11374_14734)">
          <path
            d="M12.6514 16C12.6514 15.4696 12.8621 14.9609 13.2372 14.5858C13.6122 14.2107 14.1209 14 14.6514 14H28.6514C29.1818 14 29.6905 14.2107 30.0656 14.5858C30.4407 14.9609 30.6514 15.4696 30.6514 16C30.6514 16.5304 30.4407 17.0391 30.0656 17.4142C29.6905 17.7893 29.1818 18 28.6514 18H14.6514C14.1209 18 13.6122 17.7893 13.2372 17.4142C12.8621 17.0391 12.6514 16.5304 12.6514 16Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.6514 18V28C14.6514 28.5304 14.8621 29.0391 15.2372 29.4142C15.6122 29.7893 16.1209 30 16.6514 30H26.6514C27.1818 30 27.6905 29.7893 28.0656 29.4142C28.4407 29.0391 28.6514 28.5304 28.6514 28V18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.6514 22H23.6514"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11374_14734">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(10 10)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
}
