import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import UploadAttachments from '../../../shared/components/uploadAttachments'
import { actionIcons } from '../../ActionIcons'

export const NewActionItemForm = ({
  register,
  errors,
  photoDetails,
  isEdit,
  control,
  users = [],
  statuses = [],
  action_types = [],
  sub_categories = [],
  priorities = [],
  groups = [],
  initialValues = null,
  onChangeCompany,
  allow_to_edit_group = false,
  form_fields,
  setValue,
}) => {
  const {
    hide_subcategory_field = false,
    subcategory_mandatory_field = false,
  } = form_fields || {}
  const [groupedOptions, setGroupOptions] = useState([])
  const [expandedGroups, setExpandedGroups] = useState(
    groupedOptions.reduce((acc, group) => {
      acc[group.group] = true
      return acc
    }, {})
  )

  const transformObject = (obj = {}) => {
    return (
      Object.keys(obj).map((group) => ({
        group, // The group name from the key
        items: obj[group], // The corresponding array of key-value pairs
      })) || []
    )
  }
  useEffect(() => {
    const convertedValues = transformObject(users)
    setGroupOptions(convertedValues)
    setExpandedGroups(
      convertedValues.reduce((acc, group) => {
        acc[group.group] = true
        return acc
      }, {})
    )
  }, [users])

  const toggleGroup = (group) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="">
                Groups
              </Typography>
            </Box>
            {isEdit && !allow_to_edit_group ? (
              <span>{initialValues.group_name}</span>
            ) : (
              <Controller
                name="group_id"
                control={control}
                rules={{ required: 'Group is required' }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    size="small"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event)
                      onChangeCompany(event.target.value) // Custom onChange logic
                      setValue('assignees', [])
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select Groups</em>
                    </MenuItem>
                    {groups.map((u) => (
                      <MenuItem value={u.value}>{u.key}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth error={!!errors.label}>
            <Box mb={1}>
              <Typography variant="body1">
                Title <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              size="small"
              {...register('label', {
                required: 'Name is required',
              })}
              placeholder="Enter Name"
              error={!!errors.label}
            />
            {errors.label && (
              <FormHelperText>{errors.label.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1">Description</Typography>
            </Box>
            <TextField
              size="small"
              {...register('description')}
              placeholder="Enter Description"
              error={!!errors.description}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Action Type
              </Typography>
            </Box>
            <Controller
              name="action_type_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Action Type</em>
                  </MenuItem>
                  {action_types.map((u) => (
                    <MenuItem value={u.value}>{u.key}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {!hide_subcategory_field && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Box mb={1}>
                <Typography variant="body1" className="f-label">
                  Sub Category{' '}
                  {subcategory_mandatory_field && (
                    <span style={{ color: 'red' }}>*</span>
                  )}
                </Typography>
              </Box>
              <Controller
                name="sub_category_id"
                control={control}
                rules={
                  subcategory_mandatory_field
                    ? { required: 'Sub Category is required' }
                    : {}
                }
                defaultValue=""
                render={({ field }) => (
                  <Select size="small" {...field} displayEmpty>
                    <MenuItem value="">
                      <em>Select Sub Category</em>
                    </MenuItem>
                    {sub_categories.map((u) => (
                      <MenuItem value={u.value}>{u.key}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Priority
              </Typography>
            </Box>
            <Controller
              name="priority"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Priority</em>
                  </MenuItem>
                  {priorities.map((u) => (
                    <MenuItem key={u.value} value={u.value}>
                      {u.key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Due Date
              </Typography>
            </Box>
            <TextField
              size="small"
              type="datetime-local"
              {...register('due_date')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Status
              </Typography>
            </Box>
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Status</em>
                  </MenuItem>
                  {statuses.map((u) => (
                    <MenuItem value={u.value}>{u.key}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Select Assignee <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <Controller
              name="assignees"
              control={control}
              defaultValue={[]}
              render={({ field, fieldState }) => {
                const { value, onChange } = field // Extract value and onChange
                const selectedValues = value || []
                const { error } = fieldState // Extract error from fieldState

                return (
                  <FormControl fullWidth error={!!error}>
                    {/* <InputLabel id="assignees-label">
                      Select Assignee
                    </InputLabel> */}
                    <Select
                      labelId="assignees-label"
                      multiple
                      size="small"
                      {...field}
                      inputProps={{ 'aria-label': 'Without label' }}
                      value={groupedOptions.length > 0 ? value : []}
                      displayEmpty
                      input={<OutlinedInput label="Select Assignee" />}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      renderValue={(selected) =>
                        selected && selected.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '5px',
                            }}
                          >
                            {selected.map((val) => {
                              const selectedItem = groupedOptions
                                .flatMap((group) => group.items)
                                .find((item) => item.value === val)

                              return (
                                <Chip
                                  key={val}
                                  label={selectedItem && selectedItem.key}
                                  // onDelete={() =>
                                  //   onChange(selected.filter((v) => v !== val))
                                  // }
                                  style={{ margin: '2px' }}
                                />
                              )
                            })}
                          </div>
                        ) : (
                          <span style={{ color: '#b3b3b3' }}>
                            Select Assignee
                          </span>
                        )
                      }
                    >
                      {groupedOptions.map((group) => [
                        <ListSubheader
                          key={`group-${group.group}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            background: '#e8e8e8',
                            color: '#080808',
                            margin: '5px 0',
                          }}
                        >
                          <span>{group.group}</span>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation() // Prevent closing the dropdown
                              toggleGroup(group.group)
                            }}
                          >
                            {expandedGroups[group.group]
                              ? actionIcons.arrowDown
                              : actionIcons.arrowUp}
                          </IconButton>
                        </ListSubheader>,
                        expandedGroups[group.group] &&
                          group.items.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              <Checkbox
                                checked={
                                  selectedValues.indexOf(item.value) > -1
                                }
                              />
                              {item.key}
                            </MenuItem>
                          )),
                      ])}
                    </Select>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Upload Attachment
            </Typography>
          </Box>
          <UploadAttachments
            control={control}
            name="photos"
            multiple={true}
            setValue={setValue}
            existingFiles={photoDetails.file_name ? [photoDetails] : []}
          />
        </Grid>
      </Grid>
    </>
  )
}
