import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';
import HeroBannerImage from "../../assets/images/TrainingRecordManagement.png";
export default function TaskManagement() {
    return (
            <div className="training-record-management-section">
                <img src={HeroBannerImage}  />
            </div>
        );

}
