import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Application from "../../../../config/ApplicationWrapper";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl as Input,
} from "react-bootstrap";
import Cookies from "js-cookie";
import FormHelperText from "@mui/material/FormHelperText";

let initialValue = {
  name: "",
  description: "",
  notes: "",
  company: "",
  service_intervals: "",
  unit: "",
  location: "",
  status: "Scheduled",
  last_scheduled_at: "",
  maintenanceMilage: "",
  maintenanceHours: "",
  approved_maintenance: "",
  parties_mappings_attributes: [
    {
      user_id: 1,
    },
  ],
  unitValue: "",
  interval_starts_at: "",
  is_notification: false,
  suspended: false
};

export default function CreateMaintenance(props) {
  const { show, closepopup, data, parentId } = props;
  const defaultUnit = data && data.unit ? data.unit : "Hours";
  const [party, setParty] = useState([]);
  const [responsibleParties, setResponsibleParties] = React.useState([]);
  const [existingScheduleMaintenances, setExistingScheduleMaintenances] = React.useState([]);
  const [selectedExistingSchedule, setSelectedExistingSchedule] = React.useState(null);
  const cookieDetails = Cookies.get("token");
  const [onSubmit, setSubmit] = useState(false);
  const [values, setValues] = useState(initialValue);
  const [units, setUnits] = useState(defaultUnit);
  const [approvedMaintenances, setApprovedMaintenances] = useState([]);
  const [maintenanceUnits, setMaintenanceUnits] = useState([defaultUnit]);
  const [unitLabel, setUnitLabel] = useState(defaultUnit);

  useEffect(() => {
    try {
      async function getPageDetails() {
        const responsiblePartiesDetails = await fetch(
          `${Application.api_url}/api/v2/common/maintainers`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const result = await responsiblePartiesDetails.json();
        setResponsibleParties(result && result.data ? result.data : []);

        const details = await fetch(
          `${Application.api_url}/api/v2/common/approved_maintenances`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const approvers = await details.json();
        setApprovedMaintenances(
          approvers && approvers.data ? approvers.data : []
        );

        const existingScheduleMaintenancesDetails = await fetch(
          `${Application.api_url}/api/v2/maintenance_schedules/existing_uniq_maintenance_schedules`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const maintenances = await existingScheduleMaintenancesDetails.json();
        setExistingScheduleMaintenances(
          maintenances && maintenances.data ? maintenances.data : []
        );
      }
      getPageDetails();
    } catch (err) {
      console.log("err===>", err);
    }
  }, []);

  useEffect(() => {
    if (data && data.id) {
      let obj = {};
      obj.name = data.name;
      obj.description = data.description;
      obj.notes = data.notes;
      obj.company = data.company;
      obj.service_intervals = data.service_intervals;
      obj.unit = data.unit;
      obj.location = data.location;
      obj.status = data.status;
      obj.last_scheduled_at = data.last_scheduled_at;
      obj.maintenanceMilage = data.last_maintenance_type == "Mileage" ? data.last_maintenance_amount : "";
      obj.maintenanceHours = data.last_maintenance_type != "Mileage" ? data.last_maintenance_amount : "";
      obj.approved_maintenance = data.approved_maintenance;
      obj.unitValue = data.last_maintenance_amount;
      obj.interval_starts_at = data.interval_starts_at;
      obj.is_notification = data.is_notification;
      obj.suspended = data.suspended;
      setParty(data.responsible_parties);
      setValues(obj);
      if (data.unit == "Mileage") {
        setUnits("Mileage")
      } else if (data.unit == "Hours") {
        setUnits("Hours")
      } else if (data.unit == "Days") {
        setUnits("Days")
      } else {
        setUnits("Kilometers")
      }
      setUnitLabel(data.unit);
    }
  }, [data]);

  useEffect(() => {
    if (selectedExistingSchedule) {
      let obj = {};
      obj.name = selectedExistingSchedule.name;
      obj.description = selectedExistingSchedule.description;
      obj.notes = selectedExistingSchedule.notes;
      obj.company = selectedExistingSchedule.company;
      obj.service_intervals = selectedExistingSchedule.service_intervals;
      obj.unit = selectedExistingSchedule.unit;
      obj.location = selectedExistingSchedule.location;
      obj.status = initialValue.status;
      obj.last_scheduled_at = selectedExistingSchedule.last_scheduled_at;
      obj.maintenanceMilage = selectedExistingSchedule.last_maintenance_type == "Mileage" ? selectedExistingSchedule.last_maintenance_amount : "";
      obj.maintenanceHours = selectedExistingSchedule.last_maintenance_type != "Mileage" ? selectedExistingSchedule.last_maintenance_amount : "";
      obj.approved_maintenance = selectedExistingSchedule.approved_maintenance;
      obj.unitValue = selectedExistingSchedule.last_maintenance_amount;
      obj.interval_starts_at = selectedExistingSchedule.interval_starts_at;
      obj.is_notification = selectedExistingSchedule.is_notification;
      obj.suspended = selectedExistingSchedule.suspended;
      setParty(selectedExistingSchedule.responsible_parties);
      setValues(obj);
      if (selectedExistingSchedule.unit == "mileage") {
        setUnits("Mileage")
      } else if (selectedExistingSchedule.unit == "hours") {
        setUnits("Hours")
      } else if (selectedExistingSchedule.unit == "days") {
        setUnits("Days")
      } else {
        setUnits("Kilometers")
      }
      setUnitLabel(selectedExistingSchedule.unit);
    } else {
      if (!data) {
        setValues(initialValue);
        setParty([]);
      }
    }
  }, [selectedExistingSchedule]);

  const handleChange = (name, event) => {
    switch (name) {
      case "maintenanceMilage":
        values.maintenanceMilage = event.target.value;
        values.maintenanceHours = "";
        setValues({ ...values });
        break;
      case "maintenanceHours":
        values.maintenanceHours = event.target.value;
        values.maintenanceMilage = "";
        setValues({ ...values });
        break;
      default:
        setValues({ ...values, [name]: event.target.value });
    }
    // setValues({ ...values, [name]: event.target.value });
  };

  const updateMaintentance = async () => {
    try {
      setSubmit(true);

      values.unit = units;
      values.notification_interval = "";

      if (!values.name || !values.unit || !values.service_intervals || (values.unit == "Days" && !values.interval_starts_at)) {
        return false;
      }

      if (values.unit == "Days" && (Number(values.service_intervals) < 1 || Number(values.service_intervals) > 999)) {
        return false;
      }


      if (values.unit == "Days") {
        values.notification_interval = values.service_intervals;
      } else {
        values.is_notification = false;
        values.suspended = false;
      }

      let getAssetId = localStorage.getItem("assetInfo");
      let assetId = getAssetId ? JSON.parse(getAssetId) : "";

      values.last_maintenance_amount = values.unitValue;
      values.last_maintenance_type = unitLabel;
      values.parties_mapping_ids = party.map((obj) => obj.id).join(",");

      let newAssetId
      if (parentId) {
        newAssetId = parentId;
      } else if (assetId && assetId.asstId) {
        newAssetId = assetId.asstId;
      }

      let url = `${Application.api_url}/api/v2/asset_trackings/${newAssetId}/maintenance_schedules`;
      let method = "POST";
      if (data && data.id) {
        url = `${Application.api_url}/api/v2/asset_trackings/${newAssetId}/maintenance_schedules/${data.id}`;
        method = "PUT";
      }

      const create = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: cookieDetails,
        },
        body: JSON.stringify(values),
      });
      if (create && create.status === 200) {
        setValues(initialValue);
        setSubmit(false);
        setParty([])
        closepopup(Date.now());
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const intervalValidation = (intervals) => {
    let values;
    if (!intervals && onSubmit) {
      values = `Number Of ${units} is required`
    } else if (onSubmit) {
      if (units == "Days") {
        if (intervals < 1) {
          values = `Value should be greater than 0`;
        } else if (intervals > 999) {
          values = `Value should be less than 999`;
        }
      }
    }
    return values;
  }

  const intervalError = (intervals) => {
    let values = false;
    if (!intervals && onSubmit) {
      values = true;
    } else if (onSubmit) {
      if (units == "Days") {
        if (intervals < 1) {
          values = true;
        } else if (intervals > 999) {
          values = true;
        }
      }
    }
    return values;
  }

  return (
    <Modal
      bsSize="large"
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="create-maintanance-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <b> {data && data.id ? "Edit Schedule" : "Add Schedule"} </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-form">
          <div className="row">
            <div className="col-md-12">
              <FormGroup controlId="formControlsSelectMultiple">
                <ControlLabel>Existing Maintenance Schedule</ControlLabel>
                <Autocomplete
                  id="existing-maintenance"
                  options={existingScheduleMaintenances}
                  getOptionLabel={(option) =>
                   option.name
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} style={{ fontSize: 14 }}>
                      { option.name }
                    </li>
                  )}
                  value={selectedExistingSchedule}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => setSelectedExistingSchedule(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder="" />
                  )}
                  sx={{ width: "100%" }}
                />
              </FormGroup>
            </div>
            <div className="col-md-12">
              <label>
                Maintenance Name <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!values.name && onSubmit}
                helperText={
                  !values.name && onSubmit ? "Maintenance Name is required" : ""
                }
                value={values.name}
                onChange={(e) => {
                  handleChange("name", e);
                }}
              />
            </div>
            <div className="col-md-12">
              <label>Description</label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.description}
                onChange={(e) => {
                  handleChange("description", e);
                }}
              />
            </div>
            <div className="col-md-12">
              <label>Notes</label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.notes}
                onChange={(e) => {
                  handleChange("notes", e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <label>
                Maintenance Service Intervals
                <text style={{ color: "red" }}>*</text>
              </label>
              <FormControl
                controlId="formControlsSelectMultiple"
                required
                fullWidth
              >
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  fullWidth
                  value={units}
                  input={
                    <OutlinedInput
                      sx={{ fontSize: "14px", borderColor: "red" }}
                    />
                  } mapPosition
                  onChange={(e) => {
                    handleChange("unit", e);
                    setUnits(e.target.value);
                    setMaintenanceUnits([e.target.value]);
                    setUnitLabel(e.target.value)
                  }}
                >
                  <MenuItem value={"Hours"} style={{ fontSize: 14 }} key={2}>
                    Number Of Hours
                  </MenuItem>
                  <MenuItem value={"Mileage"} style={{ fontSize: 14 }} key={3}>
                    Number Of Mileage
                  </MenuItem>
                  <MenuItem value={"Days"} style={{ fontSize: 14 }} key={4}>
                    Number Of Days
                  </MenuItem>
                  <MenuItem value={"Kilometers"} style={{ fontSize: 14 }} key={5}>
                    Number Of Kilometers
                  </MenuItem>
                </Select>
              </FormControl>
              <FormHelperText
                className="inputErrorMsg"
                error={!units && onSubmit}
              >
                {!units && onSubmit
                  ? "Maintenance Service Intervals is required"
                  : ""}
              </FormHelperText>
            </div>
            {units == "Days" &&
              <div className="col-md-4">
                <label>
                  Starting date for the interval
                  <text style={{ color: "red" }}>*</text>
                </label>
                <TextField
                  required
                  fullWidth
                  type='date'
                  className="text-field"
                  id="outlined-required"
                  value={values.interval_starts_at}
                  error={!values.interval_starts_at && onSubmit}
                  helperText={
                    !values.interval_starts_at && onSubmit
                      ? `Starting date for the interval is required`
                      : ""
                  }
                  onChange={(e) => {
                    handleChange("interval_starts_at", e);
                  }}
                />
              </div>
            }
            <div className={units == "Days" ? "col-md-3" : "col-md-7"}>
              <label>
                Number Of {values.unit || units}
                <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                type='text'
                className="text-field"
                id="outlined-required"
                value={values.service_intervals}
                error={intervalError(values.service_intervals)}
                helperText={
                  intervalValidation(values.service_intervals)
                }
                onChange={(e) => {
                  const isNum = (num)=>!Number.isNaN(Number(num));
                  isNum(e.target.value) || e.target.value === "" ? handleChange("service_intervals", e) : "";
                }}
              />
            </div>
          </div>
          {units == "Days" &&
            <div className="row">
              <div className="col-md-6">
                <label>
                  Notify
                </label>
                <FormControl
                  controlId="formControlsSelectMultiple"
                  required
                  fullWidth
                >
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    fullWidth
                    value={values.is_notification}
                    input={
                      <OutlinedInput
                        sx={{ fontSize: "14px", borderColor: "red" }}
                      />
                    } mapPosition
                    onChange={(e) => {
                      handleChange("is_notification", e);
                    }}
                  >
                    <MenuItem value={true} style={{ fontSize: 14 }} key={2}>
                      Yes
                    </MenuItem>
                    <MenuItem value={false} style={{ fontSize: 14 }} key={3}>
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6">
                <label>
                  Suspended
                </label>
                <FormControl
                  controlId="formControlsSelectMultiple"
                  required
                  fullWidth
                >
                  <Select
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    fullWidth
                    value={values.suspended}
                    input={
                      <OutlinedInput
                        sx={{ fontSize: "14px", borderColor: "red" }}
                      />
                    } mapPosition
                    onChange={(e) => {
                      handleChange("suspended", e);
                    }}
                  >
                    <MenuItem value={true} style={{ fontSize: 14 }} key={2}>
                      Yes
                    </MenuItem>
                    <MenuItem value={false} style={{ fontSize: 14 }} key={3}>
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          }
          <div className="row">
            <div className="col-md-12">
              <label>Location of Maintenance</label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.location}
                onChange={(e) => {
                  handleChange("location", e);
                }}
              />
            </div>
            <div className="col-md-12">
              <FormGroup controlId="formControlsSelectMultiple">
                <ControlLabel>Maintenance service performed by</ControlLabel>
                <Autocomplete
                  disableCloseOnSelect
                  multiple={true}
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={responsibleParties}
                  getOptionLabel={(options) =>
                   `${options.last_name},${options.first_name} (${options.employee_id})`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} style={{ fontSize: 14 }}>
                      {`${option.last_name},${option.first_name} (${option.employee_id})` }
                    </li>
                  )}
                  value={party}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => setParty(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder="" />
                  )}
                  sx={{ width: "100%" }}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>
                <b>Last Maintenance Information</b>
              </h4>
              <div className="col-md-6">
                <label>Last Date of Maintenance</label>
                <input
                  type="date"
                  max={new Date().toJSON().split("T")[0]}
                  className="date-field"
                  id="last_scheduled_at"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="last_scheduled_at"
                  data-date="MM DD YYYY"
                  data-date-format="MM DD YYYY"
                  value={values.last_scheduled_at}
                  onChange={(e) => {
                    handleChange("last_scheduled_at", e);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label>Last Maintenance {units}</label>
                <div className="mileage-hours-field">
                  <div className="input-group">
                    <div className="input-group-btn">
                      <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        {unitLabel}
                        <span className="caret"></span>
                      </button>
                      <ul className="dropdown-menu">
                        {
                          maintenanceUnits.map((unitName) => {
                            return <li onClick={() => { setUnitLabel(unitName) }}>
                              <span>{unitName}</span>
                            </li>
                          })
                        }
                      </ul>
                    </div>
                    <input type="number" className="form-control mileage-hours-input" aria-label="..."
                      placeholder={unitLabel !== 'Select' ? unitLabel : 'Choose Unit'} value={values.unitValue}
                      onChange={(e) => {
                        handleChange('unitValue', e)
                        unitLabel === 'Select' ? setUnitLabel('Mileage') : null
                      }}
                    />
                  </div>
                  {/*<div className="fields">
                    <label>Mileage</label>
                    <span>
                      <TextField
                        required
                        className="text-field"
                        id="outlined-required"
                        name="maintenanceMilage"
                        value={values.maintenanceMilage}
                        onChange={(e) => {
                          handleChange("maintenanceMilage", e);
                        }}
                      />
                    </span>
                  </div>
                  <div className="or-lable">
                    <span>Or</span>
                  </div>
                  <div className="fields">
                    <label>Hours</label>
                    <span>
                      <TextField
                        required
                        className="text-field"
                        id="outlined-required"
                        name="hours"
                        value={values.maintenanceHours}
                        onChange={(e) => {
                          handleChange("maintenanceHours", e);
                        }}
                      />
                    </span>
                  </div>*/}
                </div>
              </div>
              <div className="col-md-12">
                <label>Approved Maintenance</label>
                <TextField
                  required
                  fullWidth
                  className="text-field"
                  id="outlined-required"
                  value={values.approved_maintenance}
                  onChange={(e) => {
                    handleChange("approved_maintenance", e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            setSelectedExistingSchedule(null);
            closepopup();
            setSubmit(false)
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={(event) => {
            updateMaintentance();
            event.currentTarget.disabled = true;
          }}
        >
          {data && data.id ? "Update" : "Create"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
