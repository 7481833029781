import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import UploadAttachments from '../../../shared/components/uploadAttachments'

export const NewAssetActionForm = ({
  register,
  errors,
  photoDetails,
  setValue,
  control,
  users = [],
  sub_categories = [],
  priorities = [],
  assets = [],
  statuses = [],
  action_types = [],
  initialValues = null,
  form_fields,
}) => {
  const {
    hide_subcategory_field = false,
    subcategory_mandatory_field = false,
  } = form_fields || {}
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth error={!!errors.asset_tracking_id}>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Assets <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <Controller
              name="asset_tracking_id"
              control={control}
              rules={{ required: 'Asset is required' }}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Assets</em>
                  </MenuItem>
                  {assets.map((u) => (
                    <MenuItem value={u.value}>{u.key}</MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.asset_tracking_id && (
              <FormHelperText>
                {errors.asset_tracking_id.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth error={!!errors.issue}>
            <Box mb={1}>
              <Typography variant="body1">
                Title <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Box>
            <TextField
              size="small"
              {...register('issue', {
                required: 'Name is required',
              })}
              placeholder="Enter Name"
              error={!!errors.issue}
            />
            {errors.issue && (
              <FormHelperText>{errors.issue.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1">Description</Typography>
            </Box>
            <TextField
              size="small"
              {...register('description')}
              placeholder="Enter Description"
              error={!!errors.description}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Action Type
              </Typography>
            </Box>
            <Controller
              name="action_type_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Action Type</em>
                  </MenuItem>
                  {action_types.map((u) => (
                    <MenuItem value={u.value}>{u.key}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {!hide_subcategory_field && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.sub_category_id}>
              <Box mb={1}>
                <Typography variant="body1" className="f-label">
                  Sub Category{' '}
                  {subcategory_mandatory_field && (
                    <span style={{ color: 'red' }}>*</span>
                  )}
                </Typography>
              </Box>
              <Controller
                name="sub_category_id"
                control={control}
                defaultValue=""
                rules={
                  subcategory_mandatory_field
                    ? { required: 'Sub Category is required' }
                    : {}
                }
                render={({ field }) => (
                  <Select size="small" {...field} displayEmpty>
                    <MenuItem value="">
                      <em>Select Sub Category</em>
                    </MenuItem>
                    {sub_categories.map((u) => (
                      <MenuItem value={u.value}>{u.key}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.sub_category_id && (
                <FormHelperText>
                  {errors.sub_category_id.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Priority
              </Typography>
            </Box>
            <Controller
              name="priority"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Priority</em>
                  </MenuItem>
                  {priorities.map((u) => (
                    <MenuItem key={u.value} value={u.value}>
                      {u.key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Due Date
              </Typography>
            </Box>
            <TextField
              size="small"
              type="datetime-local"
              {...register('due_at')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Status
              </Typography>
            </Box>
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select size="small" {...field} displayEmpty>
                  <MenuItem value="">
                    <em>Select Status</em>
                  </MenuItem>
                  {statuses.map((u) => (
                    <MenuItem value={u.value}>{u.key}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1" className="f-label">
                Assignee
              </Typography>
            </Box>
            <Controller
              name="assignee_ids"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  size="small"
                  {...field}
                  multiple
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select Assignee</em>
                    }
                    return users
                      .filter((u) => selected.includes(u.value))
                      .map((u) => u.key)
                      .join(', ')
                  }}
                >
                  <MenuItem value="">
                    <em>Select Assignee</em>
                  </MenuItem>
                  {users.map((u) => (
                    <MenuItem key={u.value} value={u.value}>
                      <Checkbox checked={field.value.includes(u.value)} />
                      {u.key}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box mb={1}>
            <Typography variant="body1" className="f-label">
              Upload Attachment
            </Typography>
          </Box>
          <UploadAttachments
            control={control}
            name="images"
            multiple={true}
            setValue={setValue}
            existingFiles={photoDetails.file_name ? [photoDetails] : []}
          />
        </Grid>
      </Grid>
    </>
  )
}
