import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import Aboutus from "../../pages/HomePage/Aboutus";
import Home from "../HomePage/Home";
import Contactus from "../../pages/HomePage/Contactus";
import Login, { User } from "../../pages/HomePage/Login";
import Signup from "../../pages/HomePage/Signup";
import Forget from "../../pages/HomePage/Forget";
import Marketing from "../../pages/HomePage/Marketing";
import ComparisonPage from "../../pages/HomePage/ComparisonPage";
import Products from "../HomePage/Products";
import Solutions from "../HomePage/Solutions";
import PricingPage from "../HomePage/Pricing";
import CheckoutPage from "../HomePage/Checkout";

import Privacy from "../../components/PrivacyPolicy";
import Disclaimer from "../../components/Disclaimer";
import Eula from "../../components/eula";
import Terms from "../../components/Terms";
import UserDashboard from "../UserDashboard";
import ROT from "../ROT";
import Client from "../Client";
import COR from "../COR";
import UserProfile from "../Main/UserProfile";
import CompanyProfile from "../Main/CompanyProfile";
import StatsSettings from "../Main/StatsSettings";
import NewCompanyProfile from "../Main/NewCompanyProfile";
import UsersList from "../Main/UsersList";
import UsersArchivedList from "../Main/UsersArchivedList";
import UserList from "../Main/UserList";
import CompanyList from "../Main/CompanyList";
import SubContractorsPage from "../UserDashboard/SubContractorsPage";
import NewUser from "../Main/NewUser";
import {
  AppRoute,
  PrivateRoute,
  HomeLayout,
  AppLayout,
  AuthorizeRoute,
  LoginLayout,
  FeatureLayout,
  PricingLayout,
  CheckoutLayout,
  InternalSubLayout,
  InternalSubMobileLayout,
  InternalSubRoute,
  AuthLayout,
} from "./LayoutRoutes";
import AnalyticsDashboard from "../AnalyticsDashboard";
import AnalyticsDashboardPrinted from "../AnalyticsDashboard";
import Teams from "../HomePage/Teams";
import UpdatePassword from "../HomePage/UpdatePassword";
import RAT from "../RAT";
import CreateRAT from "../RAT/RiskAssessment/Create";
import EditRAT from "../RAT/RiskAssessment/Edit";
import ViewRiskAssessment from "../RAT/RiskAssessment/View";
import ATT from "../ATT";
import CreateAssetTracking from "../ATT/AssetTracking/Create";
import ViewAssetTracking from "../ATT/AssetTracking/View";
import EditAssetTracking from "../ATT/AssetTracking/Edit";
import Emergency from "../EmergencyPlan";
import CreateEmergency from "../EmergencyPlan/Plans/create";
import QRCodes from "../QRCodes";
import ViewQrCodes from "../QRCodes/Template/view";
import Docs from "../Doc";
import Subcontractors from "../Subcontractors";
import AddInternalSub from "../Subcontractors/InternalSubs/create";
import internalSubEmployee from "../Subcontractors/InternalSubEmployee";
import InternalSubLogin from "../InternalSubs/login";
import InternalSub from "../InternalSubs";
import NewForms from "../Subcontractors/InternalSubs/Mobile/NewForms";
import SelectGroup from "../Subcontractors/InternalSubs/Mobile/NewForms/selectGroup";
import Forms from "../Subcontractors/InternalSubs/Mobile/Forms";
import InternalSubDashboard from "../Subcontractors/InternalSubs/Mobile/Dashboard";
import InternalSubTraining from "../Subcontractors/InternalSubs/Mobile/Training";
import InternalSubDocs from "../Subcontractors/InternalSubs/Mobile/Docs";
import Careers from "../HomePage/Careers";
import HelpUs from "../HomePage/Helpus";
import EULA from "../../components/eula-policy";

//Inventory

import ViewSupplier from "../Inventory/Configuration/SupplierList/view";
import CreateInventory from "../Inventory/InventoryList/Create";
import ViewInventory from "../Inventory/InventoryList/View";
import Inventory from "../Inventory";

//TimeTracking
import TimeTracking from "../TimeTracking";
import TimeTrackingConfig from "../TimeTracking/Configuration";

// Product Features Components
import FeaturesHomePage from "../../components/Features";
import UserManagement from "../../components/Features/UserManagement";
import TrainingCertificateManagement from "../../components/Features/TrainingCertificateManagement";
import ProjectSiteManagement from "../../components/Features/ProjectSiteManagement";
import AssetManagement from "../../components/Features/AssetManagement";
import FormCreation from "../../components/Features/FormCreation";
import FormScheduling from "../../components/Features/FormScheduling";
import SubcontractorManagement from "../../components/Features/SubcontractorManagement";
import RiskAssessmentManagement from "../../components/Features/RiskAssessmentManagement";
import CompanyDocumentManagement from "../../components/Features/CompanyDocumentManagement";
import Actions from "../../components/Features/Actions";
import Timetracking from "../../components/Features/Timetracking";
import EmergencyPlanManagement from "../../components/Features/EmergencyPlanManagement";
import CompanyWorkflowManagement from "../../components/Features/CompanyWorkflowManagement";
import TodoManagement from "../TodoManagement";
import InternalSubNewForm from "../Subcontractors/InternalSubs/Web/NewForm";
import InternalSubForm from "../Subcontractors/InternalSubs/Web/Forms";
import InternalSubTrainging from "../Subcontractors/InternalSubs/Web/Tainging";

// Import the new feature components
import Reporting from "../../components/Features/Reporting";
import ContractorManagement from "../../components/Features/ContractorManagement";
import DocumentManagement from "../../components/Features/DocumentManagement";
import IncidentManagement from "../../components/Features/IncidentManagement";
import InspectionManagement from "../../components/Features/InspectionManagement";
import TrainingRecordManagement from "../../components/Features/TrainingRecordManagement";
import HazardManagement from "../../components/Features/HazardManagement";
import CorrectiveActions from "../../components/Features/CorrectiveActions";
import OnboardingOrientation from "../../components/Features/OnboardingOrientation";
import EmergencyManagement from "../../components/Features/EmergencyManagement";
import QRCodeManagement from "../../components/Features/QRCodeManagement";
import InventoryManagement from "../../components/Features/InventoryManagement";
import TaskManagement from "../../components/Features/TaskManagement";
import Analytics from "../../components/Features/Analytics";
import ComplianceManagement from "../../components/Features/ComplianceManagement";
import DataIntegrations from "../../components/Features/DataIntegrations";
import FlaggedUserManagement from "../../components/Features/FlaggedUserManagement";
import PrivateForms from "../../components/Features/PrivateForms";
import AutoNotifications from "../../components/Features/AutoNotifications";
import WearableIntegrations from "../../components/Features/WearableIntegrations";

var ApplicationWrapper = require("../../config/ApplicationWrapper");
var Application = {
  role_superadmin: "SuperAdmin",
  role_admin: "Admin",
  role_manager: "Manager",
  role_observer: "Observer",
  role_employee: "Employee",
  admin: [],
  manager: ["/company_profile"],
  observer: ["/company_profile"],
  user: [],
  role_configuration: {
    Admin: ["/company_profile", "new-company", "stats-settings"],
    Manager: [
      "/company_profile",
      "/new-user",
      "/company-users",
      "stats-settings",
    ],
    Observer: ["/company_profile", "/company-users", "stats-settings"],
    Employee: [],
  },
};

var axios = require("axios");
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import TimeTrackingTool from "../TimeTracking";
import CreateWorkEntries from "../TimeTracking/WorkEntries/Create";
import ShowWorkEntries from "../TimeTracking/WorkEntries/Show";
import UserGroup from "../UserGroup/userGroup";
import JobProfileView from "../UserGroup/components/JobProfiles/JobProfileView";
import UserGroupView from "../UserGroup/components/UserList/view";
import OrderDetails from "../Inventory/Order/OrderDetails";
import GlobalProjectView from "../UserGroup/components/AssignedGlobalProjects/view";
import InviteUser from "../UserGroup/components/UserList/InviteUser";
import ActionsManagement from "../Actions";
import { AssetPageView } from '../Actions/Components/AssetActions/View/AssetPageView';
import {ActionNoticeView} from '../Actions/Components/ActionsNotices/View/ActionNoticeView'

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      currentUser: null,
    };
  }

  componentWillMount() {
    var user = $("#app").attr("user_data");
    var info = $("#app").attr("user_info");
    const companyInfo = $("#app").data("company");
    const isActiveSubscription = $("#app").data("is-active-subscription");
    const remainingTrialDays = $("#app").data("remaining-trial-days");
    const isPastDue = $("#app").data("past-due");
    if (user != "") {
      const currentUser = JSON.parse(user);
      this.setState({
        currentUser: currentUser,
      });
      var data = JSON.parse(info);
      User.id = data.user_id;
      User.isAuthenticated = true;
      User.role = data.role;
      User.company = data.company;
      User.companyInfo = companyInfo;
      User.isActiveSubscription = isActiveSubscription;
      User.remainingTrialDays = remainingTrialDays || 0;
      User.isPastDue = isPastDue;
      User.menuItems = currentUser.menu_items;
      User.currentInventoryPlan = data.current_inventory_plan;
      User.currentInventoryPlanId = data.current_inventory_plan_id;
      User.currentTimeTrackerPlan = data.current_time_tracker_plan;
      User.currentTimeTrackerPlanId = data.current_time_tracker_plan_id;
      User.currentBulkUploadId = data.current_bulk_upload_id;
      User.currentBulkUploadPercentage = data.current_bulk_upload_percentage;
      User.inventoryTabVisible = data.inventory_tab_visible;
    }
  }

  is_authorized(menu) {
    if (menu == "/new-company") {
      if (User.role == Application.role_superadmin) {
        return true;
      }
    } else {
      if (User.role == "") {
        return false;
      }
      if (
        User.role == Application.role_superadmin ||
        User.role == Application.role_admin ||
        User.role == Application.role_manager ||
        User.role == Application.role_observer
      ) {
        return true;
      } else {
        var menus = Application.role_configuration[User.role];
        return $.inArray(menu, menus) == 0;
      }
    }
  }

  authenticated() {
    debugger;
    let auth = Cookies.get("token");
    return auth ? true : false;
  }

  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          theme="colored"
          style={{ zIndex: 9999999 }}
        />
        <Switch>
          <AppRoute exact path="/home" layout={HomeLayout} component={Home} />
          <AppRoute
            exact
            path="/product"
            layout={HomeLayout}
            component={Products}
          />
          <AppRoute
            exact
            path="/solution"
            layout={HomeLayout}
            component={Solutions}
          />

          {/* Product Feature Pages */}
          <AppRoute
            path="/features/asset_management"
            layout={FeatureLayout}
            component={AssetManagement}
          />
          <AppRoute
            path="/features/form_creation"
            layout={FeatureLayout}
            component={FormCreation}
          />
          <AppRoute
            path="/features/form_scheduling"
            layout={FeatureLayout}
            component={FormScheduling}
          />
          <AppRoute
            path="/features/subcontractor_management"
            layout={FeatureLayout}
            component={SubcontractorManagement}
          />
          <AppRoute
            path="/features/risk_assessment_management"
            layout={FeatureLayout}
            component={RiskAssessmentManagement}
          />
          <AppRoute
            path="/features/training_certificate"
            layout={FeatureLayout}
            component={TrainingCertificateManagement}
          />
          <AppRoute
            path="/features/company_document_management"
            layout={FeatureLayout}
            component={CompanyDocumentManagement}
          />
          <AppRoute
            path="/features/actions"
            layout={FeatureLayout}
            component={Actions}
          />
          <AppRoute
            path="/features/emergency_plan_management"
            layout={FeatureLayout}
            component={EmergencyPlanManagement}
          />
          <AppRoute
            path="/features/user_management"
            layout={FeatureLayout}
            component={UserManagement}
          />
          {/* <AppRoute path="/features/project_site_management" layout={FeatureLayout} component={ProjectSiteManagement} /> */}
          {/* <AppRoute path="/features/timetracking" layout={FeatureLayout} component={Timetracking} /> */}
          {/* <AppRoute path="/features/company_workflow_management" layout={FeatureLayout} component={CompanyWorkflowManagement} /> */}
          <AppRoute
            path="/features"
            layout={FeatureLayout}
            component={FeaturesHomePage}
          />

          <AppRoute path="/about" layout={HomeLayout} component={Aboutus} />
          <AppRoute path="/teams" layout={HomeLayout} component={Teams} />
          <AppRoute path="/careers" layout={HomeLayout} component={Careers} />
          <AppRoute path="/contact" layout={HomeLayout} component={Contactus} />
          <AppRoute path="/help" layout={HomeLayout} component={HelpUs} />
          <AppRoute path="/login" layout={LoginLayout} component={Login} />
          <AppRoute path="/register" layout={HomeLayout} component={Signup} />
          <AppRoute
            path="/invite_user/:id"
            layout={LoginLayout}
            component={InviteUser}
          />

          {/* <PrivateRoute
            path="/invite_user"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={InviteUser}
          /> */}
          <AppRoute
            path="/marketing"
            layout={HomeLayout}
            component={Marketing}
          />

          <AppRoute
            path="/comparison"
            layout={HomeLayout}
            component={ComparisonPage}
          />

          <AppRoute
            path="/forget-password"
            layout={LoginLayout}
            component={Forget}
          />
          <AppRoute path="/privacy" layout={HomeLayout} component={Privacy} />
          <AppRoute
            path="/disclaimer"
            layout={HomeLayout}
            component={Disclaimer}
          />
          <AppRoute path="/eula" layout={HomeLayout} component={Eula} />
          <AppRoute path="/terms" layout={HomeLayout} component={Terms} />
          <AppRoute
            path="/user-profile"
            is_authenitcated={User.isAuthenticated}
            layout={AppLayout}
            component={UserProfile}
          />
          <AppRoute
            path="/pricing"
            layout={PricingLayout}
            component={PricingPage}
          />
          <AppRoute
            path="/checkout"
            layout={CheckoutLayout}
            component={CheckoutPage}
          />
          {/* New Feature Pages */}
          <AppRoute
            path="/features/reporting"
            layout={FeatureLayout}
            component={Reporting}
          />
          <AppRoute
            path="/features/contractor_management"
            layout={FeatureLayout}
            component={ContractorManagement}
          />
          <AppRoute
            path="/features/document_management"
            layout={FeatureLayout}
            component={DocumentManagement}
          />
          <AppRoute
            path="/features/incident_management"
            layout={FeatureLayout}
            component={IncidentManagement}
          />
          <AppRoute
            path="/features/inspection_management"
            layout={FeatureLayout}
            component={InspectionManagement}
          />
          <AppRoute
            path="/features/training_record_management"
            layout={FeatureLayout}
            component={TrainingRecordManagement}
          />
          <AppRoute
            path="/features/hazard_management"
            layout={FeatureLayout}
            component={HazardManagement}
          />
          <AppRoute
            path="/features/corrective_actions"
            layout={FeatureLayout}
            component={CorrectiveActions}
          />
          <AppRoute
            path="/features/onboarding_orientation"
            layout={FeatureLayout}
            component={OnboardingOrientation}
          />
          <AppRoute
            path="/features/emergency_management"
            layout={FeatureLayout}
            component={EmergencyManagement}
          />
          <AppRoute
            path="/features/qrcode_management"
            layout={FeatureLayout}
            component={QRCodeManagement}
          />
          <AppRoute
            path="/features/inventory_management"
            layout={FeatureLayout}
            component={InventoryManagement}
          />
          <AppRoute
            path="/features/task_management"
            layout={FeatureLayout}
            component={TaskManagement}
          />
          <AppRoute
            path="/features/analytics"
            layout={FeatureLayout}
            component={Analytics}
          />
          <AppRoute
            path="/features/compliance_management"
            layout={FeatureLayout}
            component={ComplianceManagement}
          />
          <AppRoute
            path="/features/data_integrations"
            layout={FeatureLayout}
            component={DataIntegrations}
          />
          <AppRoute
            path="/features/flagged_user_management"
            layout={FeatureLayout}
            component={FlaggedUserManagement}
          />
          <AppRoute
            path="/features/private_forms"
            layout={FeatureLayout}
            component={PrivateForms}
          />
          <AppRoute
            path="/features/auto_notifications"
            layout={FeatureLayout}
            component={AutoNotifications}
          />
          <AppRoute
            path="/features/wearable_integrations"
            layout={FeatureLayout}
            component={WearableIntegrations}
          />

          <AuthorizeRoute
            path="/company-profile"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/company-profile')}
            component={CompanyProfile}
          />
          <AuthorizeRoute
            path="/stats-settings"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/stats-settings')}
            component={StatsSettings}
          />
          <AuthorizeRoute
            path="/sub-contractor"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/sub-contractor')}
            component={SubContractorsPage}
          />
          <AuthorizeRoute
            path="/new-company"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/new-company')}
            component={NewCompanyProfile}
          />
          <AuthorizeRoute
            path="/company-list"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/company-list')}
            component={CompanyList}
          />
          <AuthorizeRoute
            path="/company-users"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/company-users')}
            component={UsersList}
          />
          <AuthorizeRoute
            path="/company-archived-users"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/company-archived-users')}
            component={UsersArchivedList}
          />
          <AuthorizeRoute
            path="/user-list"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/user-list')}
            component={UserList}
          />
          <AuthorizeRoute
            path="/new-user"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/new-user')}
            component={NewUser}
          />
          <PrivateRoute
            path="/dashboard"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={UserDashboard}
          />

          <PrivateRoute
            path="/eula-policy"
            layout={LoginLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/eula-policy')}
            component={EULA}
          />

          <PrivateRoute
            path="/analytics-dashboard"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={AnalyticsDashboard}
          />
          <PrivateRoute
            path="/analytics-dashboard-print"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={AnalyticsDashboardPrinted}
          />
          <PrivateRoute
            path="/rot"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ROT}
          />
          <PrivateRoute
            path="/update-password"
            layout={LoginLayout}
            is_authenticated={User.isAuthenticated}
            is_authorized={this.is_authorized('/update-password')}
            component={UpdatePassword}
          />

          <PrivateRoute
            path="/risk-assessments"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={RAT}
          />

          <PrivateRoute
            path="/create-risk-assessment/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateRAT}
          />

          <PrivateRoute
            path="/edit-risk-assessment/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={EditRAT}
          />

          <PrivateRoute
            path="/view-risk-assessment/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ViewRiskAssessment}
          />

          <PrivateRoute
            path="/asset-tracking"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ATT}
          />

          <PrivateRoute
            path="/create-asset-tracking"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateAssetTracking}
          />

          <PrivateRoute
            path="/view-asset-tracking/:type/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ViewAssetTracking}
          />

          <PrivateRoute
            path="/edit-asset-tracking/:type/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={EditAssetTracking}
          />

          <PrivateRoute
            path="/emergency-plans"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={Emergency}
          />

          <PrivateRoute
            path="/create-plan"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateEmergency}
          />

          <PrivateRoute
            path="/view-plan/:type/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateEmergency}
          />

          <PrivateRoute
            path="/edit-plan/:type/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateEmergency}
          />

          <PrivateRoute
            path="/qrcodes"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={QRCodes}
          />

          <PrivateRoute
            path="/qrcode/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ViewQrCodes}
          />

          <PrivateRoute
            path="/company_documents_beta"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={Docs}
          />

          <PrivateRoute
            path="/add-sub-contractors"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={AddInternalSub}
          />

          <PrivateRoute
            path="/edit-sub-contractors/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={AddInternalSub}
          />

          <PrivateRoute
            path="/subcontractors"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={Subcontractors}
          />

          <PrivateRoute
            path="/subcontractor/:name"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={Subcontractors}
          />

          <PrivateRoute
            path="/internal-sub-employee/:id/:name"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={internalSubEmployee}
          />

          <PrivateRoute
            path="/view_supplier/:id/:name"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ViewSupplier}
          />

          <PrivateRoute
            path="/inventory"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={Inventory}
          />

          <PrivateRoute
            path="/orders/:orderId"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={OrderDetails}
          ></PrivateRoute>

          <PrivateRoute
            path="/create-inventory"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateInventory}
          />

          <PrivateRoute
            path="/edit-inventory/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateInventory}
          />

          <PrivateRoute
            path="/view-inventory/:id"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ViewInventory}
          />

          <PrivateRoute
            path="/view-history/:id/:path"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ViewInventory}
          />

          <AppRoute
            path="/internal-subs/login"
            layout={InternalSubLayout}
            component={InternalSubLogin}
          />

          <InternalSubRoute
            path="/internalsub"
            layout={InternalSubLayout}
            component={InternalSub}
          />

          <InternalSubRoute
            path="/internalsub_dashboard"
            layout={InternalSubLayout}
            component={InternalSubDashboard}
          />

          <InternalSubRoute
            path="/inter_sub_m_new_forms"
            layout={InternalSubMobileLayout}
            component={NewForms}
          />

          <InternalSubRoute
            path="/inter_sub_m_new_form_group/:id"
            layout={InternalSubMobileLayout}
            component={SelectGroup}
          />

          <InternalSubRoute
            path="/inter_sub_m_forms"
            layout={InternalSubMobileLayout}
            component={Forms}
          />

          <InternalSubRoute
            path="/inter_sub_m_trainings"
            layout={InternalSubMobileLayout}
            component={InternalSubTraining}
          />

          <InternalSubRoute
            path="/inter_sub_m_docs"
            layout={InternalSubMobileLayout}
            component={InternalSubDocs}
          />

          <InternalSubRoute
            path="/inter_sub_new_forms"
            layout={InternalSubLayout}
            component={InternalSubNewForm}
          />

          <InternalSubRoute
            path="/inter_sub_forms"
            layout={InternalSubLayout}
            component={InternalSubForm}
          />

          <InternalSubRoute
            path="/inter_sub_trainings"
            layout={InternalSubLayout}
            component={InternalSubTrainging}
          />

          {/*<PrivateRoute path="/client" layout={ AppLayout } is_authenticated= {User.isAuthenticated} component={ Client } />*/}
          <AppRoute
            path="/client"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={Client}
          />
          <PrivateRoute
            path="/cor"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={COR}
          />
          <PrivateRoute
            path="/todo-management"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={TodoManagement}
          />
          <PrivateRoute
            path="/time-tracking"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={TimeTrackingTool}
          />
          <PrivateRoute
            path="/time_tracking/configuration"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={TimeTrackingConfig}
          />
          <PrivateRoute
            path="/time_tracking/:id/create-work-entries"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateWorkEntries}
          />
          <PrivateRoute
            path="/time_tracking/:id/edit-work-entries"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={CreateWorkEntries}
          />
          <PrivateRoute
            path="/time_tracking/:id/view-work-entries"
            layout={AppLayout}
            is_authenticated={User.isAuthenticated}
            component={ShowWorkEntries}
          />
          <PrivateRoute
            path="/user_group/view/:id"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={UserGroupView}
          />
          <PrivateRoute
            path="/company_groups/view/:id"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={GlobalProjectView}
          />
          <PrivateRoute
            path="/user_group"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={UserGroup}
          />
          <PrivateRoute
            path="/job_profile/view/:id"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={JobProfileView}
          />
          <PrivateRoute
            path="/global_projects/view/:id"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={GlobalProjectView}
          />
          <PrivateRoute
            path="/actions-management"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={ActionsManagement}
          />
          <PrivateRoute
            path="/actions-notices/view/:id"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={ActionNoticeView}
          />
          <PrivateRoute
            path="/assets-actions/view/:id"
            layout={AuthLayout}
            is_authenticated={User.isAuthenticated}
            component={AssetPageView}
          />
          <Redirect
            to={User.isAuthenticated ? '/analytics-dashboard' : '/home'}
            is_authenitcated={User.isAuthenticated}
            is_authorized={User.isAuthenticated}
          />
        </Switch>
      </div>
    )
  }
}
export default App;
