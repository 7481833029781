import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import FormWrapper from '../../../../shared/components/formWrapper'
import { showToast } from '../../../../shared/components/showNotification'
import { AddNotesForm } from './AddNotesForm'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: '12px',
  p: 4,
}
export const AddNotesModal = ({
  open,
  handleClose,
  mutate,
  refetch,
  isMutating,
  page,
  id,
  apiRefetch,
}) => {
  const defaultValues = {
    note: '',
    photos: [],
  }
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const onSubmit = (data) => {
    const formData = new FormData()
    if (page === 'asset') {
      formData.append('action_note[is_action_notify]', true)
      formData.append('action_note[notable_id]', id)
      formData.append('action_note[notable_klass]', 'AssetAction')
      formData.append('action_note[note]', data.note || '')
      Object.values(data.photos).forEach((file) => {
        formData.append('action_note[document]', file)
      })
    } else {
      formData.append('audit_action_note[note]', data.note || '')
      Object.values(data.photos).forEach((file) => {
        formData.append('audit_action_note[photos][]', file)
      })
    }

    const payload = formData
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: 'Created Successfully' })
        handleClose(false)
        if (apiRefetch) {
          // refetch()
          apiRefetch()
        }
      },
      onError: (e) => {
        showToast({
          type: 'error',
          message: typeof e === 'string' ? e : e.errors.toString() || '',
        })
      },
    })
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography
              className="popup-title"
              id="modal-title"
              variant="h6"
              component="h2"
            >
              New Note
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: 'calc(100% - 50px)' }}
            >
              <Box
                sx={{ mt: 2 }}
                // style={{ overflowY: 'scroll', height: 'calc(100% - 40px)' }}
              >
                <AddNotesForm
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#ECECEC',
                      color: '#5C5C5C',
                      marginRight: '10px',
                      outline: 'none',
                    }}
                    onClick={() => handleClose(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#407797',
                      color: '#fff',
                      outline: 'none',
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    disabled={isMutating}
                    endIcon={
                      isMutating ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : null
                    }
                    color="primary"
                  >
                    Create
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  )
}
