import React from 'react'
import useFetch from '../../../shared/components/userFetch'
import useModify from '../../../shared/components/userModify'
import NewAssetAction from './NewAssetAction'

const EditAssetAction = ({ edit, setEdit, refetch }) => {
  const { data, isLoading } = useFetch(
    `/api/v2/actions_management/asset_actions/${edit}?view_type=form`
  )
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useModify('/api/v2/actions_management/asset_actions')
  if (isLoading) {
    return null
  }
  return (
    <NewAssetAction
      mutate={mutate}
      open={!!edit}
      setOpen={setEdit}
      initialValues={data.data}
      refetch={refetch}
      isMutating={isMutating}
    />
  )
}

export default EditAssetAction
