import React from 'react'
import useUpdate from '../../../shared/components/useUpdate'
import NewActionItem from './NewActionItem'

const CreateActionItem = ({ open, setOpen, setFilterState, refetch, optimisticUpdate }) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useUpdate('/api/v2/actions_management/actions')
  return (
    <NewActionItem
      open={open}
      mutate={mutate}
      setOpen={setOpen}
      setFilterState={setFilterState}
      refetch={refetch}
      isMutating={isMutating}
      optimisticUpdate={optimisticUpdate}
    />
  )
}

export default CreateActionItem
